import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { AuthService } from '../../../shared/services/auth.service';
import { Group } from '../../../shared/models/elearning/group.model';
import { BaseComponent } from '../../../shared/components/base/base.component';
import { User } from '../../../shared/models/elearning/user.model';
import {  GENDER, COURSE_MEMBER_ROLE, COURSE_MEMBER_ENROLL_STATUS } from '../../../shared/models/constants';
import { BaseModel } from '../../../shared/models/base.model';
import { Course } from '../../../shared/models/elearning/course.model';
import { CourseMember } from '../../../shared/models/elearning/course-member.model';
import * as _ from 'underscore';
import { LearnerViewContentComponent } from './learner-content.component';

const COURSE_MEMBER_FIELDS = ['course_name', 'role', 'enroll_status', 'date_register'];


@Component({
	
	selector: 'learner-view',
	templateUrl: 'learner-view.component.html',
})
export class LearnerViewComponent extends BaseComponent implements OnInit{

	@ViewChild(LearnerViewContentComponent,{static:true}) viewContent: LearnerViewContentComponent;
	private user: User;

	constructor(private router: Router, private route: ActivatedRoute) {
		super();
		this.user = new User();
	}

	ngOnInit() {
		this.user = this.route.snapshot.data['user'];
		this.viewContent.render(this.user);
	}

	editUser() {
		this.router.navigate(['/account/learner/form', this.user.id]);
	}

	close() {
		this.router.navigate(['/account/learners']);
	}


}

