import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { AuthService } from '../../../shared/services/auth.service';
import { Group } from '../../../shared/models/elearning/group.model';
import { BaseComponent } from '../../../shared/components/base/base.component';
import { User } from '../../../shared/models/elearning/user.model';
import * as _ from 'underscore';
import { StaffContentComponent } from './staff-content.component';

const GROUP_FIELDS = ['name', 'category', 'parent_id', 'child_ids'];
const USER_FIELDS = ['email', 'login'];

@Component({
	
	selector: 'staff-form',
	templateUrl: 'staff-form.component.html',
})
export class StaffFormComponent extends BaseComponent {

	@ViewChild(StaffContentComponent,{static:true}) formContent: StaffContentComponent;

	private user: User;
	private staffs: User[];

	constructor(private router: Router, private route: ActivatedRoute) {
		super();
		this.user = new User();
	}

	ngOnInit() {
		this.user = this.route.snapshot.data['user'];
		if (this.user.IsNew) {
			this.user.referal_code = this.makeCode();
		}
		this.formContent.render(this.user);
		User.listAllStaff(this, USER_FIELDS).subscribe(users => {
			this.staffs = users;
		});
	}

	save() {
		if (this.user.IsNew) {
			this.user.role = "staff";
			if (this.checkDuplicateInformation(this.user)) {

			} else {
				User.register(this, this.user).subscribe(resp => {
					if (!resp["success"]) {
						this.error(this.translateService.instant(resp["message"]));
						return;
					}
					this.user.id = +resp["user_id"];
					this.router.navigate(['/account/staff/view', this.user.id]);
				});
			}
		} else {
			this.user.save(this).subscribe(() => {
				this.router.navigate(['/account/staff/view', this.user.id]);
			});
		}
	}

	cancel() {
		if (this.user.IsNew)
			this.router.navigate(['/account/staffs']);
		else
			this.router.navigate(['/account/staff/view', this.user.id]);
	}

	makeCode() {
		var text = "";
		var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";

		for (var i = 0; i < 6; i++)
			text += possible.charAt(Math.floor(Math.random() * possible.length));

		return text;
	}

	checkDuplicateInformation(user: User) {
		var result = false;
		this.staffs.forEach(staff => {
			if (staff.login === user.login) {
				result = true;
				this.warn('Tên đăng nhập đã được sử dụng');
			} else if (staff.email === user.email) {
				result = true;
				this.warn('Email đã được sử dụng');
			}
		});
		return result;
	}
}

