import { Component } from '@angular/core';
import { BaseDialog } from '../../../shared/components/base/base.dialog';
import * as _ from 'underscore';
import { ActivationCode } from '../../../shared/models/elearning/activation-code.model';
import { User } from '../../../shared/models/elearning/user.model';
import { PaymentDetail } from '../../../shared/models/elearning/payment-detail.model';
import { CODE_STATUS } from '../../../shared/models/constants';

@Component({
    
    selector: 'activation-history-dialog',
    templateUrl: 'activation-history-dialog.component.html',
})
export class ActivationHistoryDialog extends BaseDialog<User> {

    private codes: ActivationCode[];
    CODE_STATUS = CODE_STATUS;

    constructor() {
        super();
    }

    ngOnInit() {
        this.onShow.subscribe((object: User) => {
            object.listActivationCodes(this).subscribe(codes => {
                this.codes = codes;
                this.codes.forEach(code => {
                    PaymentDetail.searchPaymentDetailByPaymentRequest(this, code.payment_request_id).subscribe((details: any) => {
                        details.forEach(detail => {
                            code.courses.push(detail.course_name);
                        });
                    });
                });
            });
        });
    }


}

