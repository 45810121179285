import { NgModule } from '@angular/core';
import { AuthModule } from '../auth/auth.module';
import { CourseModule } from '../course/course.module';
import { ErpSharedModule } from '../shared/shared.module';
import { CMSComponent } from './cms.component';
import { CMSRoutingModule} from './cms-routing';
import { PageListComponent } from './web/page-content/page-list.component';
import { LearningPathListComponent } from './web/learning-path/learning-path-list.component';
import { WebPageDialog } from './web/page-content/page-dialog/page-dialog.component';
import { PageSectionDialog } from './web/page-content/section-dialog/section-dialog.component';
import { SectionItemDialog } from './web/page-content/item-dialog/item-dialog.component';
import { CourseSyllabusComponent } from './course/course-syllabus/course-syllabus.component';
import { CourseUnitContainerDirective } from './course/course-unit-template/unit-container.directive';
import { FolderCourseUnitComponent } from './course/course-unit-template/folder/folder-unit.component';
import { SCORMLectureCourseUnitComponent } from './course/course-unit-template/scorm/scorm-lecture-unit.component';
import { SlideLectureCourseUnitComponent } from './course/course-unit-template/slide/slide-lecture-unit.component';
import { VideoLectureCourseUnitComponent } from './course/course-unit-template/video/video-lecture-unit.component';
import { HtmlLectureCourseUnitComponent } from './course/course-unit-template/lecture/html-lecture-unit.component';
import { CourseUnitDialog } from './course/course-unit-dialog/course-unit-dialog.component';
import { CourseUnitPreviewDialog } from './course/course-unit-preview-dialog/course-unit-preview-dialog.component';
import { CourseSettingDialog } from './course/course-setting/course-setting.dialog.component';
import { CoursePublishComponent } from './course/course-publish/course-publish.component';
import { CourseBackupComponent } from './course/course-backup/course-backup.component';
import { CourseRestoreComponent } from './course/course-restore/course-restore.component';
import { CourseResolve, CourseSyllabusResolve } from './route.resolver';
import { CourseInfoDialog } from './course/course-dialog/course-info-dialog.component';
import { PageSeoListComponent } from './web/page-seo/page-list.component';


@NgModule({
	imports: [
		ErpSharedModule,
		AuthModule,
		CourseModule,
		CMSRoutingModule
	],
	declarations: [
		CMSComponent,
		CourseUnitPreviewDialog,
		CourseSyllabusComponent,
		CourseUnitContainerDirective,
		FolderCourseUnitComponent,
		CourseSettingDialog,
		SlideLectureCourseUnitComponent,
		SCORMLectureCourseUnitComponent,
		VideoLectureCourseUnitComponent,
		HtmlLectureCourseUnitComponent,
		CourseUnitDialog,
		CoursePublishComponent,
		CourseBackupComponent,
		CourseRestoreComponent,
		PageListComponent,
		LearningPathListComponent,
		PageSectionDialog,
		SectionItemDialog,
		WebPageDialog,
		CourseInfoDialog,
		PageSeoListComponent,
	],
	exports: [
		CourseUnitPreviewDialog,
		CourseUnitContainerDirective,
		CourseInfoDialog,
	],
	providers: [CourseResolve,CourseSyllabusResolve],
	entryComponents: [
		FolderCourseUnitComponent,
		SCORMLectureCourseUnitComponent,
		SlideLectureCourseUnitComponent,
		VideoLectureCourseUnitComponent,
		HtmlLectureCourseUnitComponent,
		]
})
export class CMSModule {
}
