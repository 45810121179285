import { Component, OnInit, Input } from '@angular/core';
import { Observable, Subject } from 'rxjs/Rx';
import { AuthService } from '../../services/auth.service';
import { Group } from '../../models/elearning/group.model';
import { BaseComponent } from '../base/base.component';
import { User } from '../../../shared/models/elearning/user.model';
import * as _ from 'underscore';
import { TreeUtils } from '../../../shared/helpers/tree.utils';
import { TreeNode } from 'primeng/api';
import { GROUP_CATEGORY, CONTENT_STATUS } from '../../../shared/models/constants'
import { SelectItem } from 'primeng/api';

const USER_FIELDS = ['name', 'email', 'login'];


@Component({
	
	selector: 'select-teacher-dialog',
	templateUrl: 'select-teacher-dialog.component.html',
	styleUrls: ['select-teacher-dialog.component.css'],
})
export class SelectTeachersDialog extends BaseComponent {

	selectedUsers: User[];
	users:User[];
	display: boolean;

	onSelectUsersReceiver: Subject<any> = new Subject();
    onSelectUsers:Observable<any> =  this.onSelectUsersReceiver.asObservable();

	constructor() {
		super();
		this.display = false;
		this.selectedUsers = [];
	}

	hide() {
		this.display = false;
	}

	show() {
		this.display = true;
		this.selectedUsers = [];
		User.listAllTeacher(this, USER_FIELDS).subscribe((users)=> {
			this.users = users;
		})
	}

	select() {
		this.onSelectUsersReceiver.next(this.selectedUsers);
		this.selectedUsers=[];
		this.hide();
	}


}

