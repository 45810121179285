import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { Group } from '../../../shared/models/elearning/group.model';
import { BaseDialog } from '../../../shared/components/base/base.dialog';
import { Course } from '../../../shared/models/elearning/course.model';
import { User } from '../../../shared/models/elearning/user.model';
import { Product } from '../../../shared/models/elearning/product.model';
import * as _ from 'underscore';
import { Router, ActivatedRoute, Params } from '@angular/router';

import { Subscription } from 'rxjs/Subscription';

@Component({
	
	selector: 'product-form',
	templateUrl: 'service-form.component.html',
	styleUrls: ['service-form.component.css'],
})
export class ProductFormComponent extends BaseDialog<Product> implements OnInit {

	private product: Product;

	constructor(private route: ActivatedRoute,  private router: Router) {
		super();
		this.product =  new Product();
	}

	ngOnInit() {
		this.product = this.route.snapshot.data['product'];
	}

	save() {
		this.product.save(this).subscribe(() => {
				this.router.navigate(['/service/view', this.product.id]);
		});
	}

	cancel() {
		this.router.navigate(['/service/list']);
	}
	
}

