import { Component, OnInit, OnDestroy, ViewChild, AfterViewInit } from '@angular/core';
import { MenuItem } from 'primeng/primeng';
import { Observable } from 'rxjs/Observable';
import { Router } from '@angular/router';
import { BaseComponent } from '../../shared/components/base/base.component';
import { CourseMember } from '../../shared/models/elearning/course-member.model';
import { Course } from '../../shared/models/elearning/course.model';
import { CourseLog } from '../../shared/models/elearning/log.model';
import { User } from '../../shared/models/elearning/user.model';
import { GROUP_CATEGORY, CONFERENCE_STATUS, COURSE_MODE, COURSE_STATUS, SCHEDULER_HEADER } from '../../shared/models/constants'
import { CourseSyllabus } from '../../shared/models/elearning/course-syllabus.model';
import { SelectItem } from 'primeng/api';
import { CourseUnit } from '../../shared/models/elearning/course-unit.model';
import { BaseModel } from '../../shared/models/base.model';
import * as _ from 'underscore';

const COURSE_FIELDS = ['status', 'name', 'write_date', 'create_date', 'supervisor_id', 'logo_url', 'summary', 'description', 'code', 'mode', 'unit_count', 'group_name', 'syllabus_id'];

@Component({
    
    selector: 'user-dashboard',
    templateUrl: 'user-dashboard.component.html',
    styleUrls: ['user-dashboard.component.css'],

})
export class UserDashboardComponent extends BaseComponent implements OnInit {

    COURSE_STATUS = COURSE_STATUS;

    private courseMembers: CourseMember[];
    private courses: Course[];
    private header: any;
    private events: any[];


    constructor(private router: Router) {
        super();
        this.courses = [];
        this.events = [];
    }

    displayCourses(courses: Course[]) {
        _.each(courses, (course: Course) => {
            course['editor'] = this.lmsProfileService.getCourseMemberByRole('editor', course.id);
            course['supervisor'] = this.lmsProfileService.getCourseMemberByRole('supervisor', course.id);
            if (course['supervisor'])
                course['editor'] =  course['supervisor'];
        });
        this.courses = _.sortBy(courses, (course: Course) => {
            return -this.lmsProfileService.getLastCourseTimestamp(course);
        });

    }


    ngOnInit() {
        this.lmsProfileService.init(this).subscribe(() => {
            this.courseMembers = this.lmsProfileService.MyCourseMembers;
            var courseIds = _.pluck(this.courseMembers, 'course_id');
            courseIds = _.uniq(courseIds, id => {
                return id;
            });
            Course.array(this, courseIds, COURSE_FIELDS).subscribe(courses => {
                this.displayCourses(courses);
            });
        });
    }


    editSyllabus(course: Course, member: CourseMember) {
        this.router.navigate(['/lms/courses/edit', course.id, member.id]);
    }

    publishCourse(course: Course) {
        this.router.navigate(['/cms/course/publish', course.id, course.syllabus_id]);
    }

    manageCourse(course: Course, member: CourseMember) {
        this.router.navigate(['/lms/courses/manage', course.id, member.id]);
    }

}