import { Observable, Subject } from 'rxjs/Rx';
import { Model, ReadOnlyProperty, FieldProperty } from '../decorator';
import { APIContext } from '../context';
import { BaseModel } from '../base.model';
import { User } from './user.model';
import * as _ from 'underscore';
import { SearchReadAPI } from '../../services/api/search-read.api';
import { ExecuteAPI } from '../../services/api/execute.api';

@Model('opencourse.activation_code')
export class ActivationCode extends BaseModel {

    // Default constructor will be called by mapper
    constructor() {
        super();
        this.code = undefined;
        this.date_use = undefined;
        this.payment_request_id = undefined;
        this.user_id = undefined;
        this.status = undefined;
        this.product_id = undefined;
        this.service_name = undefined;
        this.service_length = undefined;
        this.courses = [];
    }

    code: string;
    @FieldProperty<Date>()
    date_use: number;
    payment_request_id: number;
    user_id: number;
    status: string;
    product_id: number;
    service_name: string;
    service_length: number;
    courses: string[];

}
