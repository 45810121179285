import { NgModule } from '@angular/core';
import { AuthModule } from '../auth/auth.module';
import { ErpSharedModule } from '../shared/shared.module';
import { CourseComponent } from './course.component'
import { CourseListComponent } from './course/course-list/course-list.component';
import { CourseFormComponent } from './course/course-form/course-form.component';
import { CourseViewComponent } from './course/course-view/course-view.component';
import { LearningPathFormComponent } from './learning-path/learningpath-form/learningpath-form.component';
import { LearningPathViewComponent } from './learning-path/learningpath-view/learningpath-view.component';
import { LearningPathListComponent } from './learning-path/learningpath-list/learningpath-list.component';
import { CourseRoutingModule } from './course-routing';
import { CourseEnrollmentListComponent } from './enrollment/course-list/course-list.component';
import { CourseEnrollmentFormComponent } from './enrollment/enrollment-form/course-enrollment-form.component';
import { CourseResolve, LearningPathResolve } from './route.resolver';

@NgModule({
	imports: [
		CourseRoutingModule,
		ErpSharedModule,
		AuthModule
	],
	declarations: [
		CourseComponent,
		CourseListComponent,
		CourseFormComponent,
		CourseViewComponent,
		LearningPathFormComponent,
		LearningPathViewComponent,
		LearningPathListComponent,
		CourseEnrollmentListComponent,
		CourseEnrollmentFormComponent,
	],
	exports: [
	],
	providers: [
		CourseResolve,
		LearningPathResolve
	]
})
export class CourseModule {
}
