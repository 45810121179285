import { Component, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { BaseComponent } from '../../../shared/components/base/base.component';
import { AuthService } from '../../../shared/services/auth.service';
import * as _ from 'underscore';
import { USER_STATUS, GROUP_CATEGORY } from '../../../shared/models/constants'
import { User } from '../../../shared/models/elearning/user.model';
import { Course } from '../../../shared/models/elearning/course.model';
import { Group } from '../../../shared/models/elearning/group.model';
import { TreeUtils } from '../../../shared/helpers/tree.utils';
import { TreeNode } from 'primeng/api';
import { CourseMember } from '../../../shared/models/elearning/course-member.model';
import { BaseModel } from '../../../shared/models/base.model';
import { SelectGroupDialog } from '../../../shared/components/select-group-dialog/select-group-dialog.component';
import { WebPage, PageSection, SectionItem } from '../../../shared/models/elearning/web-cms.model';
import { SelectItem } from 'primeng/api';
import { WebPageDialog } from './page-dialog/page-dialog.component';
import { PageSectionDialog } from './section-dialog/section-dialog.component';
import { SectionItemDialog } from './item-dialog/item-dialog.component';
import { SelectCoursesDialog } from '../../../shared/components/select-course-dialog/select-course-dialog.component';
import { SelectTeachersDialog } from '../../../shared/components/select-teacher-dialog/select-teacher-dialog.component';
import { SelectPathDialog } from '../../../shared/components/select-path-dialog/select-path-dialog.component';
import { LearningPath } from '../../../shared/models/elearning/learning-path.model';

@Component({
    
    selector: 'page-list',
    templateUrl: 'page-list.component.html',
    styleUrls: ['page-list.component.css'],
    encapsulation: ViewEncapsulation.None
})
export class PageListComponent extends BaseComponent {

    private pages: SelectItem[];
    private selectedPage: any;
    private sections: PageSection[];
    private items: any;

    @ViewChild(WebPageDialog,{static:true}) pageDialog: WebPageDialog;
    @ViewChild(PageSectionDialog,{static:true}) sectionDialog: PageSectionDialog;
    @ViewChild(SectionItemDialog,{static:true}) itemDialog: SectionItemDialog;
    @ViewChild(SelectCoursesDialog,{static:true}) courseDialog: SelectCoursesDialog;
    @ViewChild('courseGroup',{static:true}) groupDialog: SelectGroupDialog;
    @ViewChild(SelectTeachersDialog,{static:true}) teacherDialog: SelectTeachersDialog;
    @ViewChild(SelectPathDialog,{static:true}) pathDialog: SelectPathDialog;

    constructor() {
        super();
        this.sections = [];
        this.items = {};
    }

    ngOnInit() {
        WebPage.all(this).subscribe(pages => {
            pages = _.sortBy(pages, (page: WebPage) => {
                return page.order;
            });
            this.pages = _.map(pages, (page: WebPage) => {
                return {
                    label: page.name,
                    value: page
                }
            });
            if (this.pages.length) {
                this.selectedPage = pages[0];
                this.selectPage(pages[0])
            }
        });
        this.sections = [];
        this.items = {};
    }

    selectPage(page: WebPage) {
        page.listSections(this).subscribe((sections) => {
            this.sections = _.sortBy(sections, (section: PageSection) => {
                return section.order;
            });
            _.each(this.sections, (section: PageSection) => {
                section.listItems(this).subscribe(items => {
                    items = _.sortBy(items, (item: SectionItem) => {
                        return item.order;
                    });
                    items.push(new SectionItem());
                    this.items[section.id] = items;
                });
            })
        });
    }

    addItem(section: PageSection, item: SectionItem) {
        if (section.res_model == Group.Model) {
            this.groupDialog.show()
            this.groupDialog.onSelectGroup.first().subscribe((group: Group) => {
                item.res_id = group.id;
                item.name = group.name;
                item.section_id = section.id;
                item.order = this.items[section.id].length;
                item.save(this).subscribe(() => {
                    this.items[section.id].push(new SectionItem());
                });
            });
        }
        if (section.res_model == LearningPath.Model) {
            this.pathDialog.show()
            this.pathDialog.onSelectPath.first().subscribe((path: LearningPath) => {
                item.res_id = path.id;
                item.name = path.name;
                item.section_id = section.id;
                item.order = this.items[section.id].length;
                item.save(this).subscribe(() => {
                    this.items[section.id].push(new SectionItem());
                });
            });
        }
        if (section.res_model == Course.Model) {
            this.courseDialog.show()
            this.courseDialog.onSelectCourses.first().subscribe((course: Course) => {
                item.res_id = course.id;
                item.name = course.name;
                item.section_id = section.id;
                item.order = this.items[section.id].length;
                item.save(this).subscribe(() => {
                    this.items[section.id].push(new SectionItem());
                });
            });
        }
        if (section.res_model.startsWith(User.Model)) {
            var role = section.res_model.split('/')[1];
            if (role == 'teacher') {
                this.teacherDialog.show();
                this.teacherDialog.onSelectUsers.first().subscribe(users => {
                    if (users.length != 1) {
                        this.error('You must select one teacher');
                        return;
                    }
                    var user = users[0];
                    item.res_id = user.id;
                    item.name = user.name;
                    item.section_id = section.id;
                    item.order = this.items[section.id].length;
                    item.save(this).subscribe(() => {
                        this.items[section.id].push(new SectionItem());
                    });
                });
            }
        }
        if (section.res_model == SectionItem.Model) {
            item.section_id = section.id;
            item.order = this.items[section.id].length;
            this.itemDialog.show(item);
            this.itemDialog.onCreateComplete.first().subscribe(() => {
                this.items[section.id].push(new SectionItem());
            });
        }
    }

    showItem(item: SectionItem) {
        item.is_hidden = false;
        item.save(this).subscribe(() => {
            this.success("Action completed");
        });
    }

    hideItem(item: SectionItem) {
        item.is_hidden = true;
        item.save(this).subscribe(() => {
            this.success("Action completed");
        });
    }

    moveUp(section: PageSection, itemIndex: number) {
        if (itemIndex > 0) {
            var tmp = this.items[section.id][itemIndex];
            this.items[section.id][itemIndex] = this.items[section.id][itemIndex - 1];
            this.items[section.id][itemIndex - 1] = tmp;
            _.each(this.items[section.id], (item: SectionItem, idx) => {
                item.order = idx + 1;
            });
            var existItems = _.filter(this.items[section.id], (item: SectionItem) => {
                return !item.IsNew
            });
            SectionItem.updateArray(this, existItems).subscribe(() => {
                this.success('Action completed');
            });
        }
    }

    moveDown(section: PageSection, itemIndex: number) {
        if (itemIndex + 1 < this.items[section.id].length - 1) {
            var tmp = this.items[section.id][itemIndex];
            this.items[section.id][itemIndex] = this.items[section.id][itemIndex + 1];
            this.items[section.id][itemIndex + 1] = tmp;
            _.each(this.items[section.id], (item: SectionItem, idx) => {
                item.order = idx + 1;
            });
            var existItems = _.filter(this.items[section.id], (item: SectionItem) => {
                return !item.IsNew
            });
            SectionItem.updateArray(this, existItems).subscribe(() => {
                this.success('Action completed');
            });
        }
    }


    removeItem(section: PageSection, item: SectionItem) {
        item.delete(this).subscribe(() => {
            this.items[section.id] = _.reject(this.items[section.id], (obj: SectionItem) => {
                return obj.id == item.id;
            });
            _.each(this.items[section.id], (item: SectionItem, idx) => {
                item.order = idx + 1;
            });
            var existItems = _.filter(this.items[section.id], (item: SectionItem) => {
                return !item.IsNew
            });
            SectionItem.updateArray(this, existItems).subscribe(() => {
                this.success('Action completed');
            });
        });
    }

    editItem(section: PageSection, item: SectionItem) {
        if (section.res_model == SectionItem.Model) {
            item.section_id = section.id;
            item.order = this.items[section.id].length;
            this.itemDialog.show(item);
        }
    }

    addPage() {
        let page: WebPage = new WebPage();
        page.order = this.pages.length + 1;
        this.pageDialog.show(page);
        this.pageDialog.onCreateComplete.first().subscribe(() => {
            this.pages.push({ label: page.name, value: page });
            this.selectedPage = null;
        });
    }

    editPage(page: WebPage) {
        this.pageDialog.show(page);
    }

    addSection() {
        if (this.selectedPage) {
            let section: PageSection = new PageSection();
            section.order = this.sections.length + 1;
            section.page_id = this.selectedPage.id;
            this.sectionDialog.show(section);
            this.sectionDialog.onCreateComplete.first().subscribe(() => {
                this.items[section.id] = [new SectionItem()];
                this.sections.push(section);
            });
        }
    }

    editSection(section: PageSection) {
        this.sectionDialog.show(section);
    }

}
