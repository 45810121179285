import { Component, OnInit, Input } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { AuthService } from '../../../shared/services/auth.service';
import { Group } from '../../../shared/models/elearning/group.model';
import { BaseComponent } from '../../../shared/components/base/base.component';
import { User } from '../../../shared/models/elearning/user.model';
import { TreeUtils } from '../../../shared/helpers/tree.utils';
import { TreeNode } from 'primeng/api';
import { GROUP_CATEGORY } from '../../../shared/models/constants';
import * as _ from 'underscore';

const GROUP_FIELDS = ['name', 'category', 'parent_id', 'user_count'];

@Component({
	
	selector: 'teacher-form-content',
	templateUrl: 'teacher-content.component.html',
	styleUrls: ['teacher-content.component.css'],
})
export class TeacherContentComponent extends BaseComponent {

	private user: User;

	constructor(private router: Router, private route: ActivatedRoute) {
		super();
		this.user = new User();
	}


	render(user: User) {
		this.user = user;
	}


}

