import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { AuthService } from '../../../shared/services/auth.service';
import { Group } from '../../../shared/models/elearning/group.model';
import { Course } from '../../../shared/models/elearning/course.model';
import { CourseMember } from '../../../shared/models/elearning/course-member.model';
import { BaseDialog } from '../../../shared/components/base/base.dialog';
import { User } from '../../../shared/models/elearning/user.model';
import * as _ from 'underscore';
import { TreeUtils } from '../../../shared/helpers/tree.utils';
import { TreeNode } from 'primeng/api';
import { COURSE_MEMBER_ROLE, COURSE_MEMBER_ENROLL_STATUS } from '../../../shared/models/constants';
import { BaseModel } from '../../../shared/models/base.model';
import { ExcelService } from '../../../shared/services/excel.service';

@Component({
	
	selector: 'lms-profile-dialog',
	templateUrl: 'lms-profile-dialog.component.html',
	styleUrls: ['lms-profile-dialog.component.css'],
})
export class LMSProfileDialog extends BaseDialog<User> {

	COURSE_MEMBER_ENROLL_STATUS = COURSE_MEMBER_ENROLL_STATUS;

	private courseMembers: CourseMember[];


	constructor(private excelService: ExcelService) {
		super();
		this.courseMembers = [];
	}


	ngOnInit() {
		this.onShow.subscribe((object: CourseMember) => {
			this.courseMembers = [];
			object.populateUser(this).subscribe(() => {
				object.user.listCourseMembers(this).subscribe(members => {
					this.courseMembers = members;
					this.displayCourseHistory();
				})
			})
		});
	}

	displayCourseHistory() {
		this.courseMembers = _.filter(this.courseMembers, (member: CourseMember) => {
			return member.role == 'student';
		});
	}


	exportCourse() {
		let output = _.map(this.courseMembers, courseMember => {
			return {
				'Course': courseMember['course_name'], 'Register date': courseMember['date_register'], 'Enrollment status': courseMember['enroll_status'], 'Certificate': courseMember['certificate'] ? 'Yes' : 'No'
			};
		})
		this.excelService.exportAsExcelFile(output, 'course_history_report');
	}


}

