import { NgModule } from '@angular/core';
import { AuthModule } from '../auth/auth.module';
import { ErpSharedModule } from '../shared/shared.module';
import { AccountModule } from '../account/account.module';
import { CRMRoutingModule } from './crm-routing';
import { CRMComponent } from './crm.component';
import { CustomerListComponent } from './customer/customer-list/customer-list.component';
import { PaymentRequestListComponent } from './recharge/payment-request-list/payment-request-list.component';
import { DepositDialog } from './recharge/deposit-dialog/deposit-dialog.component';
import { MailingListComponent } from './customer/mailing-list/mailing-list.component';
import { PaymentHistoryDialog } from './customer/payment-history/payment-history-dialog.component';
import { ActivationHistoryDialog } from './customer/activation-history/activation-history-dialog.component';
import { LMSModule } from '../lms/lms.module';
import { NewDepositDialog } from './recharge/new-deposit-dialog/new-deposit-dialog.component';
import { DepositContentDialog } from './recharge/deposit-content-dialog/deposit-content-dialog.component';


@NgModule({
	imports: [
		CRMRoutingModule,
		ErpSharedModule,
		AccountModule,
		LMSModule,
		AuthModule
	],
	declarations: [
		CRMComponent,
		PaymentRequestListComponent,
		CustomerListComponent,
		MailingListComponent,
		DepositDialog,
		PaymentHistoryDialog,
		ActivationHistoryDialog,
		NewDepositDialog,
		DepositContentDialog,
	],
	exports: [

	],
	providers: []
})
export class CRMModule {
}
