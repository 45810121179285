import { Component, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable, Subscription } from 'rxjs/Rx';
import { BaseComponent } from '../../../shared/components/base/base.component';
import { AuthService } from '../../../shared/services/auth.service';
import * as _ from 'underscore';
import { USER_STATUS, GROUP_CATEGORY } from '../../../shared/models/constants';
import { User } from '../../../shared/models/elearning/user.model';
import { Group } from '../../../shared/models/elearning/group.model';
import { TreeUtils } from '../../../shared/helpers/tree.utils';
import { TreeNode } from 'primeng/api';
import { CourseMember } from '../../../shared/models/elearning/course-member.model';
import { BaseModel } from '../../../shared/models/base.model';
import { LearnerViewDialog } from '../../../account/user/learner-view/learner-view.dialog.component';
import { PaymentHistoryDialog } from '../payment-history/payment-history-dialog.component';
import { ActivationHistoryDialog } from '../activation-history/activation-history-dialog.component';

const USER_FIELDS = ['group_id', 'name', 'login', 'email', 'phone', 'group_name', 'date_start', 'date_expire'];

@Component({
  
  selector: 'customer-list',
  templateUrl: 'customer-list.component.html',
  styleUrls: ['customer-list.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class CustomerListComponent extends BaseComponent {

  private customers: User[];
  private selectedCustomer: any;


  @ViewChild(LearnerViewDialog,{static:true}) learnerProfileDialog: LearnerViewDialog;
  @ViewChild(PaymentHistoryDialog,{static:true}) paymentHistoryDialog: PaymentHistoryDialog;
  @ViewChild(ActivationHistoryDialog,{static:true}) activationHistoryDialog: ActivationHistoryDialog;

  constructor(private route: ActivatedRoute) {
    super();
  }

  ngOnInit() {
    this.loadCustomers();
  }

  loadCustomers() {
    User.listAllLearner(this, USER_FIELDS).subscribe(customers => {
      this.customers = _.sortBy(customers, (customer: User) => {
        return -customer.id;
      });
      this.selectedCustomer = null;
    });
  }

  viewAccountProfile(learner: User) {
    learner.populate(this).subscribe(() => {
      this.learnerProfileDialog.show(learner);
    });

  }

  viewPaymentHistory(learner: User) {
    learner.populate(this).subscribe(() => {
      this.paymentHistoryDialog.show(learner);
    });
  }

  viewActivationCodeHistory(learner: User) {
    learner.populate(this).subscribe(() => {
      this.activationHistoryDialog.show(learner);
    });
  }

}
