import { NgModule } from '@angular/core';
import { AuthModule } from '../auth/auth.module';
import { ErpSharedModule } from '../shared/shared.module';
import { AnalysisModule } from '../analysis/analysis.module';
import { CMSModule } from '../cms/cms.module';
import { LMSComponent } from './lms.component';
import { LMSProfileDialog } from './course/lms-profile/lms-profile-dialog.component';
import { WebcamModule } from 'ngx-webcam';
import { CourseEditComponent } from './course/course-edit/course-edit.component';
import { LMSRoutingModule } from './lms-routing';
import { CourseFaqDialog } from './course/course-faq/course-faq.dialog.component';
import { CourseMaterialDialog } from './course/course-material/course-material.dialog.component';
import { CourseResolve } from './route.resolver';

@NgModule({
    imports: [
        LMSRoutingModule,
        ErpSharedModule,
        CMSModule,
        AuthModule,
        WebcamModule,
        AnalysisModule,

    ],
    declarations: [
        LMSComponent,
        CourseEditComponent,
        CourseFaqDialog,
        CourseMaterialDialog,
        LMSProfileDialog,
    ],

    exports: [
        LMSProfileDialog
    ],
    providers: [CourseResolve]

})
export class LMSModule {
}
