import { NgModule, Injector, ApplicationRef } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { APP_BASE_HREF } from '@angular/common';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { AppRoutingModule } from './app-routing.module';
import { AuthModule } from './auth/auth.module';
import { HomeModule } from './home/home.module';
import { ErpSharedModule } from './shared/shared.module';
import { SettingModule } from './setting/setting.module';
import { AccountModule } from './account/account.module';
import { CourseModule } from './course/course.module';
import { AnalysisModule } from './analysis/analysis.module';
import { DashboardModule } from './dashboard/dashboard.module';
import { CMSModule } from './cms/cms.module';
import { CRMModule } from './crm/crm.module';
import { LMSModule } from './lms/lms.module';
import { SalesModule } from './sales/sales.module';
import { ServiceLocator } from './service.locator';
import { AppComponent } from './app.component';
import { CustomTranslationLoader } from './shared/helpers/translation.loader';
import { TranslateService } from '@ngx-translate/core';

@NgModule({
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        AppRoutingModule,
        AuthModule,
        HomeModule.forRoot(),
        LMSModule,
        DashboardModule,
        SettingModule,
        AccountModule,
        CourseModule,
        CRMModule,
        SalesModule,
        AnalysisModule,
        CMSModule,
        ErpSharedModule.forRoot(),
        TranslateModule.forRoot({
            loader: {provide: TranslateLoader, useClass: CustomTranslationLoader}
        })
    ],
    declarations: [AppComponent],
    bootstrap: [AppComponent]

})
export class AppModule {

    constructor(injector: Injector) {
        ServiceLocator.injector = injector;
    }
}
