import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { AuthService } from '../../../shared/services/auth.service';
import { Group } from '../../../shared/models/elearning/group.model';
import { BaseComponent } from '../../../shared/components/base/base.component';
import { User } from '../../../shared/models/elearning/user.model';
import { GENDER, COURSE_MEMBER_ROLE, COURSE_MEMBER_ENROLL_STATUS } from '../../../shared/models/constants';
import { BaseModel } from '../../../shared/models/base.model';
import { Course } from '../../../shared/models/elearning/course.model';
import { CourseMember } from '../../../shared/models/elearning/course-member.model';
import { StaffViewContentComponent } from './staff-content.component';
import { StaffFormDialog } from '../staff-form/staff-form-dialog.component';
import * as _ from 'underscore';


@Component({
	
	selector: 'staff-view-dialog',
	templateUrl: 'staff-view.dialog.component.html',
})
export class StaffViewDialog extends BaseComponent{

	@ViewChild(StaffFormDialog,{static:true}) formDialog: StaffFormDialog;
	@ViewChild(StaffViewContentComponent,{static:true}) viewContent: StaffViewContentComponent;

	private user: User;
	private display: boolean;

	constructor(private router: Router, private route: ActivatedRoute) {
		super();
		this.user = new User();
	}

	show(user:User) {
		this.display = true;
		this.user = user;
		this.viewContent.render(this.user);
	}

	editUser() {
		this.formDialog.show(this.user);
		if (this.user.id == this.ContextUser.id)
			this.formDialog.onUpdateComplete.subscribe(()=> {
				this.authService.UserProfile =  this.user;
			});
	}

	close() {
		this.display = false;
	}


}

