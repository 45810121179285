import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { DatePipe } from '@angular/common';
import { Observable, Subject } from 'rxjs/Rx';
import { ReportUtils } from '../../../../shared/helpers/report.utils';
import { Group } from '../../../../shared/models/elearning/group.model';
import { BaseComponent } from '../../../../shared/components/base/base.component';
import { User } from '../../../../shared/models/elearning/user.model';
import { Course } from '../../../../shared/models/elearning/course.model';
import { CourseLog } from '../../../../shared/models/elearning/log.model';
import { CourseMember } from '../../../../shared/models/elearning/course-member.model';
import * as _ from 'underscore';
import { EXPORT_DATETIME_FORMAT, REPORT_CATEGORY, GROUP_CATEGORY, COURSE_MODE, DEFAULT_DATE_LOCALE, EXPORT_DATE_FORMAT } from '../../../../shared/models/constants'
import { Report } from '../../report.decorator';
import { SelectGroupDialog } from '../../../../shared/components/select-group-dialog/select-group-dialog.component';
import { SelectCoursesDialog } from '../../../../shared/components/select-course-dialog/select-course-dialog.component';
import { TimeConvertPipe } from '../../../../shared/pipes/time.pipe';
import { ExcelService } from '../../../../shared/services/excel.service';
import { DateUtils } from '../../../../shared/helpers/date.utils';
import { StaffMonthlyReportComponent } from './staff-monthly-report.component';
import { SelectStaffDialog } from '../../../../shared/components/select-staff-dialog/select-staff-dialog.component';
import { ActivatedRoute } from '@angular/router';


@Component({
    
    selector: 'staff-monthly-report-container',
    templateUrl: 'staff-monthly-report-container.component.html',
})
@Report({
    title: 'Staff monthly report',
    category: REPORT_CATEGORY.FINANCE
})
export class StaffMonthlyReportContainerComponent extends BaseComponent implements OnInit {

    private monthYear: any;
    private dateUtils: DateUtils;
    private user: User;
    private start: Date;
    private end: Date;
    private permission: string;

    @ViewChild(StaffMonthlyReportComponent,{static:true}) staffMonthlyReport: StaffMonthlyReportComponent;
    @ViewChild(SelectStaffDialog,{static:true}) selectStaffDialog: SelectStaffDialog;

    constructor(private route: ActivatedRoute) {
        super();
    }

    ngOnInit() {
        this.user = new User();
        this.permission = this.route.snapshot.data['permission'];
        if (this.permission == 'Staff') {
            this.user = this.ContextUser;
        }
    }

    export() {
        this.staffMonthlyReport.export();
    }

    selectMonth() {
        var year = this.monthYear.split('-')[0];
        var month = this.monthYear.split('-')[1];
        var now = new Date();
        now.setMonth(+month - 1);
        now.setFullYear(year);
        var dateUtils = new DateUtils();
        this.start = dateUtils.firstDateOfMonth(now);
        this.end = dateUtils.lastDateOfMonth(now);
        this.checkParameterReport(this.start, this.end, this.user);
    }

    selectStaff() {
        this.selectStaffDialog.show();
        this.selectStaffDialog.onSelectUser.first().subscribe(user => {
            var referal_code = user.referal_code;
            this.user = user;
            this.checkParameterReport(this.start, this.end, this.user);
        });
    }

    checkParameterReport(start_date, end_date, user) {
        if (!user.id) {
            this.warn('Chọn nhân viên để có thể xem báo cáo');
        } else if (!start_date) {
            this.warn('Chọn tháng để có thể xem báo cáo');
        } else {
            this.staffMonthlyReport.render(start_date, end_date, user);
        }
    }

    paidStaff(selectedStaff) {
        console.log(selectedStaff);
    }

    unPaidStaff(selectedStaff) {
        console.log(selectedStaff);
    }
}
