import { BaseModel } from '../base.model';
import { Observable, Subject } from 'rxjs/Rx';
import { Model,ReadOnlyProperty } from '../decorator';
import { APIContext } from '../context';
import { CourseUnit } from './course-unit.model';
import * as _ from 'underscore';
import { SearchReadAPI } from '../../services/api/search-read.api';
import { ListAPI } from '../../services/api/list.api';
import { User } from './user.model';
import { Course } from './course.model';

@Model('res.groups')
export class Group extends BaseModel{

    // Default constructor will be called by mapper
    constructor(){
        super();
        
        this.name = undefined;
        this.category = undefined;
        this.order = undefined;
        this.code = undefined;
        this.parent_id = undefined;
        this.course_count =  undefined;
        this.user_count =  undefined;
        this.logo_url =  undefined;
        this.logo_id = undefined;
    }

    name:string;
    category: string;
    code: string;
    order: string;
    parent_id: number;
    course_count: number;
    user_count: number;
    logo_url: string;
    logo_id: number;

     static __api__listUserGroup(fields?:string[]): SearchReadAPI {
        return new SearchReadAPI(Group.Model, fields,"[('category','=','organization')]");
    }

    static listUserGroup(context:APIContext,fields?:string[]):Observable<any> {
        return Group.search(context,fields,"[('category','=','organization')]");
    }

    static __api__listCourseGroup(fields?:string[]): SearchReadAPI {
        return new SearchReadAPI(Group.Model, fields,"[('category','=','course')]");
    }

    static listCourseGroup(context:APIContext,fields?:string[]):Observable<any> {
        return Group.search(context,fields,"[('category','=','course')]");
    }


    static __api__listUsers(groupId:number,fields?:string[]): SearchReadAPI {
        return new SearchReadAPI(User.Model,fields,"[('group_id','=',"+groupId+")]");
    }

    listUsers( context:APIContext,fields?:string[]): Observable<any[]> {
        if (!this.id)
            return Observable.of([]);
        return User.search(context,fields,"[('group_id','=',"+this.id+")]");
    }

    static __api__listCourses(groupId:number,fields?:string[]): SearchReadAPI {
        return new SearchReadAPI(Course.Model,fields,"[('group_id','=',"+groupId+")]");
    }

    listCourses( context:APIContext,fields?:string[]): Observable<any[]> {
        if (!this.id)
            return Observable.of([]);
        return Course.search(context,fields,"[('group_id','=',"+this.id+")]");
    }

    

}
