import { Component, OnInit, Input } from '@angular/core';
import { BaseDialog } from '../../../../shared/components/base/base.dialog';
import { WebPage } from '../../../../shared/models/elearning/web-cms.model';
import * as _ from 'underscore';


@Component({
	
	selector: 'web-page-dialog',
	templateUrl: 'page-dialog.component.html',
	styleUrls: ['page-dialog.component.css'],
})
export class WebPageDialog extends BaseDialog<WebPage>  {

	constructor() {
		super();
	}

}


