import { Component, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { BaseComponent } from '../../../shared/components/base/base.component';
import { AuthService } from '../../../shared/services/auth.service';
import * as _ from 'underscore';
import { USER_STATUS, GROUP_CATEGORY } from '../../../shared/models/constants'
import { User } from '../../../shared/models/elearning/user.model';
import { Group } from '../../../shared/models/elearning/group.model';
import { TreeUtils } from '../../../shared/helpers/tree.utils';
import { TreeNode } from 'primeng/api';
import { CourseMember } from '../../../shared/models/elearning/course-member.model';
import { BaseModel } from '../../../shared/models/base.model';
import { LearningPath } from '../../../shared/models/elearning/learning-path.model';
import { Router, ActivatedRoute, Params } from '@angular/router';

@Component({
    
    selector: 'hot-learning-path-list',
    templateUrl: 'learning-path-list.component.html',
    styleUrls: ['learning-path-list.component.css'],
    encapsulation: ViewEncapsulation.None
})
export class LearningPathListComponent extends BaseComponent {


    private paths: LearningPath[];
    private selectedPaths: any;


    constructor(private router: Router) {
        super();
    }

    ngOnInit() {
        LearningPath.all(this).subscribe(paths => {
            this.paths = paths;
        });
    }

    editPath(path:LearningPath) {
        this.router.navigate(['/path/form', path.id]);
    }


}
