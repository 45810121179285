import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { AuthService } from '../../../shared/services/auth.service';
import { Group } from '../../../shared/models/elearning/group.model';
import { BaseComponent } from '../../../shared/components/base/base.component';
import { User } from '../../../shared/models/elearning/user.model';
import * as _ from 'underscore';
import { LearnerContentComponent } from './learner-content.component';

const GROUP_FIELDS = ['name', 'category', 'parent_id', 'child_ids'];
const USER_FIELDS = ['email', 'role'];

@Component({
	
	selector: 'learner-form',
	templateUrl: 'learner-form.component.html',
})
export class LearnerFormComponent extends BaseComponent {

	@ViewChild(LearnerContentComponent,{static:true}) formContent: LearnerContentComponent;

	private user: User;

	constructor(private router: Router, private route: ActivatedRoute) {
		super();
		this.user = new User();
	}

	ngOnInit() {
		this.user = this.route.snapshot.data['user'];
		this.formContent.render(this.user);
	}

	save() {
		if (this.user.IsNew) {
			this.user.role = 'learner';
			User.all(this, USER_FIELDS).subscribe(users => {
				users = _.filter(users, (user: User) => {
					return user.role == this.user.role && user.email == this.user.email;
				});
				if (users.length != 0) {
					this.error(this.translateService.instant("Email has registered. Please choose another email"));
				} else {
					User.register(this, this.user).subscribe(resp => {
						if (!resp["success"]) {
							this.error(this.translateService.instant(resp["message"]));
							return;
						}
						this.user.id = +resp["user_id"];
						this.router.navigate(['/account/learner/view', this.user.id]);
					})
				}
			});

		}
		else {
			this.user.save(this).subscribe(() => {
				this.router.navigate(['/account/learner/view', this.user.id]);
			});
		}
	}

	cancel() {
		if (this.user.IsNew)
			this.router.navigate(['/account/learners']);
		else
			this.router.navigate(['/account/learner/view', this.user.id]);
	}
}

