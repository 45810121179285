import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { User } from '../../../shared/models/elearning/user.model';
import { BaseDialog } from '../../../shared/components/base/base.dialog';
import { CourseMember } from '../../../shared/models/elearning/course-member.model';
import * as _ from 'underscore';
import { PAYMENT_METHOD, PAYMENT_STATUS } from '../../../shared/models/constants'
import { PaymentRequest } from '../../../shared/models/elearning/payment-request.model';
import { Product } from '../../../shared/models/elearning/product.model';
import { SelectItem } from 'primeng/api';
import { ActivationCode } from '../../../shared/models/elearning/activation-code.model';
import { MapUtils } from '../../../shared/helpers/map.utils';
import { LearnerFormDialog } from '../../../account/user/learner-form/learner-form-dialog.component';
import { SelectLearnersDialog } from '../../../shared/components/select-learner-dialog/select-learner-dialog.component';
import { SelectCoursesDialog } from '../../../shared/components/select-course-dialog/select-course-dialog.component';
import { Course } from '../../../shared/models/elearning/course.model';
import { PaymentDetail } from '../../../shared/models/elearning/payment-detail.model';
import { SelectStaffDialog } from '../../../shared/components/select-staff-dialog/select-staff-dialog.component';
import { Company } from '../../../shared/models/elearning/company.model';


const COMPANY_FIELDS = ['commission_rate_teacher'];

@Component({
	
	selector: 'new-deposit-dialog',
	templateUrl: 'new-deposit-dialog.component.html',
	styleUrls: ['new-deposit-dialog.component.css'],
})
export class NewDepositDialog extends BaseDialog<PaymentRequest> {

	PAYMENT_METHOD = PAYMENT_METHOD;
	PAYMENT_STATUS = PAYMENT_STATUS;

	@ViewChild(LearnerFormDialog,{static:true}) learnerDialog: LearnerFormDialog;
	@ViewChild(SelectLearnersDialog,{static:true}) selectLearnesDialog: SelectLearnersDialog;
	@ViewChild(SelectCoursesDialog,{static:true}) selectCoursesDialog: SelectCoursesDialog;
	@ViewChild(SelectStaffDialog,{static:true}) selectStaffDialog: SelectStaffDialog;
	private learner: CourseMember;
	private learnerUser: User;
	private listCourseBuy: Course[];
	private courseBuy: Course;
	private company: Company;

	constructor() {
		super();
		this.learner = new CourseMember();
	}


	ngOnInit() {
		Company.main(this, COMPANY_FIELDS).subscribe(company => {
			this.company = company;
		});
		this.learner = new CourseMember();
		this.onShow.subscribe((object: PaymentRequest) => {
			this.listCourseBuy = [];
		});
	}

	addNewLearner() {
		this.learnerUser = new User();
		this.learnerUser.role = 'learner';
		this.learnerUser.email = this.object.email;
		this.learnerUser.name = this.object.name;
		this.learnerUser.phone = this.object.phone;
		this.learnerDialog.show(this.learnerUser);
		this.learnerDialog.onCreateComplete.first().subscribe(user => {
			this.object.user_id = user.id;
			this.object.user_name = user.name;
			this.object.name = user.name;
			this.object.email = user.email;
			this.object.phone = user.phone;
		});
	}

	selectLearner() {
		this.selectLearnesDialog.show();
		this.selectLearnesDialog.onSelectUsers.first().subscribe(users => {
			this.object.name = users[0].name;
			this.object.email = users[0].email;
			this.object.phone = users[0].phone;
			this.object.user_id = users[0].id;
			this.object.user_name = users[0].name;
		});
	}

	selectStaff() {
		this.selectStaffDialog.show();
		this.selectStaffDialog.onSelectUser.first().subscribe(users => {
			this.object.referal_code = users.referal_code;
		});
	}

	addCourse() {
		this.selectCoursesDialog.show();
		this.selectCoursesDialog.onSelectCourses.first().subscribe(course => {
			if (this.containsObject(course, this.listCourseBuy)) {
				this.warn('Khóa học đã có trong chi tiết thanh toán');
			} else {
				this.listCourseBuy.push(course);
				this.object.amount = this.totalPayment();
			}
		});
	}

	removeCourse(rowIndex) {
		this.listCourseBuy.splice(rowIndex, 1);
		this.object.amount = this.totalPayment();
	}

	confirm() {
		var account = this.learnerUser ? { login: this.learnerUser.login, password: this.learnerUser["password"] } : {};
		this.object.save(this).subscribe(() => {
			this.ContextUser.confirmPayment(this, this.object.id, account).subscribe(result => {
				this.object.status = 'proccessed';
				let actvCode: ActivationCode = MapUtils.deserialize(ActivationCode, result["code"]);
				var string = this.translateService.instant('Activation code') + ' ' + this.translateService.instant('has ben sent to user') + ' ' + this.object.user_name;
				this.success(string);
				this.hide();
			});
		});
	}

	save() {
		this.object.save(this).subscribe((resp => {
			if (!resp.id) {
				this.error('Không tạo được yêu cầu thanh toán');
				return;
			} else {
				this.listCourseBuy.forEach(course => {
					var detail = new PaymentDetail();
					detail.course_id = course.id;
					detail.request_id = resp.id;
					detail.teacher_name = course.teacher;
					detail.pay_teacher = false;
					var comission = 0;
					if (course.sale != 0) {
						comission = course.sale * this.company.commission_rate_teacher / 100;
					} else {
						comission = course.price * this.company.commission_rate_teacher / 100;
					}
					var result = { 'comisson_rate': this.company.commission_rate_teacher, 'comisson': comission };
					detail.commission_teacher = JSON.stringify(result);
					detail.save(this).subscribe();
				});
				var string = 'Thêm yêu cầu thanh toán thành công với mã hóa đơn là ' + resp.ref;
				this.success(string);
				this.hide();
			}
		}));
	}

	containsObject(obj, list) {
		var i;
		for (i = 0; i < list.length; i++) {
			if (list[i] === obj) {
				return true;
			}
		}
		return false;
	}

	totalPayment() {
		var total = 0;
		this.listCourseBuy.forEach(detail => {
			if (detail.sale == 0) {
				total = total + detail.price;
			} else {
				total = total + detail.sale;
			}
		});
		return total;
	}
}
