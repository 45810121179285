import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { AuthService } from '../../../shared/services/auth.service';
import { Group } from '../../../shared/models/elearning/group.model';
import { BaseDialog } from '../../../shared/components/base/base.dialog';
import { User } from '../../../shared/models/elearning/user.model';
import * as _ from 'underscore';
import { StaffContentComponent } from './staff-content.component';

const GROUP_FIELDS = ['name', 'category', 'parent_id', 'child_ids'];

@Component({
	
	selector: 'staff-form-dialog',
	templateUrl: 'staff-form-dialog.component.html',
})
export class StaffFormDialog extends BaseDialog<User> {
	
	@ViewChild(StaffContentComponent,{static:true}) formContent: StaffContentComponent;


	constructor(private router: Router, private route: ActivatedRoute) {
		super();
	}


	ngOnInit() {
		this.onShow.subscribe(object=> {
			this.formContent.render(object);
		});
	}


	save() {
		if (this.object.IsNew) {
			User.register(this, this.object).subscribe(resp => {
				if (!resp["success"]) {
					this.error(this.translateService.instant(resp["message"]));
					return;
				}
				this.object.id = +resp["user_id"];
				this.onCreateCompleteReceiver.next(this.object);
				this.hide();
			})
		}
		else {
			this.object.save(this).subscribe(() => {
				this.onUpdateCompleteReceiver.next(this.object);
				this.hide();
			});
		}
	}

}

