import { SearchReadAPI } from '../../services/api/search-read.api';
import { BaseModel } from '../base.model';
import { MODEL_METADATA_KEY, Model } from '../decorator';
import { APIContext } from '../context';
import { MapUtils } from '../../helpers/map.utils';
import { Observable, Subject } from 'rxjs/Rx';

@Model('res.company')
export class Company extends BaseModel {


    // Default constructor will be called by mapper
    constructor() {
        super();

        this.logo = undefined;
        this.bank_account = undefined;
        this.email = undefined;
        this.name = undefined;
        this.country_id = undefined;
        this.currency_id = undefined;
        this.fax = undefined;
        this.phone = undefined;
        this.street = undefined;
        this.state_id = undefined;
        this.city = undefined;
        this.vat = undefined;
        this.website = undefined;
        this.registry = undefined;
        this.mobile = undefined;
        this.hotline = undefined;
        this.fb_link = undefined;
        this.yt_link = undefined;
        this.gl_link = undefined;
        this.head_quarter = undefined;
        this.commission_rate = undefined;
        this.commission_rate_teacher = undefined;
    }

    commission_rate: number;
    logo: string;
    head_quarter: string;
    email: string;
    bank_account: string;
    name: string;
    country_id: number;
    currency_id: number;
    fax: string;
    phone: string;
    state_id: number;
    city: string;
    street: string;
    vat: string;
    website: string;
    registry: string;
    mobile: string;
    hotline: string;
    fb_link: string;
    yt_link: string;
    gl_link: string;
    commission_rate_teacher: number;

    static main(context: APIContext, fields?: string[]): Observable<any> {
        return Company.single(context, fields, "[]");
    }


}
