import { BaseModel } from '../base.model';
import { Observable, Subject } from 'rxjs/Rx';
import { Model, ReadOnlyProperty } from '../decorator';
import { APIContext } from '../context';
import { CourseUnit } from './course-unit.model';
import * as _ from 'underscore';
import { SearchReadAPI } from '../../services/api/search-read.api';
import { ListAPI } from '../../services/api/list.api';
import { User } from './user.model';
import { Course } from './course.model';


@Model('opencourse.web_page')
export class WebPage extends BaseModel {

    // Default constructor will be called by mapper
    constructor() {
        super();

        this.name = undefined;
        this.content = undefined;
        this.order = undefined;
        this.code = undefined;
        this.section_ids = [];
        this.title = undefined;
        this.metadata = undefined;
    }

    name: string;
    content: string;
    code: string;
    order: number;
    parent_id: number;
    @ReadOnlyProperty()
    section_ids: number[];
    title: string;
    metadata: string;

    static __api__listSections(sectionId: number): SearchReadAPI {
        return new SearchReadAPI(PageSection.Model, [], "[('page_id','='," + sectionId + ")]");
    }

    listSections(context: APIContext): Observable<any> {
        return PageSection.search(context, [], "[('page_id','='," + this.id + ")]");
    }

    static __api__listHomepageSections(sectionId: number): SearchReadAPI {
        return new SearchReadAPI(PageSection.Model, [], "[('page_code','=','HOME')]");
    }

    static listHomepageItems(context: APIContext): Observable<any> {
        return PageSection.search(context, [], "[('page_code','=','HOME')]");
    }

}

@Model('opencourse.page_section')
export class PageSection extends BaseModel {

    // Default constructor will be called by mapper
    constructor() {
        super();

        this.name = undefined;
        this.content = undefined;
        this.order = undefined;
        this.code = undefined;
        this.item_ids = [];
        this.page_id = undefined;
        this.res_model = undefined;
        this.page_code = undefined;
    }

    name: string;
    res_model: string;
    content: string;
    code: string;
    page_code: string;
    order: number;
    page_id: number;
    @ReadOnlyProperty()
    item_ids: number[];

    static __api__listItems(sectionId: number): SearchReadAPI {
        return new SearchReadAPI(SectionItem.Model, [], "[('section_id','='," + sectionId + ")]");
    }

    listItems(context: APIContext): Observable<any> {
        return SectionItem.search(context, [], "[('section_id','='," + this.id + ")]");
    }

}


@Model('opencourse.section_item')
export class SectionItem extends BaseModel {

    // Default constructor will be called by mapper
    constructor() {
        super();

        this.name = undefined;
        this.content = undefined;
        this.order = undefined;
        this.image_url = undefined;
        this.video_url =  undefined;
        this.web_url = undefined;
        this.section_id = undefined;
        this.res_id = undefined;
        this.page_id = undefined;
        this.page_code = undefined;
        this.section_code = undefined;
        this.is_hidden = undefined;
        this.title = undefined;
        this.video_file_id = undefined;
        this.image_file_id = undefined;
    }

    name: string;
    res_id: number;
    page_id: number;
    page_code: string;
    section_code: string;
    content: string;
    image_url: string;
    video_url: string;
    web_url: string;
    title: string;
    order: number;
    section_id: number;
    video_file_id: number;
    image_file_id: number;
    is_hidden: boolean;
}