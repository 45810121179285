import { NgModule } from '@angular/core';
import { Routes } from '@angular/router';
import { GroupListComponent } from '../shared/components/group-list/group-list.component';
import { UserListComponent } from './user/user-list/user-list.component';
import { PermissionListComponent } from './permission/permission-list/permission-list.component';
import { AdminGuard } from '../shared/guards/admin.guard';
import { RouterModule } from '@angular/router';
import { UserResolve, GroupsResolve, DateFormatResolve, PermissionResolve } from './route.resolver';
import { UserImportComponent } from './user/user-import/user-import.component';
import { PermissionFormComponent } from './permission/permission-form/permission-form.component';
import { PermissionViewComponent } from './permission/permission-view/permission-view.component';
import { LearnerFormDialog } from './user/learner-form/learner-form-dialog.component';
import { LearnerContentComponent } from './user/learner-form/learner-content.component';
import { LearnerFormComponent } from './user/learner-form/learner-form.component';
import { StaffFormDialog } from './user/staff-form/staff-form-dialog.component';
import { StaffContentComponent } from './user/staff-form/staff-content.component';
import { StaffFormComponent } from './user/staff-form/staff-form.component';
import { TeacherFormDialog } from './user/teacher-form/teacher-form-dialog.component';
import { TeacherContentComponent } from './user/teacher-form/teacher-content.component';
import { TeacherFormComponent } from './user/teacher-form/teacher-form.component';
import { LearnerViewDialog } from './user/learner-view/learner-view.dialog.component';
import { LearnerViewContentComponent } from './user/learner-view/learner-content.component';
import { LearnerViewComponent } from './user/learner-view/learner-view.component';
import { StaffViewDialog } from './user/staff-view/staff-view.dialog.component';
import { StaffViewContentComponent } from './user/staff-view/staff-content.component';
import { StaffViewComponent } from './user/staff-view/staff-view.component';
import { TeacherViewDialog } from './user/teacher-view/teacher-view.dialog.component';
import { TeacherViewContentComponent } from './user/teacher-view/teacher-content.component';
import { TeacherViewComponent } from './user/teacher-view/teacher-view.component';

export const AccountRoutes: Routes = [
  {
    path: 'account',
    data: {
      breadcrumb: 'Account'
    },
    canActivate: [AdminGuard],
    children:
    [
      {
        path: "groups",
        component: GroupListComponent,
        data: {
          breadcrumb: 'User groups',
          category:'organization'
        },
      },
      {
        path: "staffs",
        component: UserListComponent,
        data: {
          breadcrumb: 'Users',
          role:'staff'
        },
      },
      {
        path: "teachers",
        component: UserListComponent,
        data: {
          breadcrumb: 'Users',
          role:'teacher'
        },
      },
      {
        path: "learners",
        component: UserListComponent,
        data: {
          breadcrumb: 'Users',
          role:'learner'
        },
      },
      {
        path: "staffs/import",
        component: UserImportComponent,
        resolve: {
          groups: GroupsResolve,
          dateFormat: DateFormatResolve
        },
        data: {
          breadcrumb: 'Import users'
        },
      },
      {
        path: "staff/view/:userId",
        component: StaffViewComponent,
        resolve: {
          user: UserResolve
        },
        data: {
          breadcrumb: 'Staff view form'
        },
      },
      {
        path: "staff/form/:userId",
        component: StaffFormComponent,
        resolve: {
          user: UserResolve
        },
        data: {
          breadcrumb: 'Staff edit form'
        },
      },
      {
        path: "staff/form",
        component: StaffFormComponent,
        resolve: {
          user: UserResolve
        },
        data: {
          breadcrumb: 'Staff create form'
        },
      },
      {
        path: "learner/view/:userId",
        component: LearnerViewComponent,
        resolve: {
          user: UserResolve
        },
        data: {
          breadcrumb: 'Learner view form'
        },
      },
      {
        path: "learner/form/:userId",
        component: LearnerFormComponent,
        resolve: {
          user: UserResolve
        },
        data: {
          breadcrumb: 'Learner edit form'
        },
      },
      {
        path: "learner/form",
        component: LearnerFormComponent,
        resolve: {
          user: UserResolve
        },
        data: {
          breadcrumb: 'Learner create form'
        },
      },
      {
        path: "teacher/view/:userId",
        component: TeacherViewComponent,
        resolve: {
          user: UserResolve
        },
        data: {
          breadcrumb: 'Teacher view form'
        },
      },
      {
        path: "teacher/form/:userId",
        component: TeacherFormComponent,
        resolve: {
          user: UserResolve
        },
        data: {
          breadcrumb: 'Teacher edit form'
        },
      },
      {
        path: "teacher/form",
        component: TeacherFormComponent,
        resolve: {
          user: UserResolve
        },
        data: {
          breadcrumb: 'Teacher create form'
        },
      },
      {
        path: "permissions",
        component: PermissionListComponent,
        data: {
          breadcrumb: 'Permission'
        },
      },
      {
        path: "permission/view/:permissionId",
        component: PermissionViewComponent,
        resolve: {
          permission: PermissionResolve
        },
        data: {
          breadcrumb: 'Permission view form'
        },
      },
      {
        path: "permission/form/:permissionId",
        component: PermissionFormComponent,
        resolve: {
          permission: PermissionResolve
        },
        data: {
          breadcrumb: 'Permission edit form'
        },
      },
      {
        path: "permission/form",
        component: PermissionFormComponent,
        resolve: {
          permission: PermissionResolve
        },
        data: {
          breadcrumb: 'Permission create form'
        },
      },
      
    ]
  }

]

@NgModule({
  imports: [RouterModule.forChild(AccountRoutes)],
  exports: [RouterModule]
})
export class AccountRoutingModule {}