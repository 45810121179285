import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs/Rx';
import { AuthService } from './auth.service';
import { SettingService } from './setting.service';
import 'rxjs/add/operator/map';
import { BaseComponent } from '../components/base/base.component';
import { Course } from '../models/elearning/course.model';
import { User } from '../models/elearning/user.model';
import { CourseMember } from '../models/elearning/course-member.model';
import * as _ from 'underscore';
import { TreeUtils } from '../helpers/tree.utils';
import { TreeNode } from 'primeng/api';
import { Subscription } from 'rxjs/Subscription';
import { CourseFaq } from '../models/elearning/course-faq.model';
import { CourseMaterial } from '../models/elearning/course-material.model';
import { CourseSyllabus } from '../models/elearning/course-syllabus.model';
import { SyllabusUtils } from '../helpers/syllabus.utils';
import { CourseUnit } from '../models/elearning/course-unit.model';
import { CourseLog } from '../models/elearning/log.model';
import { SelectItem } from 'primeng/api';
import { Group } from '../models/elearning/group.model';
import { ReportUtils } from '../helpers/report.utils';
import { Route, } from '@angular/router';
import { BaseModel } from '../models/base.model';
import { APIContext } from '../models/context';

const MEMBER_FIELDS = ['course_id', 'status', 'role', 'name', 'course_name', 'enroll_status', 'email', 'user_id', 'group_id', "id", "active",
"create_date",
"create_uid",
"write_date",
"write_uid"
];
@Injectable()
export class LMSProfileService {

  myCourseMembers: CourseMember[];
  courseContent: any;
  initialized: boolean;
  context: APIContext;
  

  constructor(private settingService: SettingService) {
    
    this.invalidateAll();
  }

  invalidateAll() {
    this.initialized = false;
    this.courseContent = {};
  }


  init(context: APIContext): Observable<any> {
    this.context = context;
    this.context.apiService.onLogin.subscribe(() => {
      this.invalidateAll();
    });
    this.context.apiService.onLogout.subscribe(() => {
      this.invalidateAll();
    });
    this.context.apiService.onTokenExpired.subscribe(() => {
      this.invalidateAll();
    });
    this.settingService.viewModeEvents.subscribe(()=> {
      this.invalidateAll();
    });
    if (this.initialized)
      return Observable.of([]);
    var user = context.authService.UserProfile;
    return user.populate(context).flatMap(() => {
      return BaseModel.bulk_search(context,
        User.__api__listCourseMembers(user.id, MEMBER_FIELDS)
      ).map(jsonArray => {

        this.myCourseMembers = _.filter(CourseMember.toArray(jsonArray[0]), (member: CourseMember) => {
          return isFinite(parseInt(member.course_id + "")) && member.status == 'active' ;
        });
        this.initialized = true;
      });
    })

  }

  get MyCourseMembers() {
    return this.myCourseMembers;
  }

  courseMemberById(id: number) {
    return _.find(this.myCourseMembers, (member: CourseMember) => {
      return member.id == id;
    });
  }

  getCourseContent(course: Course): Observable<any> {
    if (this.courseContent[course.id])
      return Observable.of(this.courseContent[course.id]);
    return BaseModel.bulk_list(this.context,
      Course.__api__populateSyllabus(course.id),
      Course.__api__listUnits(course.id),
      Course.__api__listFaqs(course.id),
      Course.__api__listMaterials(course.id)
    )
      .map(jsonArray => {
        var content = {};
        content["syllabus"] = CourseSyllabus.toArray(jsonArray[0])[0];
        content["units"] = CourseUnit.toArray(jsonArray[1]);
        content["faqs"] = CourseFaq.toArray(jsonArray[2]);
        content["materials"] = CourseMaterial.toArray(jsonArray[3]);
        this.courseContent[course.id] = content;
        return content;
      });
  }

  invalidateCourseContent(courseId: number) {
    delete this.courseContent[courseId];
  }


  getLastCourseTimestamp(course: Course) {
    var timestamp = Date.parse(course.create_date.toString());
    var editorRole = this.getCourseMemberByRole('editor', course.id);
    var studentRole = this.getCourseMemberByRole('student', course.id);
    var teacherRole = this.getCourseMemberByRole('teacher', course.id);
    var supervisorRole = this.getCourseMemberByRole('supervisor', course.id);
    if (studentRole && Date.parse(studentRole.create_date.toString()) < timestamp)
      timestamp = Date.parse(studentRole.create_date.toString());
    if (teacherRole && Date.parse(teacherRole.create_date.toString()) < timestamp)
      timestamp = Date.parse(teacherRole.create_date.toString());
    if (editorRole && Date.parse(editorRole.create_date.toString()) < timestamp)
      timestamp = Date.parse(editorRole.create_date.toString());
    if (supervisorRole && Date.parse(supervisorRole.create_date.toString()) < timestamp)
      timestamp = Date.parse(supervisorRole.create_date.toString());
    return timestamp;
  }

  getCourseMemberByRole(role: string, courseId: number) {
    return _.find(this.myCourseMembers, (member: CourseMember) => {
      return member.role == role && member.course_id == courseId;
    });
  }

}
