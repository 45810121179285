import { Component, OnInit, Input, ViewChild, NgZone } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { AuthService } from '../../../shared/services/auth.service';
import { Group } from '../../../shared/models/elearning/group.model';
import { User } from '../../../shared/models/elearning/user.model';
import { BaseComponent } from '../../../shared/components/base/base.component';
import { Course } from '../../../shared/models/elearning/course.model';
import { CourseMember } from '../../../shared/models/elearning/course-member.model';
import * as _ from 'underscore';
import { TreeUtils } from '../../../shared/helpers/tree.utils';
import { TreeNode } from 'primeng/api';
import { SelectItem, MenuItem } from 'primeng/api';
import { GROUP_CATEGORY, COURSE_STATUS, COURSE_MODE, COURSE_MEMBER_ROLE, COURSE_MEMBER_STATUS, COURSE_MEMBER_ENROLL_STATUS } from '../../../shared/models/constants'
import { SelectStaffDialog } from '../../../shared/components/select-staff-dialog/select-staff-dialog.component';

const GROUP_FIELDS = ['name', 'category', 'parent_id', 'course_count'];
const COURSE_FIELDS = ['code'];

@Component({
	
	selector: 'course-form',
	templateUrl: 'course-form.component.html',
	styleUrls: ['course-form.component.css'],
})
export class CourseFormComponent extends BaseComponent implements OnInit {

	private tree: TreeNode[];
	private selectedNode: TreeNode;
	private editor: CourseMember;
	private course: Course;
	private tags: any;
	private listCourse: Course[];

	@ViewChild(SelectStaffDialog,{static:true}) usersDialog: SelectStaffDialog;

	constructor(private router: Router, private route: ActivatedRoute, private ngZone: NgZone) {
		super();
		this.editor = new CourseMember();
		this.course = new Course();
	}

	nodeSelect(event: any) {
		this.selectedNode = event.node;
		if (this.selectedNode) {
			if (this.course.group_id != this.selectedNode.data.id) {
				this.course.group_id = this.selectedNode.data.id;
				this.course.group_name = this.selectedNode.data.name;
			} else {
				this.selectedNode = null;
				this.course.group_id = null;
				this.course.group_name = null;
			}
		}
	}

	nodeUnselect(event: any) {
		this.selectedNode = null;
	}


	selectEditor() {
		this.usersDialog.show();
		this.usersDialog.onSelectUser.first().subscribe(user => {
			this.editor.user_id = user.id;
			this.editor.name = user.name;
		});
	}


	ngOnInit() {
		this.course = this.route.snapshot.data['course'];
		if (this.course.metadata) {
			this.tags = JSON.parse(this.course.metadata);
		} else {
			this.tags = [];
		};
		if (this.course.IsNew) {
			this.editor = new CourseMember();
			this.course.supervisor_id = this.ContextUser.id;
			Course.all(this, COURSE_FIELDS).subscribe(courses => {
				this.listCourse = courses;
			});
		} else {
			this.course.courseEditor(this).subscribe(member => {
				if (!member) {
					this.editor = new CourseMember();
					this.editor.role = 'editor';
					this.editor.course_id = this.course.id;
				} else
					this.editor = member;
			});
		}
		Group.listCourseGroup(this, GROUP_FIELDS).subscribe(groups => {
			var treeUtils = new TreeUtils();
			this.tree = treeUtils.buildGroupTree(groups);
			if (this.course.group_id) {
				this.selectedNode = treeUtils.findTreeNode(this.tree, this.course.group_id);
			}
		});
	}

	uploadFile(file) {
		//this.apiService.upload_S3(file, this.authService.LoginToken).subscribe(
		this.apiService.upload(file, this.authService.LoginToken).subscribe(
			data => {
				if (data["result"]) {
					this.ngZone.run(() => {
						this.course.logo_url = data["url"];
						this.course.logo_id = data["attachment_id"];
					});
				}
			}
		);
	}


	changeImageFile(event: any) {
		let file = event.files[0];
		this.resizeImage(file, 500, 500).then(blob => {
			let fileNew = this.blobToFile(blob, file.name);
			this.uploadFile(fileNew);
		}, err => {
			console.error("Photo error", err);
		});
	}
	

	save() {
		this.tags = _.reject(this.tags, tag => {
			return !tag["name"];
		});
		this.course.metadata = JSON.stringify(this.tags);
		if (this.course.description == null) {
			this.course.description = '';
		}
		if (this.course.IsNew) {
			if (this.checkDuplicateCourseCode(this.course.code)) {
				this.warn('Mã khóa học đã được sử dụng.');
			} else {
				this.saveCourse();
			}
		} else {
			this.saveCourse();
		}
	}

	cancel() {
		this.router.navigate(['/course/list']);
	}

	addTag() {
		var tag = {};
		this.tags.push(tag);
	}

	checkDuplicateCourseCode(code) {
		var result = false;
		for (var i = 0; i < this.listCourse.length; i++) {
			if (this.listCourse[i]["code"] == code) {
				result = true;
			}
		}
		return result;
	}

	saveCourse() {
		this.course.save(this).subscribe(() => {
			this.editor.role = 'editor';
			this.editor.course_id = this.course.id;
			this.editor.save(this).subscribe(() => {
				this.router.navigate(['/course/view', this.course.id]);
			});
		});
	}
}

