import { Component, OnInit, Input, NgZone } from '@angular/core';
import { BaseDialog } from '../../../../shared/components/base/base.dialog';
import { PageSection, SectionItem } from '../../../../shared/models/elearning/web-cms.model';
import * as _ from 'underscore';
import { SelectItem } from 'primeng/api';
import { WindowRef } from '../../../../shared/helpers/windonw.ref';
import * as screenfull from 'screenfull';
declare var $: any;


@Component({
	
	selector: 'section-item-dialog',
	templateUrl: 'item-dialog.component.html',
	styleUrls: ['item-dialog.component.css'],
})
export class SectionItemDialog extends BaseDialog<SectionItem>  {
	WINDOW_HEIGHT: any;
	constructor(private ngZone: NgZone) {
		super();
		this.WINDOW_HEIGHT = $(window).height();
	}

	uploadFile(file, type) {
		//this.apiService.upload_S3(file, this.authService.LoginToken).subscribe(
		this.apiService.upload(file, this.authService.LoginToken).subscribe(
			data => {
				if (data["result"]) {
					this.ngZone.run(() => {
						if (type =='image') {
							this.object.image_url = data["url"];
							this.object.image_file_id = data["attachment_id"];
						}
						if (type =='video') {
							this.object.video_url = data["url"];
							this.object.video_file_id = data["attachment_id"];
						}
					});
				}
			}
		);
	}

	changeImageFile(event: any) {
		let file = event.files[0];
		this.uploadFile(file,'image');
	}

	changeVideoFile(event: any) {
		let file = event.files[0];
		this.uploadFile(file,'video');
	}

}


