import { Component, OnInit, ViewChild, ComponentFactoryResolver } from '@angular/core';
import { MenuItem } from 'primeng/primeng';
import { User } from '../../shared/models/elearning/user.model';
import { Course } from '../../shared/models/elearning/course.model';
import { CourseMember } from '../../shared/models/elearning/course-member.model';
import { BaseComponent } from '../../shared/components/base/base.component';
import { SelectItem } from 'primeng/api';
import { DateUtils } from '../../shared/helpers/date.utils';
import { Group } from '../../shared/models/elearning/group.model';
import * as _ from 'underscore';
import * as moment from 'moment';
import { TranslateService } from '@ngx-translate/core';
import { BaseModel } from '../../shared/models/base.model';
import { COURSE_MEMBER_ENROLL_STATUS, PAYMENT_STATUS } from '../../shared/models/constants';
import { PaymentRequest } from '../../shared/models/elearning/payment-request.model';
import { PaymentDetail } from '../../shared/models/elearning/payment-detail.model';

@Component({
    
    selector: 'admin-dashboard',
    templateUrl: 'admin-dashboard.component.html',
    styleUrls: ['admin-dashboard.component.css'],

})
export class AdminDashboardComponent extends BaseComponent implements OnInit {

    COURSE_MEMBER_ENROLL_STATUS = COURSE_MEMBER_ENROLL_STATUS;
    PAYMENT_STATUS = PAYMENT_STATUS;

    private newLearners: User[];
    private newMembers: CourseMember[];
    private header: any;
    private dateUtils: DateUtils;
    private requests: PaymentRequest[];

    constructor() {
        super();
        this.dateUtils = new DateUtils();
    }

    ngOnInit() {
        var now = new Date();
        User.searchByDateAndRole(this, 'learner', this.dateUtils.firstDateOfMonth(now), this.dateUtils.lastDateOfMonth(now)).subscribe(learners => {
            this.newLearners = learners;
            this.newLearners = _.sortBy(this.newLearners, (learners: any) => {
                return -learners.id;
            });
        });
        CourseMember.searchByDateAndRole(this, 'student', this.dateUtils.firstDateOfMonth(now), this.dateUtils.lastDateOfMonth(now)).subscribe(members => {
            this.newMembers = members;
            this.newMembers = _.sortBy(this.newMembers, (members: any) => {
                return -members.id;
            });
        });
        PaymentRequest.all(this).subscribe(requests => {
            PaymentDetail.all(this).subscribe(details => {
                this.requests = _.sortBy(requests, (request: PaymentRequest) => {
                    return -request.id;
                });
                this.requests.forEach((request: PaymentRequest) => {
                    details.forEach((detail: PaymentDetail) => {
                        if (request.id == detail.request_id) {
                            request.course_detail.push(detail.course_name);
                        }
                    });
                });
            });
        });
    }


}
