import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import * as _ from 'underscore';
import { BaseDialog } from 'src/app/shared/components/base/base.dialog';
import { CourseMember } from 'src/app/shared/models/elearning/course-member.model';
import { COURSE_MEMBER_ENROLL_STATUS } from 'src/app/shared/models/constants';

@Component({
    
    selector: 'member-by-course-detail-dialog',
    templateUrl: 'member-by-course-detail-dialog.component.html',
})
export class MemberByCourseDetailDialog extends BaseDialog<CourseMember> {

    private members: CourseMember[];
    COURSE_MEMBER_ENROLL_STATUS = COURSE_MEMBER_ENROLL_STATUS;

    constructor() {
        super();
        this.display = false;

    }

    ngOnInit() {
        this.members = [];
    }

    show(members: CourseMember[]){
        this.members = members;
        this.display = true;
    }


}

