import { Component, OnInit, Input } from '@angular/core';
import { Observable, Subject } from 'rxjs/Rx';

import { AuthService } from '../../services/auth.service';
import { Group } from '../../models/elearning/group.model';
import { BaseComponent } from '../base/base.component';
import { User } from '../../../shared/models/elearning/user.model';
import * as _ from 'underscore';
import { TreeUtils } from '../../../shared/helpers/tree.utils';
import { TreeNode } from 'primeng/api';
import { GROUP_CATEGORY, CONTENT_STATUS } from '../../../shared/models/constants'
import { SelectItem } from 'primeng/api';

const USER_FIELDS = ['name', 'group_name', 'login'];
const GROUP_FIELDS = ['name', 'category' ,'parent_id'];

@Component({
	
	selector: 'select-multi-user-dialog',
	templateUrl: 'select-multi-user-dialog.component.html',
	styleUrls: ['select-multi-user-dialog.component.css'],
})
export class SelectMultiUsersDialog extends BaseComponent {

	tree: TreeNode[];
	selectedNode: TreeNode;
	selectedUsers: User[];
	users: User[];
	display: boolean;
	treeUtils: TreeUtils;

	onSelectUsersReceiver: Subject<any> = new Subject();
	onSelectUsers: Observable<any> = this.onSelectUsersReceiver.asObservable();

	constructor() {
		super();
		this.display = false;
		this.selectedUsers = [];
		this.treeUtils = new TreeUtils();
	}

	hide() {
		this.display = false;
	}

	nodeSelect(event: any) {
		this.selectedNode = event.node;
		if (this.selectedNode) {
			this.selectedNode.data.listUsers(this, USER_FIELDS).subscribe(users => {
				this.users = users;
			});
		}
	}

	nodeUnselect(event: any) {
		this.selectedNode = null;
	}

	show() {
		this.display = true;
		this.selectedUsers = [];
		// , GROUP_CATEGORY.USER
		Group.listUserGroup(this).subscribe(groups => {
			var treeNodes = this.treeUtils.buildGroupTree(groups);
				this.tree = treeNodes
		});
	}

	select() {
		this.onSelectUsersReceiver.next(this.selectedUsers);
		this.selectedUsers = [];
		this.hide();
	}


}

