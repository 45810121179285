import { NgModule } from '@angular/core';
import { AuthModule } from '../auth/auth.module';
import { ErpSharedModule } from '../shared/shared.module';
import { SettingComponent } from './setting.component';
import { SettingRoutingModule } from './setting-routing';
import { ComapnySettingDialog } from './company-setting-dialog/company-setting-dialog.component';

@NgModule({
	imports: [
		SettingRoutingModule,
		ErpSharedModule,
		AuthModule
	],
	declarations: [
		SettingComponent,
		ComapnySettingDialog
	],
	exports: [ComapnySettingDialog],
	providers: []
})
export class SettingModule {
}
