import { Observable, Subject } from 'rxjs/Rx';
import { Model, FieldProperty, ReadOnlyProperty, UnserializeProperty } from '../decorator';
import { APIContext } from '../context';
import { BaseModel } from '../base.model';
import { Company } from './company.model';
import { Permission } from './permission.model';
import { SearchReadAPI } from '../../services/api/search-read.api';
import { SearchCountAPI } from '../../services/api/search-count.api';
import { ListAPI } from '../../services/api/list.api';
import { CourseMember } from './course-member.model';
import { Course } from './course.model';
import { SERVER_DATETIME_FORMAT } from '../constants';
import * as moment from 'moment';
import * as _ from 'underscore';
import { ExecuteAPI } from '../../services/api/execute.api';
import { PaymentRequest } from './payment-request.model';
import { ActivationCode } from './activation-code.model';
import { MapUtils } from '../../helpers/map.utils';

@Model('res.users')
export class User extends BaseModel {

    // Default constructor will be called by mapper
    constructor() {
        super();

        this.image = undefined;
        this.display_name = undefined;
        this.name = undefined;
        this.gender = undefined;
        this.dob = undefined;
        this.date_start = undefined;
        this.date_expire = undefined;
        this.position = undefined;
        this.email = undefined;
        this.group_id = undefined;
        this.group_code = undefined;
        this.group_name = undefined;
        this.login = undefined;
        this.phone = undefined;
        this.is_admin = undefined;
        this.banned = undefined;
        this.social_id = undefined;
        this.company_id = undefined;
        this.permission_id = undefined;
        this.permission_name = undefined;
        this.supervisor_id = undefined;
        this.supervisor_name = undefined;
        this.fb_link = undefined;
        this.tw_link = undefined;
        this.ln_link = undefined;
        this.referal_code = undefined;
        this.referer_id = undefined;
        this.role = undefined;
        this.intro = undefined;
        this.referal_name = undefined;
        this.debit = undefined;
        this.mobile = undefined;
    }

    image: string;
    name: string;
    group_code: string;
    gender: string;
    @FieldProperty<Date>()
    dob: Date;
    date_start: Date;
    date_expire: Date;
    position: string;
    email: string;
    group_id: number;
    group_name: string;
    login: string;
    phone: string;
    mobile: string;
    role: string;
    is_admin: boolean;
    banned: boolean;
    display_name: string;
    company_id: number;
    permission_id: number;
    permission_name: string;
    supervisor_id: number;
    supervisor_name: string;
    social_id: string;
    fb_link: string;
    tw_link: string;
    ln_link: string;
    intro: string;
    referal_code: string;
    referer_id: number;
    referal_name: string;
    debit: number;


    get IsAdmin() {
        return this.is_admin || this.login == 'admin';
    }

    get IsStaff() {
        return this.role == 'staff' || this.login == 'admin';
    }

    get IsSuperAdmin() {
        return this.login == 'admin' || (this.is_admin && !this.supervisor_id);
    }

    get IsTeacher() {
        return this.role == 'teacher';
    }

    getPermission(context: APIContext): Observable<any> {
        if (this.permission_id)
            return Permission.get(context, this.permission_id);
        else
            return Observable.of(new Permission());
    }


    static __api__all(fields?: string[]): SearchReadAPI {
        return new SearchReadAPI(User.Model, fields, "[('login','!=','admin')]");
    }

    static all(context: APIContext, fields?: string[]): Observable<any[]> {
        return User.search(context, fields, "[('login','!=','admin')]");
    }

    static __api__listAllAdmin(fields?: string[]): SearchReadAPI {
        return new SearchReadAPI(User.Model, fields, "[('login','!=','admin'),('is_admin','=',True),('role','=','staff')]");
    }

    static listAllAdmin(context: APIContext, fields?: string[]): Observable<any[]> {
        return User.search(context, fields, "[('login','!=','admin'),('is_admin','=',True),('role','=','staff')]");
    }

    static __api__listAllStaff(fields?: string[]): SearchReadAPI {
        return new SearchReadAPI(User.Model, fields, "[('role','=','staff')]");
    }

    static listAllStaff(context: APIContext, fields?: string[]): Observable<any[]> {
        return User.search(context, fields, "[('role','=','staff')]");
    }

    static __api__listAllTeacherer(fields?: string[]): SearchReadAPI {
        return new SearchReadAPI(User.Model, fields, "[('role','=','teacher')]");
    }

    static listAllTeacher(context: APIContext, fields?: string[]): Observable<any[]> {
        return User.search(context, fields, "[('role','=','teacher')]");
    }

    static __api__listAllLearner(fields?: string[]): SearchReadAPI {
        return new SearchReadAPI(User.Model, fields, "[('role','=','learner')]");
    }

    static listAllLearner(context: APIContext, fields?: string[]): Observable<any[]> {
        return User.search(context, fields, "[('role','=','learner')]");
    }

    static __api__countAllAdmin(): SearchCountAPI {
        return new SearchCountAPI(User.Model, "[('login','!=','admin'),('is_admin','=',True),('role','=','staff')]");
    }

    static countAllAdmin(context: APIContext): Observable<any> {
        return User.count(context, "[('login','!=','admin'),('is_admin','=',True),('role','=','staff')]");
    }

    static __api__countActive(): SearchCountAPI {
        return new SearchCountAPI(User.Model, "[('banned','=',False)]");
    }

    static countActive(context: APIContext): Observable<any> {
        return User.count(context, "[('banned','=',False)]");
    }

    static __api__countBanned(): SearchCountAPI {
        return new SearchCountAPI(User.Model, "[('banned','=',True)]");
    }

    static countBanned(context: APIContext): Observable<any> {
        return User.count(context, "[('banned','=',True)]");
    }


    static __api__countAllStaff(): SearchCountAPI {
        return new SearchCountAPI(User.Model, "[('role','=','staff')]");
    }

    static countAllStaff(context: APIContext): Observable<any> {
        return User.count(context, "[('role','=','staff')]");
    }

    static __api__countAllTeacher(): SearchCountAPI {
        return new SearchCountAPI(User.Model, "[('role','=','teacher')]");
    }

    static countAllTeacher(context: APIContext): Observable<any> {
        return User.count(context, "[('role','=','teacher')]");
    }

    static __api__countAllLearner(): SearchCountAPI {
        return new SearchCountAPI(User.Model, "[('role','=','learner')]");
    }

    static countAllLearner(context: APIContext): Observable<any> {
        return User.count(context, "[('role','=','learner')]");
    }

    static __api__searchByDateAndRole(role: string, start: Date, end: Date, fields?: string[]): SearchReadAPI {
        var startDateStr = moment(start).format(SERVER_DATETIME_FORMAT);
        var endDateStr = moment(end).format(SERVER_DATETIME_FORMAT);
        return new SearchReadAPI(User.Model, fields, "[('create_date','>=','" + startDateStr + "'),('create_date','<=','" + endDateStr + "'),('role','<=','" + role + "')]");
    }

    static searchByDateAndRole(context: APIContext, role: string, start: Date, end: Date, fields?: string[]): Observable<any> {
        var startDateStr = moment(start).format(SERVER_DATETIME_FORMAT);
        var endDateStr = moment(end).format(SERVER_DATETIME_FORMAT);
        return User.search(context, fields, "[('create_date','>=','" + startDateStr + "'),('create_date','<=','" + endDateStr + "'),('role','<=','" + role + "')]");
    }

    static __api__confirmPayment(staffId: number, requestId: number, account: any): ExecuteAPI {
        return new ExecuteAPI(User.Model, 'confirm_payment', { staffId: staffId, requestId: requestId, account: account }, null);
    }

    confirmPayment(context: APIContext, requestId: number, account: any): Observable<any> {
        return context.apiService.execute(User.__api__confirmPayment(this.id, requestId, account),
            context.authService.LoginToken);
    }

    static __api__searchByLogin(login: string): SearchCountAPI {
        return new SearchReadAPI(User.Model, ["login"], "[('login','=','" + login + "')]");
    }

    static searchByLogin(context: APIContext, login: string): Observable<any> {
        return User.single(context, ["login"], "[('login','=','" + login + "')]");
    }

    static __api__changePassword(userId: number, old_pass: string, new_pass: string): ExecuteAPI {
        return new ExecuteAPI(User.Model, 'change_password', { userId: userId, old_pass: old_pass, new_pass: new_pass }, null);
    }

    changePassword(context: APIContext, old_pass: string, new_pass: string): Observable<any> {
        return context.apiService.execute(User.__api__changePassword(this.id, old_pass, new_pass),
            context.authService.LoginToken);
    }

    static __api__register(user: User): ExecuteAPI {
        return new ExecuteAPI(User.Model, 'register', { user: MapUtils.serialize(user) }, null);
    }

    static register(context: APIContext, user: User): Observable<any> {
        return context.apiService.execute(User.__api__register(user),
            context.authService.LoginToken);
    }

    static __api__create(user: User): ExecuteAPI {
        return new ExecuteAPI(User.Model, 'create', { user: MapUtils.serialize(user) }, null);
    }

    static create(context: APIContext, user: User): Observable<any> {
        return context.apiService.execute(User.__api__create(user),
            context.authService.LoginToken);
    }

    static __api__listCourseMembers(userId: number, fields?: string[]): SearchReadAPI {
        return new SearchReadAPI(CourseMember.Model, fields, "[('user_id','='," + userId + ")]");
    }

    listCourseMembers(context: APIContext, fields?: string[]): Observable<any[]> {
        if (!this.id)
            return Observable.of([]);
        return CourseMember.search(context, fields, "[('user_id','='," + this.id + ")]");
    }

    static __api__listActivationCodes(userId: number, fields?: string[]): SearchReadAPI {
        return new SearchReadAPI(ActivationCode.Model, fields, "[('user_id','='," + userId + ")]");
    }

    listActivationCodes(context: APIContext, fields?: string[]): Observable<any[]> {
        if (!this.id)
            return Observable.of([]);
        return ActivationCode.search(context, fields, "[('user_id','='," + this.id + ")]");
    }

    static __api__listPaymentRequests(userId: number, fields?: string[]): SearchReadAPI {
        return new SearchReadAPI(PaymentRequest.Model, fields, "[('user_id','='," + userId + ")]");
    }

    listPaymentRequests(context: APIContext, fields?: string[]): Observable<any[]> {
        if (!this.id)
            return Observable.of([]);
        return PaymentRequest.search(context, fields, "[('user_id','='," + this.id + ")]");
    }

}
