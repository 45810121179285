import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { User } from '../../../shared/models/elearning/user.model';
import { BaseDialog } from '../../../shared/components/base/base.dialog';
import { CourseMember } from '../../../shared/models/elearning/course-member.model';
import * as _ from 'underscore';
import { PAYMENT_METHOD, PAYMENT_STATUS } from '../../../shared/models/constants'
import { PaymentRequest } from '../../../shared/models/elearning/payment-request.model';
import { SelectItem } from 'primeng/api';
import { ActivationCode } from '../../../shared/models/elearning/activation-code.model';
import { LearnerFormDialog } from '../../../account/user/learner-form/learner-form-dialog.component';
import { PaymentDetail } from '../../../shared/models/elearning/payment-detail.model';
import { Course } from '../../../shared/models/elearning/course.model';
import { SelectLearnersDialog } from '../../../shared/components/select-learner-dialog/select-learner-dialog.component';
import { MapUtils } from '../../../shared/helpers/map.utils';

@Component({
	
	selector: 'deposit-content-dialog',
	templateUrl: 'deposit-content-dialog.component.html',
})
export class DepositContentDialog extends BaseDialog<PaymentRequest> {

	PAYMENT_METHOD = PAYMENT_METHOD;
	PAYMENT_STATUS = PAYMENT_STATUS;

	@ViewChild(LearnerFormDialog,{static:true}) learnerDialog: LearnerFormDialog;
	@ViewChild(SelectLearnersDialog,{static:true}) selectLearnesDialog: SelectLearnersDialog;
	private learner: CourseMember;
	private productItems: SelectItem[];
	private productInfo: any;
	private selectedProduct;
	private learnerUser: User;
	private listCourseBuy: Course[];
	private listPaymentDetail: PaymentDetail[];
	private checkId: Boolean;
	constructor() {
		super();
		this.learner = new CourseMember();
	}


	ngOnInit() {
		this.learner = new CourseMember();
		this.onShow.subscribe((object: PaymentRequest) => {
			PaymentDetail.search(this, [], "[('request_id','='," + this.object.id + ")]").subscribe(details => {
				this.listCourseBuy = details;
			});
			PaymentDetail.searchPaymentDetailByPaymentRequest(this, this.object.id).subscribe(result => {
				this.listPaymentDetail = result;
				this.checkId = this.checkUserIdPaymentDetail();
				this.object.amount = this.totalPayment();
			});
		});

	}


	addNewLearner() {
		this.learnerUser = new User();
		this.learnerUser.role = 'learner';
		this.learnerUser.email = this.object.email;
		this.learnerUser.name = this.object.name;
		this.learnerUser.phone = this.object.phone;
		this.learnerDialog.show(this.learnerUser);
		this.learnerDialog.onCreateComplete.first().subscribe(user => {
			this.object.user_id = user.id;
			this.object.user_name = user.name;
		});
	}

	selectLearner() {
		this.selectLearnesDialog.show();
		this.selectLearnesDialog.onSelectUsers.first().subscribe(users => {
			this.object.name = users[0].name;
			this.object.email = users[0].email;
			this.object.phone = users[0].phone;
			this.object.user_id = users[0].id;
			this.object.user_name = users[0].name;
		});
	}

	selectSubscription() {
		if (this.selectedProduct) {
			this.object.product_id = this.selectedProduct.id;
			this.object.amount = this.selectedProduct.list_price;
		}
	}

	confirm() {
		var account = this.learnerUser ? { login: this.learnerUser.login, password: this.learnerUser["password"] } : {};
		if (this.checkId) {
			this.listPaymentDetail.forEach((detail: PaymentDetail) => {
				detail.user_id = this.object.user_id;
				detail.user_name = this.object.user_name;
				detail.save(this).subscribe();
			});
		}
		this.object.save(this).subscribe(() => {
			this.ContextUser.confirmPayment(this, this.object.id, account).subscribe(result => {
				this.object.status = 'proccessed';
				let actvCode: ActivationCode = MapUtils.deserialize(ActivationCode, result["code"]);
				var string = this.translateService.instant('Activation code') + ' ' + this.translateService.instant('has ben sent to user') + ' ' + this.object.user_name;
				this.success(string);
				this.hide();
			});
		});
	}

	checkUserIdPaymentDetail() {
		var check = false;
		this.listPaymentDetail.forEach(detail => {
			if (!detail.user_id) {
				check = true;
			}
		});
		return check;
	}

	totalPayment() {
		var total = 0;
		this.listPaymentDetail.forEach(detail => {
			if (detail.sale == 0) {
				total = total + detail.price;
			} else {
				total = total + detail.sale;
			}
		});
		return total;
	}
}
