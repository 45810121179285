import { BaseModel } from '../base.model';
import { Observable, Subject } from 'rxjs/Rx';
import { Model } from '../decorator';
import { APIContext } from '../context';
import { SearchReadAPI } from '../../services/api/search-read.api';

@Model('crm.lead')
export class Contact extends BaseModel{

    // Default constructor will be called by mapper
    constructor(){
        super();
		
			this.name = undefined;
			this.email_from = undefined;
    }
    
    name:number;
    email_from: string;


}
