import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { DatePipe } from '@angular/common';
import { BaseComponent } from '../../../../shared/components/base/base.component';
import { User } from '../../../../shared/models/elearning/user.model';
import { PaymentRequest } from '../../../../shared/models/elearning/payment-request.model';
import { Company } from '../../../../shared/models/elearning/company.model';
import * as _ from 'underscore';
import { TimeConvertPipe } from '../../../../shared/pipes/time.pipe';
import { ExcelService } from '../../../../shared/services/excel.service';
import { PaymentDetail } from '../../../../shared/models/elearning/payment-detail.model';
import { PAID_STATUS } from '../../../../shared/models/constants';
import { Course } from '../../../../shared/models/elearning/course.model';
import { ActivatedRoute } from '@angular/router';

const USER_FIELDS = ['name', 'referal_code'];
const PAYMENT_DETAIL = ['course_name'];
const COURSE_FIELD = ['name'];

@Component({
	
	selector: 'teacher-monthly-report',
	templateUrl: 'teacher-monthly-report.component.html',
	styleUrls: ['teacher-monthly-report.component.css'],
})
export class TeacherMonthlyReportComponent extends BaseComponent {

	private records: any;
	private totalComission: number;
	private totalSales: number;
	private company: Company;
	private listStaff: User[];
	private listIdRequest: number[];
	private listPaymentDetail: PaymentDetail[];
	private selectedStaff: any;
	private paid: number;
	private unpaid: number;
	private start: Date;
	private end: Date;
	private staff: User;
	private permission: string;
	PAID_STATUS = PAID_STATUS;

	constructor(private excelService: ExcelService, private datePipe: DatePipe,
		private timePipe: TimeConvertPipe, private route: ActivatedRoute) {
		super();
		this.records = [];
		this.listStaff = [];
		this.listPaymentDetail = [];
		this.totalComission = 0;
		this.totalSales = 0;
		this.unpaid = 0;
		this.paid = 0;
		this.company = new Company();
	}

	ngOnInit() {
		this.permission = this.route.snapshot.data['permission'];
	}

	export() {
		var output = _.map(this.records, record => {
			return {
				'Học viên': record['customer_name'],
				'Tên khóa học': record['course_name'],
				'Ngày thanh toán': record['payment_date'],
				'Giá': record['sales'],
				'Tỷ lệ hoa hồng': record['commission_rate'],
				'Tổng tiền nhận được': record['commission'],
				'Trạng thái': this.translateService.instant(PAID_STATUS[record['paid_status']])
			};
		});
		this.excelService.exportAsExcelFile(output, 'commission_report');
	}

	clear() {
		this.records = [];
	}

	render(start: Date, end: Date, user: User) {
		this.clear();
		this.start = start;
		this.end = end;
		this.staff = user;
		this.unpaid = 0;
		this.paid = 0;
		Company.main(this).subscribe(company => {
			this.company = company;
			this.generateReport(start, end, user.name);
		})

	}

	generateReport(start: Date, end: Date, name) {
		PaymentDetail.reportConfirmedPaymentByDateAndTeacher(this, start, end, name).subscribe((payments: any) => {
			this.records = _.map(payments, (payment: PaymentDetail) => {
				return this.generateReportRow(payment);
			});
			this.generateReportFooter(this.records);
		});
	}

	generateReportRow(payment: PaymentDetail): any {
		var record = {};
		record["payment_date"] = payment.create_date;
		record["customer_name"] = payment.user_name;
		Course.get(this, payment.course_id, COURSE_FIELD).subscribe(course => {
			record["course_name"] = course.name;
		});
		if (payment.sale != 0) {
			record["sales"] = payment.sale;
		} else {
			record["sales"] = payment.price;
		}
		if (payment.commission_teacher != "") {
			record["commission"] = JSON.parse(payment.commission_teacher).comisson;
			record["commission_rate"] = JSON.parse(payment.commission_teacher).comisson_rate;
			if (payment.pay_teacher) {
				this.paid = this.paid + JSON.parse(payment.commission_teacher).comisson;
			} else {
				this.unpaid = this.unpaid + JSON.parse(payment.commission_teacher).comisson;
			}
		} else {
			record["commission"] = 0;
			record["commission_rate"] = 0;
		}
		record["paid_status"] = payment.pay_teacher;
		record["id"] = payment.id;
		return record;
	}

	generateReportFooter(records: any) {
		this.totalSales = 0;
		_.each(this.records, record => {
			this.totalSales += record["commission"];
		})
	}

	paidStaff(selectedStaff) {
		PaymentDetail.get(this, selectedStaff.id).subscribe(request => {
			request.pay_teacher = true;
			request.save(this).subscribe(resp => {
				if (!resp["success"]) {
					this.error(this.translateService.instant(resp["message"]));
					return;
				}
				this.render(this.start, this.end, this.staff);
			});
		});

	}

	unPaidStaff(selectedStaff) {
		PaymentDetail.get(this, selectedStaff.id).subscribe(request => {
			request.pay_teacher = false;
			request.save(this).subscribe(resp => {
				if (!resp["success"]) {
					this.error(this.translateService.instant(resp["message"]));
					return;
				}
				this.render(this.start, this.end, this.staff);
			});
		});
	}
}
