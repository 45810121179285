import { NgModule } from '@angular/core';
import { AuthModule } from '../auth/auth.module';
import { ErpSharedModule } from '../shared/shared.module';
import { SalesComponent } from './sales.component';
import { ProductFormComponent } from './product/service-form/service-form.component';
import { ServiceListComponent } from './product/service-list/service-list.component';
import { ProductViewComponent } from './product/service-view/service-view.component';
import { ProductResolve } from './route.resolver';
import { ProductDialog } from './product/service-dialog/service-dialog.component';

@NgModule({
	imports: [
		ErpSharedModule,
		AuthModule
	],
	declarations: [
		SalesComponent,
		ProductDialog,
		ProductFormComponent,
		ProductViewComponent,
		ServiceListComponent
	],
	exports: [

	],
	providers: [ProductResolve]
})
export class SalesModule {
}
