import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { AuthService } from '../../../shared/services/auth.service';
import { Group } from '../../../shared/models/elearning/group.model';
import { BaseDialog } from '../../../shared/components/base/base.dialog';
import { User } from '../../../shared/models/elearning/user.model';
import * as _ from 'underscore';
import { LearnerContentComponent } from './learner-content.component';

const GROUP_FIELDS = ['name', 'category', 'parent_id', 'child_ids'];
const USER_FIELDS = ['email', 'role'];

@Component({
	
	selector: 'learner-form-dialog',
	templateUrl: 'learner-form-dialog.component.html',
})
export class LearnerFormDialog extends BaseDialog<User> {

	@ViewChild(LearnerContentComponent ,{static:true}) formContent: LearnerContentComponent;


	constructor(private router: Router, private route: ActivatedRoute) {
		super();
	}


	ngOnInit() {
		this.onShow.subscribe(object => {
			this.formContent.render(object);
		});
	}


	save() {
		if (this.object.IsNew) {
			this.object.role = "learner";

			User.all(this, USER_FIELDS).subscribe(users => {
				users = _.filter(users, (user: User) => {
					return user.role == this.object.role && user.email == this.object.email;
				});
				if (users.length != 0) {
					this.error(this.translateService.instant("Email has registered. Please choose another email."));
				} else {
					User.register(this, this.object).subscribe(resp => {
						if (!resp["success"]) {
							this.error(this.translateService.instant(resp["message"]));
							return;
						}
						this.object.id = +resp["user_id"];
						this.onCreateCompleteReceiver.next(this.object);
						this.hide();
					});
				}
			});

		}
		else {
			this.object.save(this).subscribe(() => {
				this.onUpdateCompleteReceiver.next(this.object);
				this.hide();
			});
		}
	}

}

