import { BaseModel } from '../base.model';
import { Observable, Subject } from 'rxjs/Rx';
import { Model, ReadOnlyProperty, UnserializeProperty } from '../decorator';
import { APIContext } from '../context';
import { SearchReadAPI } from '../../services/api/search-read.api';
import { ExecuteAPI } from '../../services/api/execute.api';
import * as moment from 'moment';
import { SERVER_DATETIME_FORMAT } from '../constants';
import * as _ from 'underscore';
import { CourseMember } from './course-member.model';
import { ListAPI } from '../../services/api/list.api';
import { CourseUnit } from './course-unit.model';
import { CourseSyllabus } from './course-syllabus.model';
import { CourseFaq } from './course-faq.model';
import { CourseMaterial } from './course-material.model';

@Model('opencourse.course')
export class Course extends BaseModel {

    // Default constructor will be called by mapper
    constructor() {
        super();

        this.name = undefined;
        this.metadata = undefined;
        this.summary = undefined;
        this.description = undefined;
        this.code = undefined;
        this.status = undefined;
        this.logo_url = undefined;
        this.logo_id = undefined;
        this.group_id = undefined;
        this.syllabus_id = undefined;
        this.group_name = undefined;
        this.supervisor_id = undefined;
        this.supervisor_name = undefined;
        this.prequisite_course_id = undefined;
        this.prequisite_course_name = undefined;
        this.prequisite_course_name = undefined;
        this.complete_unit_by_order = undefined;
        this.syllabus_id = undefined;
        this.unit_count = undefined;
        this.syllabus_status = undefined;
        this.syl = new CourseSyllabus();
        this.teacher = undefined;
        this.length = undefined;
        this.view_count = undefined;
        this.mission = undefined;
        this.audience = undefined;
        this.level = undefined;
        this.intro_video_url = undefined;
        this.intro_video_id = undefined;
        this.price = undefined;
        this.sale = undefined;
        this.title_tag = undefined;
        this.editor_id = undefined;
    }

    complete_unit_by_order: boolean;
    syllabus_id: number;
    intro_video_id: number;
    metadata: string;
    unit_count: number;
    syllabus_status: string;
    prequisite_course_id: number;
    prequisite_course_name: string;
    name: string;
    group_id: number;
    supervisor_id: number;
    supervisor_name: string;
    group_name: string;
    summary: string;
    code: string;
    description: string;
    status: string;
    logo_url: string;
    logo_id: number;
    @UnserializeProperty()
    syl: CourseSyllabus;
    teacher: string;
    length: number;
    view_count: number;
    mission: string;
    audience: string;
    intro_video_url: string;
    level: string;
    price: number;
    sale: number;
    title_tag: string;
    editor_id: number;


    get IsAvailable(): boolean {
        if (this.status != 'open')
            return false;
        return true;
    }


    static __api__enroll(courseId: number, userIds: number[]): ExecuteAPI {
        return new ExecuteAPI(Course.Model, 'enroll', { courseId: courseId, userIds: userIds }, null);
    }

    enroll(context: APIContext, userIds: number[]): Observable<any> {
        return context.apiService.execute(Course.__api__enroll(this.id, userIds),
            context.authService.LoginToken);
    }

    static __api__enroll_staff(courseId: number, userIds: number[]): ExecuteAPI {
        return new ExecuteAPI(Course.Model, 'enroll_staff', { courseId: courseId, userIds: userIds }, null);
    }

    enrollStaff(context: APIContext, userIds: number[]): Observable<any> {
        return context.apiService.execute(Course.__api__enroll_staff(this.id, userIds),
            context.authService.LoginToken);

    }

    static __api__open(courseId: number): ExecuteAPI {
        return new ExecuteAPI(Course.Model, 'open', { courseId: courseId }, null);
    }

    open(context: APIContext): Observable<any> {
        return context.apiService.execute(Course.__api__open(this.id),
            context.authService.LoginToken);
    }

    static __api__close(courseId: number): ExecuteAPI {
        return new ExecuteAPI(Course.Model, 'close', { courseId: courseId }, null);
    }

    close(context: APIContext): Observable<any> {
        return context.apiService.execute(Course.__api__close(this.id),
            context.authService.LoginToken);
    }

    static __api__listMembers(courseId: number, fields?: string[]): SearchReadAPI {
        return new SearchReadAPI(CourseMember.Model, fields, "[('course_id','='," + courseId + ")]");
    }

    listMembers(context: APIContext, fields?: string[]): Observable<any[]> {
        return CourseMember.search(context, fields, "[('course_id','='," + this.id + ")]");
    }


    static __api__populateSyllabus(syllabus_id: number): ListAPI {
        return new ListAPI(CourseSyllabus.Model, [syllabus_id], []);
    }

    populateSyllabus(context: APIContext): Observable<any> {
        if (!this.syllabus_id)
            return Observable.of(null);
        return CourseSyllabus.get(context, this.syllabus_id).do(syl => {
            this.syl = syl;
        });
    }

    static __api__courseEditor(course_id: number): SearchReadAPI {
        return new SearchReadAPI(CourseMember.Model, [], "[('role','=','editor'),('course_id','='," + course_id + ")]");
    }

    courseEditor(context: APIContext): Observable<any> {
        return CourseMember.single(context, [], "[('role','=','editor'),('course_id','='," + this.id + ")]");
    }

    static __api__courseTeacher(course_id: number): SearchReadAPI {
        return new SearchReadAPI(CourseMember.Model, [], "[('role','=','teacher'),('course_id','='," + course_id + ")]");
    }

    courseTeacher(context: APIContext): Observable<any> {
        return CourseMember.single(context, [], "[('role','=','teacher'),('course_id','='," + this.id + ")]");
    }

    static __api__searchAvailableByDate(role: string, start: Date, end: Date, fields?: string[]): SearchReadAPI {
        var startDateStr = moment(start).format(SERVER_DATETIME_FORMAT);
        var endDateStr = moment(end).format(SERVER_DATETIME_FORMAT);
        return new SearchReadAPI(Course.Model, fields, "[('create_date','>=','" + startDateStr + "'),('create_date','<=','" + endDateStr + "'),('status','=','published')]");
    }

    static searchAvailableByDate(context: APIContext, role: string, start: Date, end: Date, fields?: string[]): Observable<any> {
        var startDateStr = moment(start).format(SERVER_DATETIME_FORMAT);
        var endDateStr = moment(end).format(SERVER_DATETIME_FORMAT);
        return Course.search(context, fields, "[('create_date','>=','" + startDateStr + "'),('create_date','<=','" + endDateStr + "'),('status','=','published')]");
    }

    static __api__listFaqs(courseId: number, fields?: string[]): SearchReadAPI {
        return new SearchReadAPI(CourseFaq.Model, fields, "[('course_id','='," + courseId + ")]");
    }

    listFaqs(context: APIContext, fields?: string[]): Observable<any[]> {
        if (!this.id)
            return Observable.of([]);
        return CourseFaq.search(context, fields, "[('course_id','='," + this.id + ")]");
    }

    static __api__listMaterials(courseId: number, fields?: string[]): SearchReadAPI {
        return new SearchReadAPI(CourseMaterial.Model, fields, "[('course_id','='," + courseId + ")]");
    }

    listMaterials(context: APIContext, fields?: string[]): Observable<any[]> {
        if (!this.id)
            return Observable.of([]);
        return CourseMaterial.search(context, fields, "[('course_id','='," + this.id + ")]");
    }

    static __api__listUnits(courseId: number, fields?: string[]): SearchReadAPI {
        return new SearchReadAPI(CourseUnit.Model, fields, "[('course_id','='," + courseId + ")]");
    }

    listUnits(context: APIContext, fields?: string[]): Observable<any[]> {
        if (!this.id)
            return Observable.of([]);
        return CourseUnit.search(context, fields, "[('course_id','='," + this.id + ")]");
    }

}
