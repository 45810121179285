import { Component, OnInit, Input } from '@angular/core';
import { BaseDialog } from '../../../../shared/components/base/base.dialog';
import { PageSection, WebPage } from '../../../../shared/models/elearning/web-cms.model';
import * as _ from 'underscore';
import { SelectItem } from 'primeng/api';

@Component({
	
	selector: 'page-section-dialog',
	templateUrl: 'section-dialog.component.html',
	styleUrls: ['section-dialog.component.css'],
})
export class PageSectionDialog extends BaseDialog<PageSection>  {

	constructor() {
		super();
	}


}


