import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { BaseDialog } from '../../../shared/components/base/base.dialog';
import { AuthService } from '../../../shared/services/auth.service';
import * as _ from 'underscore';
import { USER_STATUS, GROUP_CATEGORY, COURSE_MODE, COURSE_STATUS, PAYMENT_METHOD, PAYMENT_STATUS } from '../../../shared/models/constants'
import { PaymentRequest } from '../../../shared/models/elearning/payment-request.model';
import { LearningPath } from '../../../shared/models/elearning/learning-path.model';
import { TreeUtils } from '../../../shared/helpers/tree.utils';
import { TreeNode } from 'primeng/api';
import { BaseModel } from '../../../shared/models/base.model';
import { User } from '../../../shared/models/elearning/user.model';

@Component({
    
    selector: 'payment-history-dialog',
    templateUrl: 'payment-history-dialog.component.html',
})
export class PaymentHistoryDialog extends BaseDialog<User> {

    PAYMENT_METHOD = PAYMENT_METHOD;
    PAYMENT_STATUS = PAYMENT_STATUS;

    private requests: PaymentRequest[];

    constructor() {
        super();
    }

    ngOnInit() {
        this.onShow.subscribe((object:User)=> {
            object.listPaymentRequests(this).subscribe(requests=> {
                this.requests = requests;
            });
        });
    }


}

