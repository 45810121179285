import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { AuthService } from '../../shared/services/auth.service';
import { Group } from '../../shared/models/elearning/group.model';
import { Course } from '../../shared/models/elearning/course.model';
import { CourseMember } from '../../shared/models/elearning/course-member.model';
import { BaseDialog } from '../../shared/components/base/base.dialog';
import { Company } from '../../shared/models/elearning/company.model';
import * as _ from 'underscore';
import { TreeUtils } from '../../shared/helpers/tree.utils';
import { TreeNode } from 'primeng/api';
import { BaseModel } from '../../shared/models/base.model';

@Component({
    
    selector: 'company-setting-dialog',
    templateUrl: 'company-setting-dialog.component.html',
    styleUrls: ['company-setting-dialog.component.css'],
})
export class ComapnySettingDialog extends BaseDialog<Company> {


    constructor() {
        super();
    }



}
