import { NgModule } from '@angular/core';
import { Routes } from '@angular/router';
import { CourseComponent } from './course.component'
import { CourseListComponent } from './course/course-list/course-list.component';
import { GroupListComponent } from '../shared/components/group-list/group-list.component';
import { AdminGuard } from '../shared/guards/admin.guard';
import { RouterModule } from '@angular/router';
import { LearningPathListComponent } from './learning-path/learningpath-list/learningpath-list.component';
import { CourseEnrollmentListComponent } from './enrollment/course-list/course-list.component';
import { CourseFormComponent } from './course/course-form/course-form.component';
import { CourseViewComponent } from './course/course-view/course-view.component';
import { LearningPathFormComponent } from './learning-path/learningpath-form/learningpath-form.component';
import { LearningPathViewComponent } from './learning-path/learningpath-view/learningpath-view.component';
import { CourseResolve, LearningPathResolve } from './route.resolver';
import { CourseEnrollmentFormComponent } from './enrollment/enrollment-form/course-enrollment-form.component';


export const CourseRoutes: Routes = [
  {
    path: 'course',
    component: CourseComponent,
    data: {
      breadcrumb: 'Course'
    },
    canActivate: [AdminGuard],
    children:
      [
        {
          path: "list",
          component: CourseListComponent,
          data: {
            breadcrumb: 'Courses'
          }
        },
        {
          path: "form",
          component: CourseFormComponent,
          data: {
            breadcrumb: 'Course form'
          },
          resolve: {
            course: CourseResolve,
          },
        },
        {
          path: "form/:courseId",
          component: CourseFormComponent,
          data: {
            breadcrumb: 'Course form'
          },
          resolve: {
            course: CourseResolve,
          },
        },
        {
          path: "view/:courseId",
          component: CourseViewComponent,
          data: {
            breadcrumb: 'Course view'
          },
          resolve: {
            course: CourseResolve,
          },
        },
        {
          path: "enrollments",
          component: CourseEnrollmentListComponent,
          data: {
            breadcrumb: 'Enrollment'
          }
        },
        {
          path: "enroll/:courseId",
          component: CourseEnrollmentFormComponent,
          data: {
            breadcrumb: 'Course enrollment'
          },
          resolve: {
            course: CourseResolve,
          },
        },
        {
          path: "groups",
          component: GroupListComponent,
          data: {
            breadcrumb: 'Groups',
            category: 'course'
          },
        }
      ]
  },
  {
    path: 'path',
    component: CourseComponent,
    data: {
      breadcrumb: 'Learning Path'
    },
    canActivate: [AdminGuard],
    children:
      [
        {
          path: "list",
          component: LearningPathListComponent,
          data: {
            breadcrumb: 'Paths'
          }
        },
        {
          path: "form",
          component: LearningPathFormComponent,
          data: {
            breadcrumb: 'Path form'
          },
          resolve: {
            path: LearningPathResolve,
          },
        },
        {
          path: "form/:pathId",
          component: LearningPathFormComponent,
          data: {
            breadcrumb: 'Path form'
          },
          resolve: {
            course: LearningPathResolve,
          },
        },
        {
          path: "view/:pathId",
          component: LearningPathViewComponent,
          data: {
            breadcrumb: 'Path view'
          },
          resolve: {
            course: LearningPathResolve,
          },
        },
      ]
  }

]

@NgModule({
  imports: [RouterModule.forChild(CourseRoutes)],
  exports: [RouterModule]
})
export class CourseRoutingModule { }