import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { AuthService } from '../../../shared/services/auth.service';
import { Group } from '../../../shared/models/elearning/group.model';
import { BaseComponent } from '../../../shared/components/base/base.component';
import { User } from '../../../shared/models/elearning/user.model';
import {  GENDER, COURSE_MEMBER_ROLE, COURSE_MEMBER_ENROLL_STATUS } from '../../../shared/models/constants';
import { BaseModel } from '../../../shared/models/base.model';
import { Course } from '../../../shared/models/elearning/course.model';
import { CourseMember } from '../../../shared/models/elearning/course-member.model';
import * as _ from 'underscore';


@Component({
	
	selector: 'teacher-view-content',
	templateUrl: 'teacher-content.component.html',
	styleUrls: ['teacher-content.component.css'],
})
export class TeacherViewContentComponent extends BaseComponent {

	GENDER = GENDER;
	COURSE_MEMBER_ENROLL_STATUS = COURSE_MEMBER_ENROLL_STATUS;
	

	private user: User;


	constructor(private router: Router, private route: ActivatedRoute) {
		super();
		this.user = new User();
	}

	render(user:User) {
		this.user = user;

	}

	

}

