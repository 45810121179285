import { NgModule } from '@angular/core';
import { AuthModule } from '../auth/auth.module';
import { ErpSharedModule } from '../shared/shared.module';
import { LMSModule } from '../lms/lms.module';
import { UserListComponent } from './user/user-list/user-list.component';
import { LearnerFormDialog } from './user/learner-form/learner-form-dialog.component';
import { LearnerContentComponent } from './user/learner-form/learner-content.component';
import { LearnerFormComponent } from './user/learner-form/learner-form.component';
import { StaffFormDialog } from './user/staff-form/staff-form-dialog.component';
import { StaffContentComponent } from './user/staff-form/staff-content.component';
import { StaffFormComponent } from './user/staff-form/staff-form.component';
import { TeacherFormDialog } from './user/teacher-form/teacher-form-dialog.component';
import { TeacherContentComponent } from './user/teacher-form/teacher-content.component';
import { TeacherFormComponent } from './user/teacher-form/teacher-form.component';
import { LearnerViewDialog } from './user/learner-view/learner-view.dialog.component';
import { LearnerViewContentComponent } from './user/learner-view/learner-content.component';
import { LearnerViewComponent } from './user/learner-view/learner-view.component';
import { StaffViewDialog } from './user/staff-view/staff-view.dialog.component';
import { StaffViewContentComponent } from './user/staff-view/staff-content.component';
import { StaffViewComponent } from './user/staff-view/staff-view.component';
import { TeacherViewDialog } from './user/teacher-view/teacher-view.dialog.component';
import { TeacherViewContentComponent } from './user/teacher-view/teacher-content.component';
import { TeacherViewComponent } from './user/teacher-view/teacher-view.component';
import { UserExportDialog } from './user/export-dialog/export-dialog.component';
import { UserImportComponent } from './user/user-import/user-import.component';
import { CalendarModule } from 'primeng/calendar';
import { RadioButtonModule } from 'primeng/radiobutton';
import { AccountRoutingModule } from './account-routing';
import { ChangePasswordDialog } from './user/change-password/change-password-dialog.component';
import { PermissionFormComponent } from './permission/permission-form/permission-form.component';
import { PermissionViewComponent } from './permission/permission-view/permission-view.component';
import { PermissionListComponent } from './permission/permission-list/permission-list.component';
import { UserResolve, GroupsResolve, DateFormatResolve, PermissionResolve } from './route.resolver';


@NgModule({
    imports: [
        AccountRoutingModule,
        ErpSharedModule,
        AuthModule,
        LMSModule
    ],
    declarations: [
        UserListComponent,
        LearnerContentComponent,
        LearnerFormComponent,
        LearnerFormDialog,
        UserExportDialog,
        UserImportComponent,
        StaffFormDialog,
        StaffContentComponent,
        StaffFormComponent,
        TeacherFormDialog,
        TeacherFormComponent,
        TeacherContentComponent,
        LearnerViewDialog,
        LearnerViewContentComponent,
        LearnerViewComponent,
        StaffViewDialog,
        StaffViewContentComponent,
        StaffViewComponent,
        TeacherViewDialog,
        TeacherViewContentComponent,
        TeacherViewComponent,
        PermissionListComponent,
        PermissionFormComponent,
        PermissionViewComponent,
        ChangePasswordDialog,
    ],
    exports: [
        StaffFormDialog,
        LearnerFormDialog,
        StaffViewDialog,
        LearnerViewDialog,
        ChangePasswordDialog
    ],
    providers: [UserResolve, GroupsResolve, DateFormatResolve, PermissionResolve]
})
export class AccountModule {
}
