import { NgModule } from '@angular/core';
import { Routes } from '@angular/router';
import { GroupListComponent } from '../shared/components/group-list/group-list.component';
import { AdminGuard } from '../shared/guards/admin.guard';
import { RouterModule } from '@angular/router';
import { CRMComponent } from './crm.component';
import { CustomerListComponent } from './customer/customer-list/customer-list.component';
import { PaymentRequestListComponent } from './recharge/payment-request-list/payment-request-list.component';
import { MailingListComponent } from './customer/mailing-list/mailing-list.component';

export const CRMRoutes: Routes = [
    {
       path: 'crm',
       component: CRMComponent,
       data: {
      breadcrumb: 'CRM'
    },
    canActivate: [AdminGuard],
       children:
       [
           
            {
               path: "recharges",
               component: PaymentRequestListComponent,
               data: {
                  breadcrumb: 'Top-up'
                }
            },
            {
               path: "learners",
               component: CustomerListComponent,
               data: {
                  breadcrumb: 'Learners'
                }
            },
            {
               path: "mail-list",
               component: MailingListComponent,
               data: {
                  breadcrumb: 'Mailing list'
                }
            },
       ]
    }

]

@NgModule({
  imports: [RouterModule.forChild(CRMRoutes)],
  exports: [RouterModule]
})
export class CRMRoutingModule {}