import { NgModule } from '@angular/core';
import { Routes } from '@angular/router';
import { AnalysisComponent } from './analysis.component';
import { ReportComponent } from './report/report.component';
import { ChartComponent } from './chart/chart.component';
import { AdminGuard } from '../shared/guards/admin.guard';
import { RouterModule } from '@angular/router';
import { StaffMonthlyReportContainerComponent } from './report/finance/staff-monthly-report/staff-monthly-report-container.component';
import { TeacherMonthlyReportContainerComponent } from './report/finance/teacher-monthly-report/teacher-monthly-report-container.component';


export const AnalysisRoutes: Routes = [
  {
    path: 'analysis',
    component: AnalysisComponent,
    data: {
      breadcrumb: 'Analysis'
    },
    canActivate: [AdminGuard],
    children:
      [
        {
          path: "reports",
          component: ReportComponent,
          data: {
            breadcrumb: 'Report'
          }
        },
        {
          path: "charts",
          component: ChartComponent,
          data: {
            breadcrumb: 'Charts'
          }
        },
      ]
  }, {
    path: 'report',
    component: AnalysisComponent,
    data: {
      breadcrumb: 'Analysis'
    },
    children: [
      {
        path: "staff",
        component: StaffMonthlyReportContainerComponent,
        data: {
          breadcrumb: 'Report',
          permission: 'Staff'
        },
      },
      {
        path: "teacher",
        component: TeacherMonthlyReportContainerComponent,
        data: {
          breadcrumb: 'Report',
          permission: 'Teacher'
        },
      },
    ]
  }

]

@NgModule({
  imports: [RouterModule.forChild(AnalysisRoutes)],
  exports: [RouterModule]
})
export class AnalysisRoutingModule { }