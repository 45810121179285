import { Component, ElementRef, Input, Output, EventEmitter } from '@angular/core';
import { APIService } from '../../services/api.service';
import { AuthService } from '../../services/auth.service';
import { MessageService } from 'primeng/components/common/messageservice';
import { APIContext } from '../../models/context';
import { ServiceLocator } from "../../../service.locator";
import { ConfirmationService } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';
import { SettingService } from '../../services/setting.service';
import { Observable, Subject, Subscription } from 'rxjs/Rx';
import { User } from '../../models/elearning/user.model';
import { Permission } from '../../models/elearning/permission.model';
import { LMSProfileService } from '../../services/lms-profile.service';

export abstract class BaseComponent implements APIContext {
	apiService: APIService;
	authService: AuthService;
	messageService: MessageService;
	confirmationService: ConfirmationService;
	translateService: TranslateService;
	settingService: SettingService;
	lmsProfileService: LMSProfileService;

	loading: boolean;

	constructor() {
		this.apiService = ServiceLocator.injector.get(APIService);
		this.authService = ServiceLocator.injector.get(AuthService);
		this.messageService = ServiceLocator.injector.get(MessageService);
		this.confirmationService = ServiceLocator.injector.get(ConfirmationService);
		this.translateService = ServiceLocator.injector.get(TranslateService);
		this.settingService = ServiceLocator.injector.get(SettingService);
		this.lmsProfileService = ServiceLocator.injector.get(LMSProfileService);

		this.apiService.onStartHTTP.subscribe(()=> {
			this.loading =  true;
		});
		this.apiService.onFinishHTTP.subscribe(()=> {
			this.loading =  false;
		});
	}

	get ContextUser():User {
		return this.authService.UserProfile;
	}

	get ContextPermission(): Permission {
		return this.authService.UserPermission;
	}

	error(msg:string) {
		this.messageService.add({ severity: 'error', summary: this.translateService.instant('Error'), detail: this.translateService.instant(msg) });
	}

	info(msg:string) {
		this.messageService.add({ severity: 'info', summary: this.translateService.instant('Info'), detail: this.translateService.instant(msg) });
	}

	success(msg:string) {
		this.messageService.add({ severity: 'success', summary: this.translateService.instant('Success'), detail: this.translateService.instant(msg) });
	}

	warn(msg:string) {
		this.messageService.add({ severity: 'warn', summary: this.translateService.instant('Warn'), detail: this.translateService.instant(msg) });
	}

	confirm(prompt:string, callback:()=> any) {
		this.confirmationService.confirm({
            message: this.translateService.instant(prompt),
            accept: () => {
                callback();
            }
        });
	}

	resizeImage(file:File, maxWidth:number, maxHeight:number):Promise<Blob> {
		return new Promise((resolve, reject) => {
			let image = new Image();
			image.src = URL.createObjectURL(file);
			image.onload = () => {
				let width = image.width;
				let height = image.height;
				
				if (width <= maxWidth && height <= maxHeight) {
					resolve(file);
				}
	
				let newWidth;
				let newHeight;
	
				if (width > height) {
					newHeight = height * (maxWidth / width);
					newWidth = maxWidth;
				} else {
					newWidth = width * (maxHeight / height);
					newHeight = maxHeight;
				}
	
				let canvas = document.createElement('canvas');
				canvas.width = newWidth;
				canvas.height = newHeight;
	
				let context = canvas.getContext('2d');
	
				context.drawImage(image, 0, 0, newWidth, newHeight);
	
				canvas.toBlob(resolve, file.type);
			};
			image.onerror = reject;
		});
	}

	blobToFile(blob: Blob, fileName:string): File{
		var b: any = blob;
		b.lastModifiedDate = new Date();
		b.name = fileName;
		return <File>blob;
	}

	// ConvertTo16bpp(img: Image) {
	// 	var bmp = new Bitmap(img.Width, img.Height,
	// 				  System.Drawing.Imaging.PixelFormat.Format16bppRgb555);
	// 	using (var gr = Graphics.FromImage(bmp))
	// 		gr.DrawImage(img, new Rectangle(0, 0, img.Width, img.Height));
	// 	return bmp;
	// }
	

}
