import { Component, OnInit, Input, ViewChild, NgZone } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { AuthService } from '../../../shared/services/auth.service';
import { Group } from '../../../shared/models/elearning/group.model';
import { User } from '../../../shared/models/elearning/user.model';
import { BaseDialog } from '../../../shared/components/base/base.dialog';
import { Course } from '../../../shared/models/elearning/course.model';
import { LearningPath } from '../../../shared/models/elearning/learning-path.model';
import { LearningPathItem } from '../../../shared/models/elearning/learning-path-item.model';
import { CourseMember } from '../../../shared/models/elearning/course-member.model';
import * as _ from 'underscore';
import { TreeUtils } from '../../../shared/helpers/tree.utils';
import { TreeNode } from 'primeng/api';
import { SelectItem, MenuItem } from 'primeng/api';
import { GROUP_CATEGORY, COURSE_STATUS, COURSE_MEMBER_ROLE, COURSE_MEMBER_STATUS, COURSE_MEMBER_ENROLL_STATUS } from '../../../shared/models/constants'
import { WindowRef } from '../../../shared/helpers/windonw.ref';
import { SelectCoursesDialog } from '../../../shared/components/select-course-dialog/select-course-dialog.component';
import { Router, ActivatedRoute, Params } from '@angular/router';

declare var $: any;

@Component({
	
	selector: 'learningpath-form',
	templateUrl: 'learningpath-form.component.html',
})
export class LearningPathFormComponent extends BaseDialog<LearningPath> implements OnInit {

	COURSE_STATUS = COURSE_STATUS;

	private items: LearningPathItem[];
	private selectedItem: any;
	private percentage: number;
	private path: LearningPath;

	@ViewChild(SelectCoursesDialog,{static:true}) courseDialog: SelectCoursesDialog;

	constructor(private ngZone: NgZone, private route: ActivatedRoute, private router: Router) {
		super();
		this.path = new LearningPath();
	}


	ngOnInit() {
		this.path = this.route.snapshot.data['path'];
		if (!this.path.IsNew)
			this.path.listItems(this).subscribe(items=> {
				this.items = items;
			});
	}

	addCourse() {
		this.courseDialog.show();
		this.courseDialog.onSelectCourses.first().subscribe((course: Course) => {
			var existItem = _.find(this.items, (item: LearningPathItem) => {
				return item.course_id == course.id;
			});
			if (existItem) {
				existItem["markedAsDeleted"] = false;
				return;
			}
			course.populate(this).subscribe(() => {
				let item: LearningPathItem = new LearningPathItem();
				item.course_id = course.id;
				item.course_name = course.name;
				item.group_name = course.group_name;
				item.course_status = course.status;
				item.course_teacher = course.teacher;
				this.items.push(item);
				this.reIndexPathItem();
			})

		});
	}

	deleteCourse(item: LearningPathItem) {
		item["markedAsDeleted"] = true;
	}

	reIndexPathItem() {
		for (var i = 0; i < this.items.length; i++)
			this.items[i].order = i + 1;
		this.items = _.sortBy(this.items, (item: LearningPathItem) => {
			return item.order;
		});
	}

	moveUp(item: LearningPathItem) {
		this.reIndexPathItem();
		if (item.order > 1) {
			for (var i = 0; i < this.items.length; i++) {
				if (this.items[i].order == item.order && i > 0) {
					var tmp = this.items[i - 1];
					this.items[i - 1] = this.items[i];
					this.items[i] = tmp;
					break;
				}
			}
			this.reIndexPathItem();
		}
	}

	moveDown(item: LearningPathItem) {
		this.reIndexPathItem();
		if (item.order < this.items.length) {
			for (var i = 0; i < this.items.length; i++) {
				if (this.items[i].order == item.order && i + 1 < this.items.length) {
					var tmp = this.items[i];
					this.items[i] = this.items[i + 1];
					this.items[i + 1] = tmp;
					break;
				}
			}
			this.reIndexPathItem();
		}
	}

	save() {
		var isNew = this.path.IsNew;
		this.path.save(this).subscribe(() => {
			_.each(this.items, (item: LearningPathItem) => {
				item.path_id = this.path.id;
			});
			var existItems = _.filter(this.items, (item: LearningPathItem) => {
				return !item.IsNew && !item["markedAsDeleted"];
			});
			var newItems = _.filter(this.items, (item: LearningPathItem) => {
				return item.IsNew && !item["markedAsDeleted"];
			});
			var deleteItems = _.filter(this.items, (item: LearningPathItem) => {
				return !item.IsNew && item["markedAsDeleted"];
			});
			Observable.forkJoin(
				LearningPathItem.createArray(this, newItems),
				LearningPathItem.deleteArray(this, deleteItems))
				.subscribe(() => {
					if (isNew) {
						this.success(this.translateService.instant('Learning path created successfully.'));
					} else {
						this.success(this.translateService.instant('Learning path created successfully.'));
					}
				});
		});
	}

	uploadImageFile(file) {
		//this.apiService.upload_S3(file, this.authService.LoginToken).subscribe(
		this.apiService.upload(file, this.authService.LoginToken).subscribe(
			data => {
				if (data["result"]) {
					this.ngZone.run(() => {
						this.path.logo_url = data["url"];
						this.path.logo_id = data["attachment_id"];
					});
				}
			}
		);
	}

	changeImageFile(event: any) {
		let file = event.files[0];
		this.uploadImageFile(file);
	}

	cancel() {
		this.router.navigate(['/path/list']);
	}

}
