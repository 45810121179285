import { Component, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { BaseComponent } from '../../../shared/components/base/base.component';
import * as _ from 'underscore';
import { WebPage } from '../../../shared/models/elearning/web-cms.model';
import { SelectItem } from 'primeng/api';

@Component({
    
    selector: 'page-list',
    templateUrl: 'page-list.component.html',
    styleUrls: ['page-list.component.css'],
    encapsulation: ViewEncapsulation.None
})
export class PageSeoListComponent extends BaseComponent {

    private pages: SelectItem[];
    private selectedPage: any;
    private tags: any;
    private selectedTag: any;

    constructor() {
        super();
    }

    ngOnInit() {
        WebPage.all(this).subscribe(pages => {
            pages = _.sortBy(pages, (page: WebPage) => {
                return page.order;
            });
            this.pages = _.map(pages, (page: WebPage) => {
                return {
                    label: page.name,
                    value: page
                }
            });
            if (this.pages.length) {
                this.selectedPage = pages[0];
                this.selectPage(pages[0])
            }
        });
    }

    selectPage(page: WebPage) {
        if (page.metadata) {
            this.tags = JSON.parse(page.metadata);
        } else {
            this.tags = [];
        }
    }

    addTag() {
        var tag = {};
        this.tags.push(tag);
    }

    savePage(page: WebPage) {
        this.tags = _.reject(this.tags, tag=> {
            return !tag["name"];
        });
        page.metadata = JSON.stringify(this.tags);
        page.save(this).subscribe(() => {
            this.success("SEO data saved successfully");
        });
    }

}
