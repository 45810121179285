import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { DatePipe } from '@angular/common';
import { BaseComponent } from '../../../../shared/components/base/base.component';
import { User } from '../../../../shared/models/elearning/user.model';
import { PaymentRequest } from '../../../../shared/models/elearning/payment-request.model';
import { Company } from '../../../../shared/models/elearning/company.model';
import * as _ from 'underscore';
import { TimeConvertPipe } from '../../../../shared/pipes/time.pipe';
import { ExcelService } from '../../../../shared/services/excel.service';
import { PaymentDetail } from '../../../../shared/models/elearning/payment-detail.model';

const USER_FIELDS = ['name', 'referal_code'];
const PAYMENT_DETAIL = ['course_name'];

@Component({
	
	selector: 'sales-monthly-commission-report',
	templateUrl: 'sales-monthly-commission-report.component.html',
	styleUrls: ['sales-monthly-commission-report.component.css'],
})
export class SalesMonthlyComissionReportComponent extends BaseComponent {

	private records: any;
	private totalComission: number;
	private totalSales: number;
	private company: Company;
	private listStaff: User[];
	private listIdRequest: number[];
	private listPaymentDetail: PaymentDetail[];

	constructor(private excelService: ExcelService, private datePipe: DatePipe, private timePipe: TimeConvertPipe) {
		super();
		this.records = [];
		this.listStaff = [];
		this.listPaymentDetail = [];
		this.totalComission = 0;
		this.totalSales = 0;
		this.company = new Company();
	}

	ngOnInit() {
		User.listAllStaff(this, USER_FIELDS).subscribe(users => {
			this.listStaff = users;
		});
	}

	export() {
		var output = _.map(this.records, record => {
			return {
				'Payment date': record['payment_date'],
				'Customer name': record['customer_name'],
				'Email': record['customer_email'],
				'Mobile': record['customer_mobile'],
				'Salesperson': record['referal_name'],
				'Month': record['month'],
				'Sales': record['sales'],
				'Commission': record['commission']
			};
		});
		this.excelService.exportAsExcelFile(output, 'commission_report');
	}

	clear() {
		this.records = [];
	}

	render(start: Date, end: Date) {
		this.clear();
		Company.main(this).subscribe(company => {
			this.company = company;
			this.generateReport(start, end);
		})

	}

	generateReport(start: Date, end: Date) {
		PaymentRequest.reportConfirmedPaymentByDate(this, start, end).subscribe((payments: any) => {
			this.records = _.map(payments, (payment: PaymentRequest) => {
				return this.generateReportRow(payment);
			});
			this.generateReportFooter(this.records);
		});
		PaymentDetail.reportConfirmedPaymentByDate(this, start, end).subscribe(details => {
			this.listPaymentDetail = details;
		});
	}

	generateReportRow(payment: PaymentRequest): any {
		var record = {};
		record["payment_date"] = payment.create_date;
		record["customer_name"] = payment.user_name;
		record["customer_email"] = payment.email;
		record["customer_mobile"] = payment.phone;
		record["referal_name"] = this.getNameByReferalCode(payment.referal_code);
		record["month"] = Math.floor(payment.service_length / 30);
		record["sales"] = payment.amount;
		record["service_name"] = payment.service_name;
		record["commission"] = payment.amount * this.company.commission_rate / 100;
		PaymentDetail.searchPaymentDetailByPaymentRequest(this, payment.id, PAYMENT_DETAIL).subscribe(details => {
			record["payment_detail"] = details;
			record["product_quantity"] = details.length;
		});
		return record;
	}

	generateReportFooter(records: any) {
		this.totalSales = 0;
		_.each(this.records, record => {
			this.totalSales += record["sales"];
		})
	}

	getNameByReferalCode(code) {
		var name = '';
		if (code) {
			this.listStaff.forEach(staff => {
				if (staff.referal_code === code) {
					name = staff.name;
				}
			});
		}
		return name;
	}
}
