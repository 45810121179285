import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { environment } from '../../../environments/environment.prod'
import 'rxjs/add/operator/map';
import { Observable, Subject } from 'rxjs/Rx';
import { Token } from '../models/cloud/token.model';
import { BaseAPI } from './api/base.api';
import { User } from '../models/elearning/user.model';
import { CryptoService } from './crypto.service'; 

@Injectable()
export class APIService {
    apiEndpoint: string;
    cloudId: string = environment.CLOUDID;
    constructor(private http: HttpClient, private cryptoService: CryptoService) { }

    onStartHTTPReceiver: Subject<any> = new Subject();
    onFinishHTTPReceiver: Subject<any> = new Subject();
    onLoginReceiver: Subject<any> = new Subject();
    onLogoutReceiver: Subject<any> = new Subject();
    onTokenExpiredReceiver: Subject<any> = new Subject();
    onUnauthorizedAccessReceiver:Subject<any> = new Subject();
    onStartHTTP: Observable<any> = this.onStartHTTPReceiver.asObservable();
    onFinishHTTP: Observable<any> = this.onFinishHTTPReceiver.asObservable();
    onLogin: Observable<any> = this.onLoginReceiver.asObservable();
    onLogout: Observable<any> = this.onLogoutReceiver.asObservable();
    onTokenExpired: Observable<any> = this.onTokenExpiredReceiver.asObservable();
    onUnauthorizedAccess: Observable<any> = this.onUnauthorizedAccessReceiver.asObservable();


    startHttpTransaction() {
        this.onStartHTTPReceiver.next();
    }

    finishHttpTransaction() {
        this.onFinishHTTPReceiver.next();
    }

    userLogin(user:User) {
        this.onLoginReceiver.next(user);
    }

    userLogout() {
        this.onLogoutReceiver.next();
    }

    tokenExpired() {
        this.onTokenExpiredReceiver.next();
    }

    accessDenied() {
        this.onUnauthorizedAccessReceiver.next();
    }

    init(): Observable<any> {
        if (this.apiEndpoint)
            return Observable.of(this.apiEndpoint);
        let headers = new HttpHeaders({ 'Content-Type': 'application/json' ,'Authorization': `${this.cloudId}`});
        var endpoint = environment.AUTHEN_SERVER_URL + '/account/cloud';
        var params = {};
        return this.http.post(endpoint, params, { headers: headers })
            .map((response) => {
                this.apiEndpoint = response["api_endpoint"];
                return this.apiEndpoint;
            })
            .catch((e) => {
                console.log(e);
                return Observable.throw(e.error);
            });
    }

    register(user: any): Observable<any> {
        return this.init().flatMap(() => {
            let headers = new HttpHeaders({ 'Content-Type': 'text/plain' ,'Authorization': `${this.cloudId}`});
            var endpoint = this.apiEndpoint + '/account/register';
            var params = { user: user }
            this.startHttpTransaction();
            let encryptedMsg = this.cryptoService.encrypt(JSON.stringify(params));

            return this.http.post(endpoint, encryptedMsg, { headers: headers })
                .map((response: string) => JSON.parse(this.cryptoService.decrypt(response))).do(() => {
                    this.finishHttpTransaction();
                })
                .catch((e) => {
                    console.log(e);
                    this.finishHttpTransaction();
                    return Observable.throw(JSON.parse(this.cryptoService.decrypt(e.error)));
                });
        });
    }


    login(username: string, password: string): Observable<any> {
        return this.init().flatMap(() => {
            let headers = new HttpHeaders({ 'Content-Type': 'text/plain' ,'Authorization': `${this.cloudId}`});
            var endpoint = this.apiEndpoint + '/account/login';
            var params = { username: username, password: password }
            this.startHttpTransaction();
            let encryptedMsg = this.cryptoService.encrypt(JSON.stringify(params));
            return this.http.post(endpoint, encryptedMsg, { headers: headers, responseType: 'text' })
                .map((response:string) => {
                   return   JSON.parse(this.cryptoService.decrypt(response))
                }).do(resp => {
                    let user = new User();
                    user.fill(resp["user"]);
                    this.userLogin(user);
                    this.finishHttpTransaction();
                })
                .catch((e) => {
                    console.log("e,", e);
                    this.finishHttpTransaction();
                    return Observable.throw(JSON.parse(this.cryptoService.decrypt(e.error)));
                });
        });
    }

    logout(token: Token): Observable<any> {
        return this.init().flatMap(() => {
            let headers = new HttpHeaders({ 'Content-Type': 'text/plain' ,'Authorization': `${token.cloud_code} ${token.code}`});
            var endpoint = this.apiEndpoint + '/account/logout';
            var params = {token: token.code};
            this.startHttpTransaction();
            let encryptedMsg = this.cryptoService.encrypt(JSON.stringify(params));
            return this.http.post(endpoint, encryptedMsg, { headers: headers, responseType: 'text' })
                .do(() => {
                    this.finishHttpTransaction();
                })
                .catch((e) => {
                    console.log(e);
                    this.finishHttpTransaction();
                    return Observable.of(null);
                });
        });
    }

    resetPasswordRequest(email: string): Observable<any> {
        return this.init().flatMap(() => {
            let headers = new HttpHeaders({ 'Content-Type': 'text/plain' ,'Authorization': `${this.cloudId}`});
            var endpoint = this.apiEndpoint + '/account/resetpass/request';
            var params = { email: email }
            this.startHttpTransaction();
            let encryptedMsg = this.cryptoService.encrypt(JSON.stringify(params));
            return this.http.post(endpoint, encryptedMsg, { headers: headers, responseType: 'text' })
                .map((response: string) => JSON.parse(this.cryptoService.decrypt(response))).do(() => {
                    this.finishHttpTransaction();
                })
                .catch((e) => {
                    console.log(e);
                    this.finishHttpTransaction();
                    return Observable.throw(JSON.parse(this.cryptoService.decrypt(e.error)));
                });
        });
    }

    resetPasswordExecute(token: Token, new_pass: string): Observable<any> {
        return this.init().flatMap(() => {
            let headers = new HttpHeaders({ 'Content-Type': 'text/plain' ,'Authorization': `${token.cloud_code} ${token.code}`});
            var endpoint = this.apiEndpoint + '/account/resetpass/execute';
            var params = { new_pass: new_pass, token: token }
            this.startHttpTransaction();
            let encryptedMsg = this.cryptoService.encrypt(JSON.stringify(params));
            return this.http.post(endpoint, encryptedMsg, { headers: headers, responseType: 'text' })
                .map((response: string) => JSON.parse(this.cryptoService.decrypt(response))).do(() => {
                    this.finishHttpTransaction();
                })
                .catch((e) => {
                    console.log(e);
                    this.finishHttpTransaction();
                    return Observable.throw(JSON.parse(this.cryptoService.decrypt(e.error)));
                });
        });
    }

    upload(file: any, token: Token): Observable<any> {
        return this.init().flatMap(() => {
            let formData: FormData = new FormData();
            formData.append('file', file, file.name);
            this.startHttpTransaction();

            return Observable.create(observer => {
                let xhr: XMLHttpRequest = new XMLHttpRequest();
                xhr.onreadystatechange = () => {
                    if (xhr.readyState === 4) {
                        if (xhr.status === 200) {
                            observer.next(JSON.parse(xhr.response));
                            observer.complete();
                        } else {
                            observer.error(JSON.parse(xhr.response));
                        }
                        this.finishHttpTransaction();
                    }
                };

                xhr.upload.onprogress = (event) => {
                    console.log(event);
                    var progress = Math.round(event.loaded / event.total * 100);
                    observer.next(progress);
                };
                xhr.open('POST', this.apiEndpoint + '/file/upload', true);
                xhr.setRequestHeader('Authorization',`${token.cloud_code} ${token.code}` )
                xhr.send(formData);
            });
        });
    }

    upload_S3(file: any, token: Token): Observable<any> {
        return this.init().flatMap(() => {
            let formData: FormData = new FormData();
            formData.append('file', file, file.name);
            this.startHttpTransaction();

            return Observable.create(observer => {
                let xhr: XMLHttpRequest = new XMLHttpRequest();
                xhr.onreadystatechange = () => {
                    if (xhr.readyState === 4) {
                        if (xhr.status === 200) {
                            observer.next(JSON.parse(xhr.response));
                            observer.complete();
                        } else {
                            observer.error(JSON.parse(xhr.response));
                        }
                        this.finishHttpTransaction();
                    }
                };

                xhr.upload.onprogress = (event) => {
                    console.log(event);
                    var progress = Math.round(event.loaded / event.total * 100);
                    observer.next(progress);
                };
                xhr.open('POST', this.apiEndpoint + '/file/upload_s3', true);
                xhr.setRequestHeader('Authorization',`${token.cloud_code} ${token.code}` )
                xhr.send(formData);
            });
        });
    }

    unzip(filename: any, token: Token): Observable<any> {
        return this.init().flatMap(() => {
            let headers = new HttpHeaders({ 'Content-Type': 'application/json' ,'Authorization': `${token.cloud_code} ${token.code}`});
            this.startHttpTransaction();
            var params = { filename: filename };
            return this.http.post(this.apiEndpoint + '/file/unzip', JSON.stringify({ filename: filename }), { headers: headers, responseType: 'text' })
                .map(res => res)
                .do(() => {
                    this.finishHttpTransaction();
                })
                .catch(error => {
                    this.finishHttpTransaction();
                    return Observable.throw(error)
                }
                );
        });
    }

    convert2Pdf(filename: any, token: Token): Observable<any> {
        return this.init().flatMap(() => {
            let headers = new HttpHeaders({ 'Content-Type': 'application/json' ,'Authorization': `${token.cloud_code} ${token.code}`});
            this.startHttpTransaction();
            var params = { filename: filename };
            return this.http.post(this.apiEndpoint + '/file/convert2pdf', JSON.stringify({filename: filename }), { headers: headers,  responseType: 'text' })
                .map(res => res)
                .do(() => {
                    this.finishHttpTransaction();
                })
                .catch(error => {
                    this.finishHttpTransaction();
                    return Observable.throw(error)
                }
                );
        });
    }

    execute(api: BaseAPI, token: Token): Observable<any> {
        return this.init().flatMap(() => {
            let headers = token ? new HttpHeaders({ 'Content-Type': 'text/plain' ,'Authorization': `${token.cloud_code} ${token.code}`})
            : new HttpHeaders({ 'Content-Type': 'text/plain' ,'Authorization': `${this.cloudId}`});
            var params = api.params;
            var endpoint = this.apiEndpoint + api.Method;
            this.startHttpTransaction();
            var encryptedMsg = this.cryptoService.encrypt(JSON.stringify(params));
            return this.http.post(endpoint, encryptedMsg, { headers: headers , responseType: 'text'})
                .map((response: string) =>  {
                    return JSON.parse(this.cryptoService.decrypt(response));
                })
                .do(() => {
                    this.finishHttpTransaction();
                })
                .catch((e) => {
                    console.log(e);
                    this.finishHttpTransaction();
                    if (e["status"] == 400)
                        this.accessDenied();
                    if (e["status"] == 401)
                        this.tokenExpired();
                    return Observable.throw(JSON.parse(this.cryptoService.decrypt(e.error)));
                });
        });
    }

}