import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs/Rx';
import 'rxjs/add/operator/mergeMap';
import { AuthService } from './auth.service';
import { MenuItem } from 'primeng/primeng';
import * as _ from 'underscore';

@Injectable()
export class MenuService {

  private onShowCompanySettingReceiver: Subject<any> = new Subject();
  onShowCompanySetting: Observable<any> = this.onShowCompanySettingReceiver.asObservable();

  private ADMIN_MENU = [
    { label: 'Dashboard', icon: 'dashboard', routerLink: ['/dashboard/admin'], code: 'DASHBOARD' },
    { label: '', separator: true, styleClass: 'menu-separator' },
    {
      label: 'Course', icon: 'school', code: 'SCHOOL',
      items: [
        { label: 'Course', routerLink: ['/course/list'], code: 'SCHOOL-COURSE' },
        { label: 'Course group', routerLink: ['/course/groups'], code: 'SCHOOL-GROUP' },
        { label: 'Enrollment', routerLink: ['/course/enrollments'], code: 'SCHOOL-ENROLLMENT' },
      ]
    },
    {
      label: 'Customer', icon: 'person', code: 'CRM',
      items: [
        { label: 'Learners', routerLink: ['/crm/learners'], code: 'CRM-LEARNER' },
        { label: 'Payment requests', routerLink: ['/crm/recharges'], code: 'CRM-RECHARGE' },
        { label: 'Mailing list', routerLink: ['/crm/mail-list'], code: 'CRM-MAIL_LIST' },
      ]
    },
    {
      label: 'Web CMS', icon: 'web', code: 'CMS',
      items: [
        { label: 'Page contents', routerLink: ['/cms/pages'], code: 'CMS-PAGES' },
        { label: 'SEO', routerLink: ['/cms/seo'], code: 'CMS-SEO' },
      ]
    },
    { label: '', separator: true, styleClass: 'menu-separator' },
    {
      label: 'Analysis', icon: 'pie_chart', code: 'ANALYSIS',
      items: [
        { label: 'Report', routerLink: ['/analysis/reports'], code: 'ANALYSIS-REPORT' },
        { label: 'Chart', routerLink: ['/analysis/charts'], code: 'ANALYSIS-CHART' },
      ]
    },
    {
      label: 'Accounts', icon: 'people', code: 'ACCOUNT',
      items: [
        { label: 'Staff', routerLink: ['/account/staffs'], code: 'ACCOUNT-STAFF' },
        { label: 'Teacher', routerLink: ['/account/teachers'], code: 'ACCOUNT-TEACHER' },
        { label: 'Learner', routerLink: ['/account/learners'], code: 'ACCOUNT-LEARNER' },
        { label: '', separator: true, styleClass: 'menu-separator' },
        { label: 'Group', routerLink: ['/account/groups'], code: 'ACCOUNT-USER_GROUP' },
      ]
    },
    {
      label: 'Setting', icon: 'settings', code: 'SETTING', command: () => {
        this.onShowCompanySettingReceiver.next();
      }
    }

  ];

  private USER_MENU = [
    { label: 'Dashboard', icon: 'dashboard', routerLink: ['/dashboard/lms'] },
    { label: '', separator: true, styleClass: 'menu-separator' },
    { label: 'My courses', icon: 'school', routerLink: ['/dashboard/courses'] },
    { label: '', separator: true, styleClass: 'menu-separator' },
    { label: 'Report', icon: 'pie_chart', routerLink: ['/report/staff'] },

  ];

  private TEACHER_MENU = [
    { label: 'Report', icon: 'pie_chart', routerLink: ['/report/teacher'] },
  ]

  constructor(private authService: AuthService) {
  }

  menuToTree(menu) {
    var tree = [];
    _.each(menu, ((menuItem: MenuItem) => {
      if (!menuItem.separator) {
        var node = this.convertMenuNodeToTreeNode(menuItem);
        node.expanded = true;
        tree.push(node);
      }
    }));
    return tree;
  }

  findMenuTreeNode(tree, code: string) {
    for (var i = 0; i < tree.length; i++) {
      var node = this.findSubMenuTreeNode(tree[i], code);
      if (node)
        return node;
    }
    return null;
  }

  private findSubMenuTreeNode(node, code: string) {
    if (node["data"] == code)
      return node;
    for (var i = 0; i < node.children.length; i++) {
      var subNode = this.findSubMenuTreeNode(node.children[i], code);
      if (subNode)
        return subNode;
    }
  }

  private convertMenuNodeToTreeNode(menuItem) {
    var node = menuItem;
    node["data"] = menuItem["code"];
    node["children"] = [];
    _.each(menuItem.items, ((menuSubItem: MenuItem) => {
      if (!menuSubItem.separator) {
        var subNode = this.convertMenuNodeToTreeNode(menuSubItem);
        node["children"].push(subNode);
      }
    }));
    return node;
  }

  adminMenu() {
    if (this.authService.UserProfile.IsSuperAdmin)
      return this.ADMIN_MENU;
    var menuCodes = [];
    if (this.authService.UserPermission && this.authService.UserPermission.menu_access)
      menuCodes = JSON.parse(this.authService.UserPermission.menu_access);
    var menu = [];
    _.each(this.ADMIN_MENU, ((menuItem: MenuItem) => {
      var allow = this.isAllowTowViewAdminMenu(menuItem, menuCodes);
      if (allow)
        menu.push(menuItem);
    }));
    return menu;
  }

  isAllowTowViewAdminMenu(menuItem, menuCodes): boolean {
    if (menuItem.separator || !menuItem["code"])
      return true;
    return _.contains(menuCodes, menuItem["code"]);
  }

  userMenu() {
    return this.USER_MENU;
  }

  teacherMenu() {
    return this.TEACHER_MENU;
  }
}
