import { NgModule } from '@angular/core';
import { Routes } from '@angular/router';
import { PageListComponent } from './web/page-content/page-list.component';
import { CMSComponent } from './cms.component';
import { AdminGuard } from '../shared/guards/admin.guard';
import { AuthGuard } from '../shared/guards/auth.guard';
import { RouterModule } from '@angular/router';
import { LearningPathListComponent } from './web/learning-path/learning-path-list.component';
import { CourseSyllabusComponent } from './course/course-syllabus/course-syllabus.component';
import { CoursePublishComponent } from './course/course-publish/course-publish.component';
import { CourseBackupComponent } from './course/course-backup/course-backup.component';
import { CourseRestoreComponent } from './course/course-restore/course-restore.component';
import { CourseResolve, CourseSyllabusResolve } from './route.resolver';
import { PageSeoListComponent } from './web/page-seo/page-list.component';

export const CMSRoutes: Routes = [
  {
    path: 'cms',
    component: CMSComponent,
    data: {
      breadcrumb: 'CMS'
    },
    canActivate: [AdminGuard],
    children:
      [
        {
          path: "pages",
          component: PageListComponent,
          data: {
            breadcrumb: 'Page content'
          }
        },
        {
          path: "news",
          component: LearningPathListComponent,
          data: {
            breadcrumb: 'Hot learning paths',
          },
        },
        {
          path: "course/backup/:courseId/:sylId",
          component: CourseBackupComponent,
          data: {
            breadcrumb: 'Course backup'
          },
          resolve: {
            course: CourseResolve,
            syllabus: CourseSyllabusResolve
          },
        },
        {
          path: "course/publish/:courseId/:sylId",
          component: CoursePublishComponent,
          data: {
            breadcrumb: 'Course publish'
          },
          resolve: {
            course: CourseResolve,
            syllabus: CourseSyllabusResolve
          },
        },
        {
          path: "course/restore/:courseId/:sylId",
          component: CourseRestoreComponent,
          data: {
            breadcrumb: 'Course restore'
          },
          resolve: {
            course: CourseResolve,
            syllabus: CourseSyllabusResolve
          },
        },
        {
          path: "seo",
          component: PageSeoListComponent,
          data: {
            breadcrumb: 'Page content'
          }
        },
      ]
  },

  {
    path: 'cms',
    component: CMSComponent,
    data: {
      breadcrumb: 'Cms'
    },
    canActivate: [AuthGuard],
    children:
      [
        {
          path: "course/compose/:courseId/:sylId",
          component: CourseSyllabusComponent,
          data: {
            breadcrumb: 'Course compose'
          },
          resolve: {
            course: CourseResolve,
            syllabus: CourseSyllabusResolve
          },
        },
      ]
  }

]

@NgModule({
  imports: [RouterModule.forChild(CMSRoutes)],
  exports: [RouterModule]
})
export class CMSRoutingModule { }