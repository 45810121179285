import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { BaseComponent } from '../../../shared/components/base/base.component';
import * as _ from 'underscore';
import { PAYMENT_METHOD, PAYMENT_STATUS } from '../../../shared/models/constants'
import { PaymentRequest } from '../../../shared/models/elearning/payment-request.model';
import { DepositDialog } from '../deposit-dialog/deposit-dialog.component';
import { NewDepositDialog } from '../new-deposit-dialog/new-deposit-dialog.component';
import { PaymentDetail } from '../../../shared/models/elearning/payment-detail.model';
import { DepositContentDialog } from '../deposit-content-dialog/deposit-content-dialog.component';

@Component({
    
    selector: 'payment-request-list',
    templateUrl: 'payment-request-list.component.html',
    styleUrls: ['payment-request-list.component.css'],
})
export class PaymentRequestListComponent extends BaseComponent {

    PAYMENT_METHOD = PAYMENT_METHOD;
    PAYMENT_STATUS = PAYMENT_STATUS;

    private statusValues: string[];
    private selectedRequest: any;
    private displayRequests: PaymentRequest[];
    private requests: PaymentRequest[];

    @ViewChild(DepositDialog,{static:true}) depositDialog: DepositDialog;
    @ViewChild(NewDepositDialog,{static:true}) newDepositDialog: NewDepositDialog;
    @ViewChild(DepositContentDialog,{static:true}) depositContentDialog: DepositContentDialog;

    constructor() {
        super();
        this.statusValues = ['open'];
    }

    ngOnInit() {
        this.loadRequests();
    }


    confirmRequest(request: PaymentRequest) {
        this.depositDialog.show(request);
        this.depositDialog.onHide.subscribe(() => {
            this.loadRequests();
        });
    }


    rejectRequest(request: PaymentRequest) {
        this.confirm(this.translateService.instant('Are you sure to reject ?'), () => {
            request.status = 'rejected';
            request.save(this).subscribe(() => {
                this.selectedRequest = null;
            });
            // request.rejectPayment(this, request.id).subscribe(resp => {
            //     console.log(resp);
            //     this.selectedRequest = null;
            // });
        });
    }

    loadRequests() {
        PaymentRequest.all(this).subscribe(requests => {
            PaymentDetail.all(this).subscribe(details => {
                this.requests = _.sortBy(requests, (request: PaymentRequest) => {
                    return -request.id;
                });
                this.requests.forEach((request: PaymentRequest) => {
                    details.forEach((detail: PaymentDetail) => {
                        if (request.id == detail.request_id) {
                            request.course_detail.push(detail.course_name);
                        }
                    });
                });
                this.filterByStatus();
            });
        });
    }

    filterByStatus() {
        this.displayRequests = _.filter(this.requests, (request: PaymentRequest) => {
            return this.statusValues.includes(request.status);
        });
    }

    addRequest() {
        var request = new PaymentRequest();
        this.newDepositDialog.show(request);
        this.newDepositDialog.onHide.first().subscribe(() => {
            this.loadRequests();
        });
    }

    viewRequest(request: PaymentRequest) {
        this.depositContentDialog.show(request);
    }
}

