import { Component, OnInit, AfterViewInit } from '@angular/core';

@Component({
  
  selector: 'crm',
  template: '<router-outlet></router-outlet>'

})
export class CRMComponent  {

  constructor() {

  }




}
