import { BaseModel } from '../base.model';
import { Observable, Subject } from 'rxjs/Rx';
import { Model, ReadOnlyProperty } from '../decorator';
import { APIContext } from '../context';
import { SearchReadAPI } from '../../services/api/search-read.api';
import { ListAPI } from '../../services/api/list.api';
import { LearningPathItem } from './learning-path-item.model';

@Model('opencourse.learning_path')
export class LearningPath extends BaseModel {

    // Default constructor will be called by mapper
    constructor() {
        super();

        this.name = undefined;
        this.group_name = undefined;
        this.is_hot = undefined;
        this.course_count = undefined;
        this.logo_url = undefined;
        this.logo_id = undefined;
        this.member_count = undefined;
        this.supervisor_name = undefined;
        this.length = undefined;
        this.summary = undefined;
        this.intro_video_url = undefined;
        this.intro_video_id = undefined;
    }

    name: string;
    intro_video_id: number;
    length: number;
    member_count: number;
    group_name: string;
    is_hot: boolean;
    course_count: number;
    logo_url: string;
    logo_id: number;
    supervisor_name: string;
    summary: string;
    intro_video_url: string;

    

    static __api__listItems(pathId:number,fields?:string[]): SearchReadAPI {
        return new SearchReadAPI(LearningPathItem.Model,fields,"[('path_id','=',"+pathId+")]");
    }

    listItems( context:APIContext,fields?:string[]): Observable<any[]> {
        if (!this.id)
            return Observable.of([]);
        return LearningPathItem.search(context,fields,"[('path_id','=',"+this.id+")]");
    }


}
