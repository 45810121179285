import { NgModule } from '@angular/core';
import { Routes } from '@angular/router';
import { SalesComponent } from './sales.component';
import { GroupListComponent } from '../shared/components/group-list/group-list.component';
import { AdminGuard } from '../shared/guards/admin.guard';
import { RouterModule } from '@angular/router';
import { ProductFormComponent } from './product/service-form/service-form.component';
import { ServiceListComponent } from './product/service-list/service-list.component';
import { ProductViewComponent } from './product/service-view/service-view.component';
import { ProductResolve } from './route.resolver';
export const SaleRoutes: Routes = [
  {
    path: 'service',
    component: SalesComponent,
    data: {
      breadcrumb: 'Sales'
    },
    canActivate: [AdminGuard],
    children:
      [

        {
          path: "list",
          component: ServiceListComponent,
          data: {
            breadcrumb: 'Product list'
          }
        },
      ]
  }

]

@NgModule({
  imports: [RouterModule.forChild(SaleRoutes)],
  exports: [RouterModule]
})
export class CourseRoutingModule { }