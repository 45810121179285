import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { Group } from '../../../shared/models/elearning/group.model';
import { BaseDialog } from '../../../shared/components/base/base.dialog';
import { Course } from '../../../shared/models/elearning/course.model';
import { User } from '../../../shared/models/elearning/user.model';
import { Product } from '../../../shared/models/elearning/product.model';
import * as _ from 'underscore';

import { Subscription } from 'rxjs/Subscription';

@Component({
	
	selector: 'product-dialog',
	templateUrl: 'service-dialog.component.html',
	styleUrls: ['service-dialog.component.css'],
})
export class ProductDialog extends BaseDialog<Product> {



	constructor() {
		super();

	}

	
}

