import { Component, OnInit, Input } from '@angular/core';
import { Observable, Subject } from 'rxjs/Rx';
import { AuthService } from '../../services/auth.service';
import { Group } from '../../models/elearning/group.model';
import { BaseComponent } from '../base/base.component';
import { User } from '../../models/elearning/user.model';
import * as _ from 'underscore';
import { TreeUtils } from '../../helpers/tree.utils';
import { TreeNode } from 'primeng/api';
import { GROUP_CATEGORY, CONTENT_STATUS } from '../../models/constants'
import { SelectItem } from 'primeng/api';

const USER_FIELDS = ['name', 'email', 'login'];


@Component({
	selector: 'select-radio-teacher-dialog',
	templateUrl: 'select-radio-teacher-dialog.component.html',
	styleUrls: ['select-radio-teacher-dialog.component.css'],
})
export class SelectRadioTeachersDialog extends BaseComponent {

	private selectedUser: User;
	private users: User[];
	private display: boolean;

	private onSelectUserReceiver: Subject<any> = new Subject();
	onSelectUser: Observable<any> = this.onSelectUserReceiver.asObservable();

	constructor() {
		super();
		this.display = false;
	}

	hide() {
		this.display = false;
	}

	show() {
		this.display = true;
		this.selectedUser = null;
		User.listAllTeacher(this, USER_FIELDS).subscribe((users) => {
			this.users = users;
		})
	}

	select() {
		this.onSelectUserReceiver.next(this.selectedUser);
		this.hide();
	}


}

