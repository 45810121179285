import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';


@NgModule({
  imports: [
    RouterModule.forRoot([])
  ],
  exports: [RouterModule],
  providers: []
})
export class AppRoutingModule { }

