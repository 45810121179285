import { Observable, Subject } from 'rxjs/Rx'
import { Group } from '../models/elearning/group.model';
import { Course } from '../models/elearning/course.model';
import { CourseMember } from '../models/elearning/course-member.model';
import { CourseLog } from '../models/elearning/log.model';
import * as _ from 'underscore';
import { Injectable } from '@angular/core';

export class ReportUtils {

	constructor() {
	}

	createRowGroupMetaData(records: any, key: string) {
		var rowGroupMetadata = {};
		if (records) {
			for (let i = 0; i < records.length; i++) {
				let rowData = records[i];
				let value = rowData[key];
				if (i == 0) {
					rowGroupMetadata[value] = { index: 0, size: 1 };
				}
				else {
					let previousRowData = records[i - 1];
					let previousRowGroup = previousRowData[key];
					if (value === previousRowGroup)
						rowGroupMetadata[value].size++;
					else
						rowGroupMetadata[value] = { index: i, size: 1 };
				}
			}
		}
		return rowGroupMetadata;
	}

	analyzeCourseMemberActivity(logs: CourseLog[]): any {
		logs = _.sortBy(logs, (log:CourseLog)=> {
			return new Date(log.start.toString()).getTime();
		});
		var onTime = 0;
		var startCourseUnitLogs = _.filter(logs, (log) => {
			return log.start != null && log.code == 'START_COURSE_UNIT';
		});
		var endCourseUnitLogs = _.filter(logs, (log) => {
			return log.start != null && log.code == 'COMPLETE_COURSE_UNIT';
		});
		var first_attempt = _.min(startCourseUnitLogs, (log) => {
			return new Date(log.start.toString()).getTime();
		});
		var last_attempt = _.max(endCourseUnitLogs, (log) => {
			return new Date(log.start.toString()).getTime();
		});
		for (var i=0;i<logs.length;i++) {
			var current = logs[i];
			if (current.code == "START_COURSE_UNIT") {
				if (i +1 < logs.length && logs[i+1].res_id == current.res_id && logs[i+1].member_id == current.member_id) {
					var next = logs[i+1];
					if (next.code == "STOP_COURSE_UNIT" || next.code =="COMPLETE_COURSE_UNIT") {
						onTime += new Date(next.start.toString()).getTime() - new Date(current.start.toString()).getTime();
					}
				}
			}
		}		
		var unitIds = [];
		_.each(logs, (log:CourseLog) => {
			if (log.code == 'COMPLETE_COURSE_UNIT') {
				unitIds.push(log.res_id);
			}
		});
		unitIds = _.uniq(unitIds, (id)=> {
			return id;
		});

		return [first_attempt.start, last_attempt.start, onTime, unitIds.length];
	}

	analyseCourseMember(course: Course, members: CourseMember[]): any {
		var record = {};

		var studentMembers = _.filter(members, (member: CourseMember) => {
			return member.role == 'student';
		});

		// var registeredMembers = _.filter(studentMembers, (member: CourseMember) => {
		// 	return member.enroll_status == 'registered';
		// });
		// var inprogressMembers = _.filter(studentMembers, (member: CourseMember) => {
		// 	return member.enroll_status == 'in-study';
		// });
		// var completededMembers = _.filter(studentMembers, (member: CourseMember) => {
		// 	return member.enroll_status == 'completed';
		// });
		var registeredMembers = _.filter(studentMembers, (member: CourseMember) => {
			return member.percent_complete == 0;
		});
		registeredMembers.forEach((registeredMember: CourseMember) => {
			registeredMember.enroll_status = 'registered';
		});
		var inprogressMembers = _.filter(studentMembers, (member: CourseMember) => {
			return member.percent_complete > 0 && member.percent_complete < 100 ;
		});
		inprogressMembers.forEach((inprogressMembers: CourseMember) => {
			inprogressMembers.enroll_status = 'in-study';
		});
		var completededMembers = _.filter(studentMembers, (member: CourseMember) => {
			return member.percent_complete == 100;
		});
		completededMembers.forEach((completededMember: CourseMember) => {
			completededMember.enroll_status = 'completed';
		});
		record["total_member"] = members.length;
		record["total_member_student"] = studentMembers.length;
		record["total_member_registered"] = registeredMembers.length;
		record["percentage_member_registered"] = studentMembers.length ? Math.round(registeredMembers.length / studentMembers.length * 100) : 0;
		record["total_member_inprogress"] = inprogressMembers.length;
		record["percentage_member_inprogress"] = studentMembers.length ? Math.round(inprogressMembers.length / studentMembers.length * 100) : 0;
		record["total_member_completed"] = completededMembers.length;
		record["percentage_member_completed"] = 100 - record["percentage_member_registered"] - record["percentage_member_inprogress"];
		return record;
	}

	
}
