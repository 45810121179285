import { Component, OnInit, AfterViewInit } from '@angular/core';

@Component({
  
  selector: 'setting',
  template: '<router-outlet></router-outlet>'

})
export class SettingComponent  {

  constructor() {
  }

}
