import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import 'rxjs/add/operator/map';
import { Observable, Subject, Observer } from 'rxjs/Rx';
import { DEFAULT_LANG } from '../models/constants';

@Injectable()
export class SettingService {

  viewMode: string;
  APP_ID = 'PEO-COURSE';

  constructor(private authService: AuthService) {
  }

  viewModeEventReceiver: Subject<string> = new Subject();
  viewModeEvents: Observable<string> = this.viewModeEventReceiver.asObservable();


  get ViewMode() {
    if (this.viewMode)
      return this.viewMode;
    if (this.authService.UserProfile)
      return this.authService.UserProfile.IsAdmin ? 'admin' : 'lms';
    return null;
  }

  set ViewMode(data: string) {
    if (data != this.viewMode) {
      this.viewMode = data;
      this.viewModeEventReceiver.next(data);
    }
  }

  set Lang(lang: string) {
    localStorage.setItem(this.APP_ID+':'+'language', lang);
  }

  get Lang(): string {
    if (localStorage.getItem(this.APP_ID+':'+'language'))
      return localStorage.getItem(this.APP_ID+':'+'language');
    else
      return DEFAULT_LANG;
  }

}