import { Component, OnInit, Input } from '@angular/core';
import { Observable, Subject } from 'rxjs/Rx';
import { AuthService } from '../../services/auth.service';
import { Group } from '../../models/elearning/group.model';
import { BaseComponent } from '../base/base.component';
import { LearningPath } from '../../../shared/models/elearning/learning-path.model';
import * as _ from 'underscore';
import { TreeUtils } from '../../../shared/helpers/tree.utils';
import { TreeNode } from 'primeng/api';
import { GROUP_CATEGORY, CONTENT_STATUS } from '../../../shared/models/constants'
import { SelectItem } from 'primeng/api';

const PATH_FIELDS = ['name']
@Component({
	
	selector: 'select-path-dialog',
	templateUrl: 'select-path-dialog.component.html',
	styleUrls: ['select-path-dialog.component.css'],
})
export class SelectPathDialog extends BaseComponent {

	selectedPath: LearningPath;
	paths:LearningPath[];
	display: boolean;

	onSelectPathReceiver: Subject<any> = new Subject();
    onSelectPath:Observable<any> =  this.onSelectPathReceiver.asObservable();

	constructor() {
		super();
		this.display = false;
		this.selectedPath = null;;
	}

	hide() {
		this.display = false;
	}

	show() {
		this.display = true;
		this.selectedPath = null;
		LearningPath.all(this, PATH_FIELDS).subscribe((paths)=> {
			this.paths = paths;
		})
	}

	select() {
		this.onSelectPathReceiver.next(this.selectedPath);
		this.selectedPath=null;
		this.hide();
	}

}

