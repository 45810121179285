import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { AuthService } from '../../../shared/services/auth.service';
import { Group } from '../../../shared/models/elearning/group.model';
import { BaseComponent } from '../../../shared/components/base/base.component';
import { User } from '../../../shared/models/elearning/user.model';
import { GENDER, COURSE_MEMBER_ROLE, COURSE_MEMBER_ENROLL_STATUS } from '../../../shared/models/constants';
import { BaseModel } from '../../../shared/models/base.model';
import { Course } from '../../../shared/models/elearning/course.model';
import { CourseMember } from '../../../shared/models/elearning/course-member.model';
import * as _ from 'underscore';

const COURSE_MEMBER_FIELDS = ['course_name', 'certificate_id', 'role', 'enroll_status', 'date_register'];
const EXAM_MEMBER_FIELDS = ['exam_name', 'grade', 'enroll_status', 'date_register', 'status', 'exam_id', 'role'];


@Component({
	
	selector: 'learner-view-content',
	templateUrl: 'learner-content.component.html',
	styleUrls: ['learner-content.component.css'],
})
export class LearnerViewContentComponent extends BaseComponent {

	GENDER = GENDER;
	COURSE_MEMBER_ENROLL_STATUS = COURSE_MEMBER_ENROLL_STATUS;


	private user: User;
	private courseMembers: CourseMember[];

	constructor(private router: Router, private route: ActivatedRoute) {
		super();
		this.user = new User();
	}

	render(user: User) {
		this.user = user;
		this.courseMembers = [];
		this.user.listCourseMembers(this,COURSE_MEMBER_FIELDS)
			.subscribe(members => {
				this.courseMembers = members;
				this.displayCourseHistory();
			});
	}

	displayCourseHistory() {
		this.courseMembers = _.filter(this.courseMembers, (member: CourseMember) => {
			return member.role == 'student';
		});
	}

}

