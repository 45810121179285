import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { AuthService } from '../../../shared/services/auth.service';
import { Group } from '../../../shared/models/elearning/group.model';
import { BaseComponent } from '../../../shared/components/base/base.component';
import { User } from '../../../shared/models/elearning/user.model';
import * as _ from 'underscore';
import { TeacherContentComponent } from './teacher-content.component';

const GROUP_FIELDS = ['name', 'category', 'parent_id', 'child_ids'];
const USER_FIELDS = ['email', 'login'];

@Component({
	
	selector: 'teacher-form',
	templateUrl: 'teacher-form.component.html',
})
export class TeacherFormComponent extends BaseComponent {

	@ViewChild(TeacherContentComponent,{static:true}) formContent: TeacherContentComponent;

	private user: User;
	private teachers: User[];

	constructor(private router: Router, private route: ActivatedRoute) {
		super();
		this.user = new User();
	}

	ngOnInit() {
		this.user = this.route.snapshot.data['user'];
		this.formContent.render(this.user);
		User.listAllTeacher(this, USER_FIELDS).subscribe(users => {
			this.teachers = users;
		});
	}

	save() {
		if (this.user.IsNew) {
			this.user.role = 'teacher';
			if (this.checkDuplicateInformation(this.user)) {

			} else {
				User.register(this, this.user).subscribe(resp => {
					if (!resp["success"]) {
						this.error(this.translateService.instant(resp["message"]));
						return;
					}
					this.user.id = +resp["user_id"];
					this.router.navigate(['/account/teacher/view', this.user.id]);
				});
			}
		}
		else {
			this.user.save(this).subscribe(() => {
				this.router.navigate(['/account/teacher/view', this.user.id]);
			});
		}
	}

	cancel() {
		if (this.user.IsNew)
			this.router.navigate(['/account/teachers']);
		else
			this.router.navigate(['/account/teacher/view', this.user.id]);
	}

	checkDuplicateInformation(user: User) {
		var result = false;
		this.teachers.forEach(teacher => {
			if (teacher.login === user.login) {
				result = true;
				this.warn('Tên đăng nhập đã được sử dụng');
			} else if (teacher.email === user.email) {
				result = true;
				this.warn('Email đã được sử dụng');
			}
		});
		return result;
	}
}

