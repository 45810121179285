import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { Router, Resolve, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { APIService } from '../shared/services/api.service';
import { AuthService } from '../shared/services/auth.service';
import { APIContext } from '../shared/models/context';
import { Course } from '../shared/models/elearning/course.model';
import { LearningPath } from '../shared/models/elearning/learning-path.model';
import { Product } from '../shared/models/elearning/product.model';

@Injectable()
export class ProductResolve implements Resolve<Product>, APIContext {

	apiService: APIService;
	authService: AuthService;

	constructor(apiService: APIService, authService: AuthService, private router: Router) {
		this.apiService = apiService;
		this.authService = authService;
	}

	resolve(route: ActivatedRouteSnapshot) {
		if (route.paramMap.get('productId'))
			return Product.get(this, +route.paramMap.get('productId'));
		else
			return Observable.of(new Product());
	}
}
