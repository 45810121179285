import { Injectable } from '@angular/core';

@Injectable()
export class CryptoService {

  secretKey = "AIzaSyBS37KU-w4s3sHh_LnZwWX0lAwsc5a9c9s";
  constructor() { }

  encrypt(value : string) : string{
    return window.btoa(unescape(encodeURIComponent(value)));
  }

  decrypt(value : string): string {
    return unescape(encodeURIComponent(window.atob(value)));
  }
}