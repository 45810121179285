import { Component, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable, Subscription } from 'rxjs/Rx';
import { BaseComponent } from '../../../shared/components/base/base.component';
import { AuthService } from '../../../shared/services/auth.service';
import * as _ from 'underscore';
import { USER_STATUS, GROUP_CATEGORY } from '../../../shared/models/constants'
import { Contact } from '../../../shared/models/elearning/contact.model';
import { Group } from '../../../shared/models/elearning/group.model';
import { TreeUtils } from '../../../shared/helpers/tree.utils';
import { TreeNode, SelectItem } from 'primeng/api';
import { CourseMember } from '../../../shared/models/elearning/course-member.model';
import { BaseModel } from '../../../shared/models/base.model';
import { ExcelService } from '../../../shared/services/excel.service';


@Component({
  
  selector: 'mailing-list',
  templateUrl: 'mailing-list.component.html',
  styleUrls: ['mailing-list.component.css'],
})
export class MailingListComponent extends BaseComponent {

  private contacts: Contact[];
  private selectedContact: any;
  private fields: SelectItem[];
  private selectedFields: string[];
  @Input() lang: string;

  constructor(private route: ActivatedRoute, private excelService: ExcelService) {
    super();
    this.fields = [
      { value: 'email_from', label: this.translateService.instant('Email') },
      { value: 'create_date', label: this.translateService.instant('Subscription date') },
    ];
  }

  ngOnInit() {
    this.loadContacts();
  }

  loadContacts() {
    Contact.all(this).subscribe(contacts => {
      this.contacts = contacts;
    });
  }

  deleteContact(contact: Contact) {
    this.confirm(this.translateService.instant('Are you sure to delete ?'), () => {
      contact.delete(this).subscribe(() => {
        this.loadContacts();
        this.selectedContact = null;
      });
    });
  }

  exportContact() {
    this.selectedFields = ['email_from', 'create_date'];
    var data = _.map(this.contacts, (contact) => {
      var contactData = {};
      _.each(this.selectedFields, (field) => {
        var exportField = _.find(this.fields, obj => {
          return obj["value"] == field;
        });
        contactData[exportField["label"]] = contact[field];
      });
      return contactData;
    });
    this.excelService.exportAsExcelFile(data, 'mail_list_export');
  }

}
