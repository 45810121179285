import { Observable, Subject } from 'rxjs/Rx'
import { DatePipe } from "@angular/common";
import { Group } from '../models/elearning/group.model';
import { CourseLog, UserLog } from '../models/elearning/log.model';
import * as _ from 'underscore';
import * as moment from 'moment';
import { SERVER_DATETIME_FORMAT } from '../models/constants';
import { Injectable } from '@angular/core';
import { APIContext } from '../models/context';
import { Course } from '../models/elearning/course.model';
import { User } from '../models/elearning/user.model';

export class StatsUtils {

	constructor() {
	}


	courseStatisticByDate(context: APIContext, startDate: Date, endDate: Date): Observable<any> {
		var startDateStr = moment(startDate).format(SERVER_DATETIME_FORMAT);
		var endDateStr = moment(endDate).format(SERVER_DATETIME_FORMAT);
		return CourseLog.search(context, [], "[('start','>=','" + startDateStr + "'),('start','<=','" + endDateStr + "')]").map(logs => {
			var dayLengthMills = 1000 * 60 * 60 * 24;
			var slots = [];
			var starTimeMillis = startDate.getTime();
			var endTimeMills = endDate.getTime();
			for (var i = 0; starTimeMillis + i * dayLengthMills < endTimeMills; i++)
				slots.push(0);
			_.each(logs, (log: CourseLog) => {
				var start = new Date(log.start);
				var index = Math.floor((start.getTime() - starTimeMillis) / dayLengthMills);
				slots[index]++;
			});
			return slots;
		});
	}

	courseMemberStatisticByDate(context: APIContext, memberId: number, courseId: number, startDate: Date, endDate: Date): Observable<any> {
		var startDateStr = moment(startDate).format(SERVER_DATETIME_FORMAT);
		var endDateStr = moment(endDate).format(SERVER_DATETIME_FORMAT);
		return CourseLog.search(context, [], "[('member_id','='," + memberId + "),('course_id','='," + courseId + "),('start','>=','" + startDateStr + "'),('start','<=','" + endDateStr + "')]").map(logs => {
			var dayLengthMills = 1000 * 60 * 60 * 24;
			var slots = [];
			var starTimeMillis = startDate.getTime();
			var endTimeMills = endDate.getTime();
			for (var i = 0; starTimeMillis + i * dayLengthMills < endTimeMills; i++)
				slots.push(0);
			_.each(logs, (log: CourseLog) => {
				var start = new Date(log.start);
				var index = Math.floor((start.getTime() - starTimeMillis) / dayLengthMills);
				slots[index]++;
			});
			return slots;
		});
	}

	
	userLoginStatisticByDate(context: APIContext, startDate: Date, endDate: Date): Observable<any> {
		var startDateStr = moment(startDate).format(SERVER_DATETIME_FORMAT);
		var endDateStr = moment(endDate).format(SERVER_DATETIME_FORMAT);
		return UserLog.search(context, [], "[('start','>=','" + startDateStr + "'),('start','<=','" + endDateStr + "'),('res_model','=','"+User.Model +"'),('code','=','LOGIN')]").map(logs => {
			var dayLengthMills = 1000 * 60 * 60 * 24;
			var slots = [];
			var starTimeMillis = startDate.getTime();
			var endTimeMills = endDate.getTime();
			for (var i = 0; starTimeMillis + i * dayLengthMills < endTimeMills; i++)
				slots.push(0);
			_.each(logs, (log: UserLog) => {
				var start = new Date(log.start);
				var index = Math.floor((start.getTime() - starTimeMillis) / dayLengthMills);
				slots[index]++;
			});
			return slots;
		});
	}

}
