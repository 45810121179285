import { NgModule } from '@angular/core';
import { Routes } from '@angular/router';
import { LMSComponent } from './lms.component';
import { CourseEditComponent } from './course/course-edit/course-edit.component';
import { CourseGuard } from '../shared/guards/course.guard';
import { SyllabusGuard } from '../shared/guards/syllabus.guard';
import { RouterModule } from '@angular/router';
import { CourseResolve } from './route.resolver';

export const LMSRoutes: Routes = [
    {
       path: 'lms',
       component: LMSComponent,
       data: {
         breadcrumb:'LMS'
       },
       children:
       [
            {
               path: "courses/edit/:courseId/:memberId",
               component: CourseEditComponent,
               data: {
                 breadcrumb:'Edit course'
               },
               resolve: {
                course: CourseResolve
               },
               canActivate:[SyllabusGuard]
            },
       ]
    }

]

@NgModule({
  imports: [RouterModule.forChild(LMSRoutes)],
  exports: [RouterModule]
})
export class LMSRoutingModule {}