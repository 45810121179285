import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { BaseComponent } from '../../shared/components/base/base.component';
import { ReportUtils } from '../../shared/helpers/report.utils';
import * as _ from 'underscore';
import { GROUP_CATEGORY, COURSE_STATUS, COURSE_MODE } from '../../shared/models/constants'
import { Course } from '../../shared/models/elearning/course.model';
import { CourseUnit } from '../../shared/models/elearning/course-unit.model';
import { CourseSyllabus } from '../../shared/models/elearning/course-syllabus.model';
import { CourseMember } from '../../shared/models/elearning/course-member.model';
import { Group } from '../../shared/models/elearning/group.model';
import { User } from '../../shared/models/elearning/user.model';
import { SelectItem, TreeNode } from 'primeng/api';
import { BaseModel } from '../../shared/models/base.model';
import { TreeUtils } from 'src/app/shared/helpers/tree.utils';

const COURSE_FIELDS = ['status','name', 'write_date','create_date', 'supervisor_id', 'logo_url', 'summary', 'description', 'code', 'mode', 'unit_count', 'group_id','group_name', 'syllabus_id'];
const GROUP_FIELDS = ['name', 'category' ,'parent_id'];

@Component({
    
    selector: 'lms-course-list',
    templateUrl: 'course-list.component.html',
    styleUrls: ['course-list.component.css'],
})
export class CourseListComponent extends BaseComponent implements OnInit {

    COURSE_STATUS = COURSE_STATUS;
    COURSE_MODE = COURSE_MODE;

    private courses: Course[];
    private filteredCourses: Course[];
    private courseMembers: CourseMember[];
    private reportUtils: ReportUtils;
    private tree: TreeNode[];
    private selectedGroupNodes: TreeNode[];
    @Input() keyword: string;



    constructor(private router: Router) {
        super();
        this.reportUtils = new ReportUtils();
    }

    ngOnInit() {
        Group.listCourseGroup(this,GROUP_FIELDS).subscribe(groups => {
            var treeUtils = new TreeUtils();
            this.tree = treeUtils.buildGroupTree(groups);
        })
        this.lmsProfileService.init(this).subscribe(() => {
            this.courseMembers =  this.lmsProfileService.MyCourseMembers;
            var courseIds = _.pluck(this.courseMembers, 'course_id');
            courseIds = _.uniq(courseIds, id=> {
                    return id;
            });
            Course.array(this, courseIds, COURSE_FIELDS).subscribe(courses=> {
                this.displayCourses(courses);
            });
        });
    }

    displayCourses(courses:Course[]) {
        _.each(courses, (course:Course)=> {
            course['editor'] =  this.lmsProfileService.getCourseMemberByRole('editor', course.id);
            course['supervisor'] =  this.lmsProfileService.getCourseMemberByRole('supervisor', course.id);
        });
        this.courses = this.filteredCourses = _.sortBy(courses, (course: Course) => {
            return -this.lmsProfileService.getLastCourseTimestamp(course);
        });
    }

    editSyllabus(course: Course, member: CourseMember) {
        this.router.navigate(['/lms/courses/edit', course.id, member.id]);
    }

    publishCourse(course: Course) {
        this.router.navigate(['/cms/course/publish', course.id, course.syllabus_id]);
    }

    filterCourse() {
        if (this.selectedGroupNodes.length != 0) {
            this.filteredCourses = _.filter(this.courses, course => {
                var parentGroupNode = _.find(this.selectedGroupNodes, node => {
                    return node.data.id == course.group_id;
                });
                return parentGroupNode != null;
            });
        } else {
            this.filteredCourses = this.courses;
        }
        if (!this.keyword)
            return;
        this.keyword = this.keyword.trim();
        if (this.keyword.length == 0)
            this.filteredCourses = this.courses;
        else {
            var keyword = this.keyword.toLowerCase();
            this.filteredCourses = _.filter(this.courses, (course: Course) => {
                return course.name.toLowerCase().includes(keyword)
                    || course.summary.toLowerCase().includes(keyword)
                    || course.code.toLowerCase().includes(keyword)
                    || course.description.toLowerCase().includes(keyword);
            });
        }

    }
}