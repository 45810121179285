import { NgModule } from '@angular/core';
import { Routes } from '@angular/router';
import { DashboardComponent } from './dashboard.component';
import { RouterModule } from '@angular/router';
import { AdminDashboardComponent } from './admin-dashboard/admin-dashboard.component';
import { UserDashboardComponent } from './user-dashboard/user-dashboard.component';
import { AdminGuard } from '../shared/guards/admin.guard';
import { CourseListComponent } from './course-list/course-list.component';

export const DashboardRoutes: Routes = [
	{
		path: 'dashboard',
		component: DashboardComponent,
		data: {
			breadcrumb: 'Dashboard'
		},
		children:
			[
				{
					path: "lms",
					component: UserDashboardComponent,
					data: {
						breadcrumb: 'LMS'
					},
				},
				{
					path: "admin",
					component: AdminDashboardComponent,
					data: {
						breadcrumb: 'Admin'
					},
					canActivate: [AdminGuard]
				},
				{
					path: "courses",
					component: CourseListComponent,
					data: {
						breadcrumb: 'My courses'
					}
				},
			]
	}

]
@NgModule({
	imports: [RouterModule.forChild(DashboardRoutes)],
	exports: [RouterModule]
})
export class DashboardRoutingModule { }