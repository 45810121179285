import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { BaseComponent } from '../../../shared/components/base/base.component';
import * as _ from 'underscore';
import { Product } from '../../../shared/models/elearning/product.model';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { ProductDialog } from '../service-dialog/service-dialog.component';

const PRODUCT_FIELDS = ['name', 'list_price', 'create_date', 'write_date', 'days']

@Component({
    
    selector: 'service-list',
    templateUrl: 'service-list.component.html',
    styleUrls: ['service-list.component.css'],
})
export class ServiceListComponent extends BaseComponent implements OnInit {

    private products: Product[];
    private selectedProduct: Product;


    constructor(private route: ActivatedRoute, private router: Router) {
        super();
    }

    @ViewChild(ProductDialog,{static:true}) productDialog: ProductDialog;

    ngOnInit() {
        this.loadProducts();
    }

    loadProducts() {
        Product.all(this, PRODUCT_FIELDS).subscribe(products => {
            this.products = _.sortBy(products, (product: Product) => {
                return -product.id;
            });
        })
    }


    addProduct() {
        // this.router.navigate(['/service/form']);
        var product = new Product();
        this.productDialog.show(product);
        this.productDialog.onCreateComplete.subscribe(() => {
            this.loadProducts();
        });
    }

    editProduct(product: Product) {
        // this.router.navigate(['/service/form', product.id]);
        this.productDialog.show(product);
        this.productDialog.onUpdateComplete.subscribe(() => { });
    }

    viewProduct(product: Product) {
        // this.router.navigate(['/service/view', product.id]);
        this.productDialog.show(product);
    }


    deleteProduct(product: Product) {
        this.confirm(this.translateService.instant('Are you sure to delete ?'), () => {
            product.delete(this).subscribe(() => {
                this.loadProducts();
                this.selectedProduct = null;
            });
        });
    }

}
