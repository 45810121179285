import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { DatePipe } from '@angular/common';
import { BaseComponent } from '../../../../shared/components/base/base.component';
import { User } from '../../../../shared/models/elearning/user.model';
import { PaymentRequest } from '../../../../shared/models/elearning/payment-request.model';
import { Company } from '../../../../shared/models/elearning/company.model';
import * as _ from 'underscore';
import { TimeConvertPipe } from '../../../../shared/pipes/time.pipe';
import { ExcelService } from '../../../../shared/services/excel.service';
import { PaymentDetail } from '../../../../shared/models/elearning/payment-detail.model';
import { PAID_STATUS } from '../../../../shared/models/constants';
import { ActivatedRoute } from '@angular/router';

const USER_FIELDS = ['name', 'referal_code'];
const PAYMENT_DETAIL = ['course_name'];

@Component({
	
	selector: 'staff-monthly-report',
	templateUrl: 'staff-monthly-report.component.html',
	styleUrls: ['staff-monthly-report.component.css'],
})
export class StaffMonthlyReportComponent extends BaseComponent {

	private records: any;
	private totalComission: number;
	private totalSales: number;
	private company: Company;
	private listStaff: User[];
	private listIdRequest: number[];
	private listPaymentDetail: PaymentDetail[];
	private selectedStaff: any;
	private paid: number;
	private unpaid: number;
	private start: Date;
	private end: Date;
	private staff: User;
	private permission: string;
	PAID_STATUS = PAID_STATUS;

	constructor(private excelService: ExcelService, private datePipe: DatePipe, private timePipe: TimeConvertPipe, private route: ActivatedRoute) {
		super();
		this.records = [];
		this.listStaff = [];
		this.listPaymentDetail = [];
		this.totalComission = 0;
		this.totalSales = 0;
		this.company = new Company();
	}

	ngOnInit() {
		this.permission = this.route.snapshot.data['permission'];
	}

	export() {
		var output = _.map(this.records, record => {
			return {
				'Học viên': record['customer_name'],
				'Mã thanh toán': record['ref'],
				'Ngày thanh toán': record['payment_date'],
				'Giá': record['sales'],
				'Số khóa học': record['product_quantity'],
				'Tỷ lệ hoa hồng': record['commission_rate'],
				'Tổng tiền nhận được': record['commission'],
				'Trạng thái': this.translateService.instant(PAID_STATUS[record['paid_status']]),
			};
		});
		this.excelService.exportAsExcelFile(output, 'commission_report');
	}

	clear() {
		this.records = [];
	}

	render(start: Date, end: Date, user: User) {
		this.clear();
		this.start = start;
		this.end = end;
		this.staff = user;
		this.unpaid = 0;
		this.paid = 0;
		Company.main(this).subscribe(company => {
			this.company = company;
			this.generateReport(start, end, user.referal_code);
		})

	}

	generateReport(start: Date, end: Date, referal_code) {
		PaymentRequest.reportConfirmedPaymentByDateAndUser(this, start, end, referal_code).subscribe((payments: any) => {
			this.records = _.map(payments, (payment: PaymentRequest) => {
				return this.generateReportRow(payment);
			});
			this.generateReportFooter(this.records);
		});
	}

	generateReportRow(payment: PaymentRequest): any {
		var record = {};
		record["payment_date"] = payment.create_date;
		record["customer_name"] = payment.user_name;
		record["customer_email"] = payment.email;
		record["customer_mobile"] = payment.phone;
		record["referal_name"] = this.getNameByReferalCode(payment.referal_code);
		record["ref"] = payment.ref;
		record["month"] = Math.floor(payment.service_length / 30);
		record["sales"] = payment.amount;
		record["service_name"] = payment.service_name;
		if (payment.commission_staff != '') {
			record["commission"] = JSON.parse(payment.commission_staff).comisson;
			record["commission_rate"] = JSON.parse(payment.commission_staff).comisson_rate;
			if (payment.pay_staff) {
				this.paid = this.paid + JSON.parse(payment.commission_staff).comisson;
			} else {
				this.unpaid = this.unpaid + JSON.parse(payment.commission_staff).comisson;
			}
		} else {
			record["commission"] = 0;
			record["commission_rate"] = 0;
		}
		record["paid_status"] = payment.pay_staff;
		record["id"] = payment.id;
		PaymentDetail.searchPaymentDetailByPaymentRequest(this, payment.id, PAYMENT_DETAIL).subscribe(details => {
			record["product_quantity"] = details.length;
		});
		return record;
	}

	generateReportFooter(records: any) {
		this.totalSales = 0;
		_.each(this.records, record => {
			this.totalSales += record["commission"];
		});
	}

	getNameByReferalCode(code) {
		var name = '';
		if (code) {
			this.listStaff.forEach(staff => {
				if (staff.referal_code === code) {
					name = staff.name;
				}
			});
		}
		return name;
	}

	paidStaff(selectedStaff) {
		PaymentRequest.get(this, selectedStaff.id).subscribe(request => {
			request.pay_staff = true;
			request.save(this).subscribe(resp => {
				if (!resp["success"]) {
					this.error(this.translateService.instant(resp["message"]));
					return;
				}
				this.render(this.start, this.end, this.staff);
			});
		});

	}

	unPaidStaff(selectedStaff) {
		PaymentRequest.get(this, selectedStaff.id).subscribe(request => {
			request.pay_staff = false;
			request.save(this).subscribe(resp => {
				if (!resp["success"]) {
					this.error(this.translateService.instant(resp["message"]));
					return;
				}
				this.render(this.start, this.end, this.staff);
			});
		});
	}
}
