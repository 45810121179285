import { Component, OnInit, Input, ViewChild,NgZone } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { AuthService } from '../../../shared/services/auth.service';
import { Group } from '../../../shared/models/elearning/group.model';
import { User } from '../../../shared/models/elearning/user.model';
import { BaseDialog } from '../../../shared/components/base/base.dialog';
import { Course } from '../../../shared/models/elearning/course.model';
import { CourseMember } from '../../../shared/models/elearning/course-member.model';
import * as _ from 'underscore';
import { TreeUtils } from '../../../shared/helpers/tree.utils';
import { TreeNode } from 'primeng/api';
import { SelectItem, MenuItem } from 'primeng/api';
import { GROUP_CATEGORY, COURSE_STATUS, COURSE_MODE, COURSE_MEMBER_ROLE, COURSE_MEMBER_STATUS, COURSE_MEMBER_ENROLL_STATUS } from '../../../shared/models/constants'
import { SelectStaffDialog } from '../../../shared/components/select-staff-dialog/select-staff-dialog.component';
import { WindowRef } from '../../../shared/helpers/windonw.ref';

declare var $: any;

@Component({
	
	selector: 'course-info-dialog',
	templateUrl: 'course-info-dialog.component.html',
	styleUrls: ['course-info-dialog.component.css'],
})
export class CourseInfoDialog extends BaseDialog<Course> {
	private percentage: number;
	WINDOW_HEIGHT: any;

	constructor(private ngZone: NgZone ) {
		super();
		this.WINDOW_HEIGHT = $(window).height();
	}

	uploadFile(file) {
		this.percentage = 0;
		this.apiService.upload(file, this.authService.LoginToken).subscribe(
			data => {
				if (data["result"]) {
					this.ngZone.run(() => {
						this.object.intro_video_id = data["attachment_id"];
						this.object.intro_video_url = data["url"];
					});
				} else {
					this.ngZone.run(() => { 
						this.percentage = +data;
					});
				}
			}
		);
	}

	changeFile(event: any) {
		let file = event.files[0];
		this.uploadFile(file);
	}


}

