import { Observable, Subject } from 'rxjs/Rx';
import { Model, ReadOnlyProperty } from '../decorator';
import { APIContext } from '../context';
import { BaseModel } from '../base.model';
import { User } from './user.model';
import * as _ from 'underscore';
import { SearchReadAPI } from '../../services/api/search-read.api';
import * as moment from 'moment';
import { SERVER_DATETIME_FORMAT } from '../constants';

@Model('opencourse.payment_detail')
export class PaymentDetail extends BaseModel {

    // Default constructor will be called by mapper
    constructor() {
        super();

        this.course_id = undefined;
        this.course_name = undefined;
        this.teacher_name = undefined;
        this.price = undefined;
        this.sale = undefined;
        this.request_id = undefined;
        this.request_name = undefined;
        this.request_date = undefined;
        this.user_id = undefined;
        this.user_name = undefined;
        this.pay_teacher = undefined;
        this.commission_teacher = undefined;
    }

    course_id: number;
    course_name: string;
    teacher_name: string;
    price: number;
    sale: number;
    request_id: number;
    request_name: string;
    request_date: Date;
    user_id: number;
    user_name: string;
    pay_teacher: boolean;
    commission_teacher: string;

    static __api__searchPaymentDetailByPaymentRequest(requestId: number, fields?: string[]): SearchReadAPI {
        return new SearchReadAPI(PaymentDetail.Model, fields, "[('request_id','='," + requestId + ")]");
    }

    static searchPaymentDetailByPaymentRequest(context: APIContext, requestId: number, fields?: string[]): Observable<any> {
        return PaymentDetail.search(context, fields, "[('request_id','='," + requestId + ")]");
    }

    static __api__searchConfirmedPaymentByUser(userId: number, fields?: string[]): SearchReadAPI {
        return new SearchReadAPI(PaymentDetail.Model, fields, "[('user_id','='," + userId + "),('status','=','confirmed')]");
    }

    static searchConfirmedPaymentByUser(context: APIContext, userId: number, fields?: string[]): Observable<any> {
        return PaymentDetail.search(context, fields, "[('user_id','='," + userId + "),('status','=','confirmed')]");
    }

    static countPaymentDetailByPaymentRequest(context: APIContext, requestId: number, fields?: string[]): Observable<any> {
        return PaymentDetail.count(context, "[('request_id','='," + requestId + ")]");
    }

    static reportConfirmedPaymentByDate(context: APIContext, start: Date, end: Date, fields?: string[]): Observable<any> {
        var startDateStr = moment(start).format(SERVER_DATETIME_FORMAT);
        var endDateStr = moment(end).format(SERVER_DATETIME_FORMAT);
        var month = moment(start).format('YYYY-MM');
        return PaymentDetail.search(context, fields, "[('create_date','ilike','" + month + "'),('status','=','confirmed')]");
    }

    static reportConfirmedPaymentByDateAndTeacher(context: APIContext, start: Date, end: Date, name: String, fields?: string[]): Observable<any> {
        var startDateStr = moment(start).format(SERVER_DATETIME_FORMAT);
        var endDateStr = moment(end).format(SERVER_DATETIME_FORMAT);
        var month = moment(start).format('YYYY-MM');
        return PaymentDetail.search(context, fields, "[('create_date','ilike','" + month + "'),('teacher_name','=','" + name + "'),('status','=','confirmed')]");
    }
}
