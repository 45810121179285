import { Component, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { BaseComponent } from '../../../shared/components/base/base.component';
import { User } from '../../../shared/models/elearning/user.model';
import * as _ from 'underscore';
import { SelectStaffDialog } from '../../../shared/components/select-staff-dialog/select-staff-dialog.component';
import { KeyFilterModule } from 'primeng/keyfilter';

@Component({
	
	selector: 'learner-form-content',
	templateUrl: 'learner-content.component.html',
	styleUrls: ['learner-content.component.css'],
})
export class LearnerContentComponent extends BaseComponent {

	private user: User;
	@ViewChild(SelectStaffDialog,{static:true}) staffDialog: SelectStaffDialog;

	constructor(private router: Router, private route: ActivatedRoute) {
		super();
		this.user = new User();
	}


	render(user: User) {
		this.user = user;

	}

	selectReferer() {
		this.staffDialog.show();
		this.staffDialog.onSelectUser.first().subscribe((user: User) => {
			this.user.referer_id = user.id;
			this.user.referal_name = user.name;

		});
	}


}

