import { NgModule } from '@angular/core';
import { AuthModule } from '../auth/auth.module';
import { ErpSharedModule } from '../shared/shared.module';
import { AnalysisComponent } from './analysis.component';
import { ReportComponent } from './report/report.component';
import { ChartComponent } from './chart/chart.component';
import { CourseByMemberReportComponent } from './report/course/course-by-member-report/course-by-member-report.component';
import { CourseByMemberReportContainerComponent } from './report/course/course-by-member-report/course-by-member-report-container.component';
import { MemberByCourseReportComponent } from './report/course/member-by-course-report/member-by-course-report.component';
import { MemberByCourseReportContainerComponent } from './report/course/member-by-course-report/member-by-course-report-container.component';
import { ReportContainerDirective } from './report/report-container.directive';
import { CourseActivityChartComponent } from './chart/course-activity-chart/course-activity-chart.component';
import { CourseActivityChartContainerComponent } from './chart/course-activity-chart/course-activity-chart-container.component';
import { UserLoginActivityChartComponent } from './chart/user-login-activity-chart/user-login-activity-chart.component';
import { UserLoginActivityChartContainerComponent } from './chart/user-login-activity-chart/user-login-activity-chart-container.component';
import { ChartContainerDirective } from './chart/chart-container.directive'
import { DatePipe } from '@angular/common';
import { ReportUtils } from '../shared/helpers/report.utils';
import { TimeConvertPipe } from '../shared/pipes/time.pipe';
import { StatsUtils } from '../shared/helpers/statistics.utils';
import { CourseMemberActivityChartComponent } from './chart/course-member-activity-chart/course-member-activity-chart.component';
import { AnalysisRoutingModule } from './analysis-routing';
import { UserStatusChartComponent } from './chart/user-status-chart/user-status-chart.component';
import { UserStatusChartContainerComponent } from './chart/user-status-chart/user-status-chart-container.component';
import { CourseStatusReportComponent } from './report/course/course-status-report/course-status-report.component';
import { CourseStatusReportContainerComponent } from './report/course/course-status-report/course-status-report-container.component';
import { SalesMonthlyComissionReportComponent } from './report/finance/sales-monthly-commission-report/sales-monthly-commission-report.component';
import { SalesComissionReportContainerComponent } from './report/finance/sales-monthly-commission-report/sales-monthly-commission-container.component';
import { StaffMonthlyReportComponent } from './report/finance/staff-monthly-report/staff-monthly-report.component';
import { StaffMonthlyReportContainerComponent } from './report/finance/staff-monthly-report/staff-monthly-report-container.component';
import { TeacherMonthlyReportComponent } from './report/finance/teacher-monthly-report/teacher-monthly-report.component';
import { TeacherMonthlyReportContainerComponent } from './report/finance/teacher-monthly-report/teacher-monthly-report-container.component';
import { MemberByCourseDetailDialog } from './report/course/member-by-course-report/member-by-course-detail-dialog/member-by-course-detail-dialog.component';

@NgModule({
	imports: [
		AnalysisRoutingModule,
		ErpSharedModule,
		AuthModule
	],
	declarations: [
		AnalysisComponent,
		ReportComponent,
		ChartComponent,
		CourseByMemberReportContainerComponent,
		CourseByMemberReportComponent,
		MemberByCourseReportContainerComponent,
		MemberByCourseReportComponent,
		ChartContainerDirective,
		CourseActivityChartComponent,
		CourseActivityChartContainerComponent,
		UserLoginActivityChartComponent,
		UserLoginActivityChartContainerComponent,
		UserStatusChartComponent,
		UserStatusChartContainerComponent,
		CourseMemberActivityChartComponent,
		CourseStatusReportContainerComponent,
		CourseStatusReportComponent,
		ReportContainerDirective,
		SalesComissionReportContainerComponent,
		SalesMonthlyComissionReportComponent,
		StaffMonthlyReportComponent,
		StaffMonthlyReportContainerComponent,
		TeacherMonthlyReportComponent,
		TeacherMonthlyReportContainerComponent,
		MemberByCourseDetailDialog
	],
	entryComponents: [
		CourseByMemberReportContainerComponent,
		MemberByCourseReportContainerComponent,
		UserLoginActivityChartContainerComponent,
		CourseActivityChartContainerComponent,
		CourseStatusReportContainerComponent,
		UserStatusChartContainerComponent,
		SalesComissionReportContainerComponent,
		StaffMonthlyReportComponent,
		StaffMonthlyReportContainerComponent,
		TeacherMonthlyReportComponent,
		TeacherMonthlyReportContainerComponent,
	],
	exports: [
		CourseMemberActivityChartComponent
	],
	providers: [
		DatePipe,
		TimeConvertPipe
	]
})
export class AnalysisModule {
}
