import { Component, OnInit, Input, NgZone } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { BaseDialog } from '../../components/base/base.dialog';
import { Group } from '../../models/elearning/group.model';
import * as _ from 'underscore';
import { TreeUtils } from '../../helpers/tree.utils';
import { TreeNode } from 'primeng/api';

@Component({
	
	selector: 'group-dialog',
	templateUrl: 'group-dialog.component.html',
	styleUrls: ['group-dialog.component.css'],
})
export class GroupDialog extends BaseDialog<Group> implements OnInit {

	tree: TreeNode[];
	treeUtils: TreeUtils;
	selectedNode: TreeNode;

	constructor(private ngZone: NgZone) {
		super();
		this.treeUtils = new TreeUtils();
	}

	nodeSelect(event: any) {
		if (this.selectedNode) {
			this.object.parent_id = this.selectedNode.data.id;
		}
	}

	ngOnInit() {
		this.onShow.subscribe(object => {
			var subscription = null;
			if (object.category == "course")
				subscription = Group.listCourseGroup(this);
			if (object.category == "organization")
				subscription = Group.listUserGroup(this);
			if (subscription) {
				
				subscription.subscribe(groups => {
					this.tree = this.treeUtils.buildGroupTree(groups);
					if (object.id) {
						var node = this.treeUtils.findTreeNode(this.tree, object.id);
						node.selectable = false;
					}
					if (object.parent_id) {
						this.selectedNode = this.treeUtils.findTreeNode(this.tree, object.parent_id);
					}
					
				});
			}

		});
	}

	uploadImageFile(file) {
		//this.apiService.upload_S3(file, this.authService.LoginToken).subscribe(
		this.apiService.upload(file, this.authService.LoginToken).subscribe(
			data => {
				if (data["result"]) {
					this.ngZone.run(() => {
							this.object.logo_url = data["url"];
							this.object.logo_id = data["attachment_id"];
					});
				}
			}
		);
	}

	changeImageFile(event: any) {
		let file = event.files[0];
		this.resizeImage(file, 100, 100).then(blob => {
			let fileNew = this.blobToFile(blob, file.name);
			this.uploadImageFile(fileNew);
		}, err => {
			console.error("Photo error", err);
		});
	}
}


