import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { BaseComponent } from '../../../shared/components/base/base.component';
import { AuthService } from '../../../shared/services/auth.service';
import * as _ from 'underscore';
import { USER_STATUS, GROUP_CATEGORY, COURSE_MODE, COURSE_STATUS } from '../../../shared/models/constants'
import { Course } from '../../../shared/models/elearning/course.model';
import { LearningPath } from '../../../shared/models/elearning/learning-path.model';
import { TreeUtils } from '../../../shared/helpers/tree.utils';
import { TreeNode } from 'primeng/api';
import { BaseModel } from '../../../shared/models/base.model';
import { User } from '../../../shared/models/elearning/user.model';
import { Router, ActivatedRoute, Params } from '@angular/router';

@Component({
    
    selector: 'learningpath-list',
    templateUrl: 'learningpath-list.component.html',
})
export class LearningPathListComponent extends BaseComponent implements OnInit{

    private selectedPath: any;
    private paths: LearningPath[];


    constructor(private router: Router) {
        super();
    }

    ngOnInit() {
        this.loadLearningPaths();
    }


    addPath() {
        this.router.navigate(['/path/form']);
    }

    editPath(path: LearningPath) {
        this.router.navigate(['/path/form', path.id]);
    }

    viewPath(path: LearningPath) {
        this.router.navigate(['/path/view', path.id]);
    }


    deletePath(path: LearningPath) {
        this.confirm(this.translateService.instant('Are you sure to delete ?'), () => {
            path.delete(this).subscribe(() => {
                this.loadLearningPaths();
                this.selectedPath = null;
            })
        });
    }

    loadLearningPaths() {
        LearningPath.all(this).subscribe(paths => {
            this.paths = _.sortBy(paths, (path: LearningPath) => {
                return -path.id;
            });
        });
    }

}

