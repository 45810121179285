import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { Product } from '../../../shared/models/elearning/product.model';
import { Router, ActivatedRoute, Params } from '@angular/router';import { AuthService } from '../../../shared/services/auth.service';
import { Group } from '../../../shared/models/elearning/group.model';
import { User } from '../../../shared/models/elearning/user.model';
import { BaseComponent } from '../../../shared/components/base/base.component';
import { LearningPath } from '../../../shared/models/elearning/learning-path.model';
import { LearningPathItem } from '../../../shared/models/elearning/learning-path-item.model';
import * as _ from 'underscore';
import { TreeUtils } from '../../../shared/helpers/tree.utils';
import { TreeNode } from 'primeng/api';
import { SelectItem, MenuItem } from 'primeng/api';
import { GROUP_CATEGORY, COURSE_STATUS, COURSE_MODE, COURSE_MEMBER_ROLE, COURSE_MEMBER_STATUS, COURSE_MEMBER_ENROLL_STATUS } from '../../../shared/models/constants'

@Component({
	
	selector: 'service-view',
	templateUrl: 'service-view.component.html',
})
export class ProductViewComponent extends BaseComponent {

	private product: Product;


	constructor(private router: Router, private route: ActivatedRoute) {
		super();
		this.product = new Product();
	}


	ngOnInit() {
		this.product = this.route.snapshot.data['product'];
	}

	editProduct() {
		this.router.navigate(['/service/form', this.product.id]);
	}

	close() {
		this.router.navigate(['/service/list']);
	}
}

