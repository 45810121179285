import { Component, Input, OnInit, ViewChild} from '@angular/core';
import { DatePipe } from '@angular/common';
import { Observable, Subject } from 'rxjs/Rx';
import { ReportUtils } from '../../../../shared/helpers/report.utils';
import { Group } from '../../../../shared/models/elearning/group.model';
import { BaseComponent } from '../../../../shared/components/base/base.component';
import { User } from '../../../../shared/models/elearning/user.model';
import { Course } from '../../../../shared/models/elearning/course.model';
import { CourseLog } from '../../../../shared/models/elearning/log.model';
import { CourseMember } from '../../../../shared/models/elearning/course-member.model';
import * as _ from 'underscore';
import { EXPORT_DATETIME_FORMAT, REPORT_CATEGORY, GROUP_CATEGORY, COURSE_MODE, DEFAULT_DATE_LOCALE, EXPORT_DATE_FORMAT } from '../../../../shared/models/constants'
import { Report } from '../../report.decorator';
import { SelectGroupDialog } from '../../../../shared/components/select-group-dialog/select-group-dialog.component';
import { SelectCoursesDialog } from '../../../../shared/components/select-course-dialog/select-course-dialog.component';
import { TimeConvertPipe} from '../../../../shared/pipes/time.pipe';
import { ExcelService } from '../../../../shared/services/excel.service';
import { SalesMonthlyComissionReportComponent } from './sales-monthly-commission-report.component';
import {DateUtils } from '../../../../shared/helpers/date.utils';

@Component({
    
    selector: 'sales-monthly-commission-report-container',
	templateUrl: 'sales-monthly-commission-report-container.component.html',
})
@Report({
    title:'Sales commission report',
    category:REPORT_CATEGORY.FINANCE
})
export class SalesComissionReportContainerComponent extends BaseComponent implements OnInit{

    private monthYear: any;
    private dateUtils: DateUtils;

    @ViewChild(SalesMonthlyComissionReportComponent ,{static:true}) salesReport: SalesMonthlyComissionReportComponent;
    
    constructor() {
        super();
    }

    ngOnInit() {;
    }

    export() {
        this.salesReport.export();
    }

    selectMonth() {
        var year = this.monthYear.split('-')[0];
        var month = this.monthYear.split('-')[1];
        var now = new Date();
        now.setMonth(+month-1);
        now.setFullYear(year);
        var dateUtils = new DateUtils();
        this.salesReport.render(dateUtils.firstDateOfMonth(now),dateUtils.lastDateOfMonth(now));

    }


}
