import { Component, ElementRef, Renderer, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../shared/services/auth.service';
import { BaseComponent } from '../shared/components/base/base.component';
import * as _ from 'underscore';
import { HomeEventManager } from './home-manager.service';
import { StaffViewDialog } from '../account/user/staff-view/staff-view.dialog.component';
import { UserLog } from '../shared/models/elearning/log.model';
import { Group } from '../shared/models/elearning/group.model';
import { BaseModel } from '../shared/models/base.model';
import { User } from '../shared/models/elearning/user.model';
import { ComapnySettingDialog } from '../setting/company-setting-dialog/company-setting-dialog.component';
import { MenuService } from '../shared/services/menu.service';
import { Company } from '../shared/models/elearning/company.model';
import { ChangePasswordDialog } from '../account/user/change-password/change-password-dialog.component';

@Component({
    
    selector: 'app-home',
    templateUrl: 'home.component.html',
    styleUrls: ['home.component.css'],
})
export class HomeComponent extends BaseComponent implements OnInit, AfterViewInit {

    menuClick: boolean;
    menuButtonClick: boolean;
    topbarMenuButtonClick: boolean;
    topbarMenuClick: boolean;
    topbarMenuActive: boolean;
    activeTopbarItem: Element;
    layoutStatic: boolean;
    sidebarActive: boolean;
    mobileMenuActive: boolean;
    darkMenu: boolean;

    @ViewChild(StaffViewDialog,{static:true}) userProfileDialog: StaffViewDialog;
    @ViewChild(ComapnySettingDialog,{static:true}) settingDialog :ComapnySettingDialog;
    @ViewChild(ChangePasswordDialog,{static:true}) passwordDialog: ChangePasswordDialog

    constructor( private router: Router, private eventManager :HomeEventManager, private menuService: MenuService) {
        super();
        
    }

    ngOnInit() {
        // Fill the cache
        if (this.ContextUser.IsAdmin)
            this.router.navigate(['/dashboard/admin']);
        else
            this.router.navigate(['/dashboard/lms']);
        this.menuService.onShowCompanySetting.subscribe(()=> {
            Company.main(this).subscribe(company=> {
                this.settingDialog.show(company);
            });
        })
        this.apiService.onLogout.first().subscribe(() => {
            UserLog.logout(this, this.ContextUser.id).subscribe();
            this.authService.logout();
            this.router.navigate(["/auth/login"]);
        });
    }

    onWrapperClick() {
        if (!this.menuClick && !this.menuButtonClick) {
            this.mobileMenuActive = false;
        }

        if (!this.topbarMenuClick && !this.topbarMenuButtonClick) {
            this.topbarMenuActive = false;
            this.activeTopbarItem = null;
        }
        this.menuClick = false;
        this.menuButtonClick = false;
        this.topbarMenuClick = false;
        this.topbarMenuButtonClick = false;
    }

    ngAfterViewInit() {
        this.eventManager.showProfileEvents.subscribe(() => {
            this.userProfileDialog.show(this.ContextUser);
        });
        this.eventManager.changePasswordEvents.subscribe(() => {
            this.passwordDialog.show();
        });
        this.eventManager.topbarMenuEvents.subscribe(() => {
            this.topbarMenuClick = true;
        });
        this.eventManager.topbarRootItemEvents.subscribe((item: any) => {
            if (this.activeTopbarItem === item) {
                this.activeTopbarItem = null;
            } else {
                this.activeTopbarItem = item;
            }
        });
        this.eventManager.menuEvents.subscribe(() => {
            this.menuButtonClick = true;
            if (this.isMobile()) {
                this.mobileMenuActive = !this.mobileMenuActive;
            }
        });
        this.eventManager.topbarMobileMenuEvents.subscribe(() => {
            this.topbarMenuButtonClick = true;
            this.topbarMenuActive = !this.topbarMenuActive;
        });
        this.eventManager.sidebarEvents.subscribe(() => {
            this.menuClick = true;
        });
        this.eventManager.toggleMenuEvents.subscribe(() => {
            this.layoutStatic = !this.layoutStatic;
        });
    }


    isMobile() {
        return window.innerWidth < 640;
    }

}
