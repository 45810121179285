import { Observable, Subject } from 'rxjs/Rx';
import { Model,ReadOnlyProperty,FieldProperty } from '../decorator';
import { APIContext } from '../context';
import { BaseModel } from '../base.model';
import { User } from './user.model';
import * as _ from 'underscore';
import { SearchReadAPI } from '../../services/api/search-read.api';

@Model('product.product')
export class Product extends BaseModel{

    // Default constructor will be called by mapper
    constructor(){
        super();
        this.name = undefined;
        this.days = undefined;
        this.list_price =  undefined;
    }

    name: string;
    days: number;
    list_price: number;
}
