import { BaseModel } from '../base.model';
import { Observable, Subject } from 'rxjs/Rx';
import { Model } from '../decorator';
import { APIContext } from '../context';
import { SearchReadAPI } from '../../services/api/search-read.api';

@Model('opencourse.learning_path_item')
export class LearningPathItem extends BaseModel{

    // Default constructor will be called by mapper
    constructor(){
        super();
		
			this.group_name = undefined;
			this.course_id = undefined;
			this.group_id = undefined;
			this.path_id = undefined;
			this.order = undefined;
			this.supervisor_name = undefined;
			this.course_name = undefined;
			this.course_status = undefined;
            this.course_teacher = undefined;
    }
    
    course_teacher: string;
    course_id:number;
    group_name: string;
    course_status: string;
    course_name: string;
    supervisor_name: string;
    group_id: number;
    path_id: number;
    order: number;


}
