import { Observable, Subject } from 'rxjs/Rx';
import { Model, ReadOnlyProperty } from '../decorator';
import { APIContext } from '../context';
import { BaseModel } from '../base.model';
import { User } from './user.model';
import * as _ from 'underscore';
import { SearchReadAPI } from '../../services/api/search-read.api';
import * as moment from 'moment';
import { SERVER_DATETIME_FORMAT } from '../constants';
import { ExecuteAPI } from '../../services/api/execute.api';

@Model('opencourse.payment_request')
export class PaymentRequest extends BaseModel {

    // Default constructor will be called by mapper
    constructor() {
        super();

        this.name = undefined;
        this.amount = undefined;
        this.ref = undefined;
        this.email = undefined;
        this.phone = undefined;
        this.city = undefined;
        this.referal_code = undefined;
        this.support_mail = undefined;
        this.user_id = undefined;
        this.user_name = undefined;
        this.referer_name = undefined;
        this.activation_code = undefined;
        this.activation_code_id = undefined;
        this.address = undefined;
        this.method = undefined;
        this.status = undefined;
        this.course_detail = [];
        this.service_length = undefined;
        this.service_price = undefined;
        this.service_name = undefined;
        this.pay_staff = undefined;
        this.pay_teacher = undefined;
        this.commission_staff = undefined;
        this.commission_teacher = undefined;
    }

    name: string;
    amount: number;
    ref: string;
    email: string;
    phone: string;
    city: string;
    referal_code: string;
    support_mail: string;
    user_id: number;
    user_name: string;
    referer_name: string;
    activation_code: string;
    activation_code_id: number;
    address: string;
    method: string;
    status: string;
    course_detail: string[];
    service_price: number;
    service_length: number;
    service_name: string;
    pay_staff: boolean;
    pay_teacher: boolean;
    commission_staff: string;
    commission_teacher: string;

    static __api__searchConfirmedPaymentByDate(start: Date, end: Date, fields?: string[]): SearchReadAPI {
        var startDateStr = moment(start).format(SERVER_DATETIME_FORMAT);
        var endDateStr = moment(end).format(SERVER_DATETIME_FORMAT);
        return new SearchReadAPI(PaymentRequest.Model, fields, "[('create_date','>=','" + startDateStr + "'),('create_date','<=','" + endDateStr + "'),('status','=','confirmed')]");
    }

    static searchConfirmedPaymentByDate(context: APIContext, start: Date, end: Date, fields?: string[]): Observable<any> {
        var startDateStr = moment(start).format(SERVER_DATETIME_FORMAT);
        var endDateStr = moment(end).format(SERVER_DATETIME_FORMAT);
        return PaymentRequest.search(context, fields, "[('create_date','>=','" + startDateStr + "'),('create_date','<=','" + endDateStr + "'),('status','=','confirmed')]");
    }

    static __api__reportConfirmedPaymentByDate(start: Date, end: Date, fields?: string[]): SearchReadAPI {
        var startDateStr = moment(start).format(SERVER_DATETIME_FORMAT);
        var endDateStr = moment(end).format(SERVER_DATETIME_FORMAT);
        var month = moment(start).format('YYYY-MM');
        return new SearchReadAPI(PaymentRequest.Model, fields, "[('create_date','ilike','" + month + "'),('status','=','confirmed')]");
    }

    static reportConfirmedPaymentByDate(context: APIContext, start: Date, end: Date, fields?: string[]): Observable<any> {
        var startDateStr = moment(start).format(SERVER_DATETIME_FORMAT);
        var endDateStr = moment(end).format(SERVER_DATETIME_FORMAT);
        var month = moment(start).format('YYYY-MM');
        return PaymentRequest.search(context, fields, "[('create_date','ilike','" + month + "'),('status','=','confirmed')]");
    }

    static reportConfirmedPaymentByDateAndUser(context: APIContext, start: Date, end: Date, referal_code, fields?: string[]): Observable<any> {
        var startDateStr = moment(start).format(SERVER_DATETIME_FORMAT);
        var endDateStr = moment(end).format(SERVER_DATETIME_FORMAT);
        var month = moment(start).format('YYYY-MM');
        return PaymentRequest.search(context, fields, "[('create_date','ilike','" + month + "'),('referal_code','=','" + referal_code + "'),('status','=','confirmed')]");
    }

    static __api__rejectPayment(requestId: number): ExecuteAPI {
        return new ExecuteAPI(PaymentRequest.Model, 'reject_payment', { "RequestId": requestId }, null);
    }

    rejectPayment(context: APIContext, requestId: number): Observable<any> {
        return context.apiService.execute(PaymentRequest.__api__rejectPayment(requestId),
            context.authService.LoginToken);
    }
}
