import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { DatePipe } from '@angular/common';
import { Observable, Subject } from 'rxjs/Rx';
import { ReportUtils } from '../../../../shared/helpers/report.utils';
import { Group } from '../../../../shared/models/elearning/group.model';
import { BaseComponent } from '../../../../shared/components/base/base.component';
import { User } from '../../../../shared/models/elearning/user.model';
import { Course } from '../../../../shared/models/elearning/course.model';
import { CourseLog } from '../../../../shared/models/elearning/log.model';
import { CourseMember } from '../../../../shared/models/elearning/course-member.model';
import * as _ from 'underscore';
import { EXPORT_DATETIME_FORMAT, REPORT_CATEGORY, GROUP_CATEGORY, COURSE_MODE, COURSE_MEMBER_ENROLL_STATUS, EXPORT_DATE_FORMAT, COURSE_LEVEL, COURSE_STATUS } from '../../../../shared/models/constants'
import { Report } from '../../report.decorator';
import { SelectGroupDialog } from '../../../../shared/components/select-group-dialog/select-group-dialog.component';
import { SelectCoursesDialog } from '../../../../shared/components/select-course-dialog/select-course-dialog.component';
import { TimeConvertPipe } from '../../../../shared/pipes/time.pipe';
import { ExcelService } from '../../../../shared/services/excel.service';
import { BaseModel } from '../../../../shared/models/base.model';

const COURSE_FIELDS = ['code','name','level','status','group_name','supervisor_name']

@Component({
	
	selector: 'course-status-report',
	templateUrl: 'course-status-report.component.html',
	styleUrls: ['course-status-report.component.css'],
})
export class CourseStatusReportComponent extends BaseComponent {

	GROUP_CATEGORY = GROUP_CATEGORY;
	COURSE_LEVEL = COURSE_LEVEL;
	COURSE_STATUS = COURSE_STATUS;


	private records: any;
	private reportUtils: ReportUtils;

	constructor(private excelService: ExcelService, private datePipe: DatePipe, private timePipe: TimeConvertPipe) {
		super();
		this.records = [];
	}

	export() {
		var output = _.map(this.records, record => {
			return {
				'Course code': record['code'],
				'Course name': record['name'],
				'Level': record['level'],
				'Status': record['status'],
				'Group name': record['group_name'],
				'Supervisor': record['supervisor_name'],
				'Create date': record['creat_date'],
			}
		});
		this.excelService.exportAsExcelFile(output, 'course_status_report');
	}


	clear() {
		this.records = [];
	}

	render(courses: Course[]) {
		this.clear();
		var courseIds = _.pluck(courses, 'id');
		Course.array(this, courseIds, COURSE_FIELDS).subscribe(courses=> {
			this.generateReport(courses);
		});
	}

	generateReport(courses: Course[]) {
		this.records = courses;
	}


}
