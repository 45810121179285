import { Component, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs/Rx';
import { BaseComponent } from '../../../shared/components/base/base.component';
import { AuthService } from '../../../shared/services/auth.service';
import * as _ from 'underscore';
import { USER_STATUS, GROUP_CATEGORY } from '../../../shared/models/constants'
import { User } from '../../../shared/models/elearning/user.model';
import { Group } from '../../../shared/models/elearning/group.model';
import { UserExportDialog } from '../export-dialog/export-dialog.component';
import { TreeUtils } from '../../../shared/helpers/tree.utils';
import { TreeNode } from 'primeng/api';
import { CourseMember } from '../../../shared/models/elearning/course-member.model';
import { BaseModel } from '../../../shared/models/base.model';
import { ExcelService } from '../../../shared/services/excel.service';

const GROUP_FIELDS = ['name', 'category', 'parent_id', 'user_count'];
const USER_FIELDS = ['group_id', 'banned', 'name', 'login', 'email', 'position', 'phone', 'group_name', 'permission_name', 'role', 'ban_date', 'unban_date'];

@Component({
  
  selector: 'user-list',
  templateUrl: 'user-list.component.html',
  styleUrls: ['user-list.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class UserListComponent extends BaseComponent {

  @ViewChild(UserExportDialog,{static:true}) userExportDialog: UserExportDialog;


  private tree: TreeNode[];
  private users: User[];
  private displayUsers: User[];
  private selectedUsers: any;
  private selectedGroupNodes: TreeNode[];

  private mode: string;
  private batchAction: string;
  private role: string;


  constructor(private excelService: ExcelService, private router: Router, private route: ActivatedRoute) {
    super();
  }

  ngOnInit() {
    this.role = this.route.snapshot.data['role'];
    this.enterSingleMode();
    Group.listUserGroup(this, GROUP_FIELDS).subscribe(groups => {
      let treeUtils = new TreeUtils();
      this.tree = treeUtils.buildGroupTree(groups);
    });
    User.all(this, USER_FIELDS).subscribe(users => {
      this.users = _.filter(users, (user: User) => {
        return user.role == this.role;
      });
      this.users = _.sortBy(this.users, (user: User) => {
        return -user.id;
      });
      this.displayUsers = this.users;
    });
  }

  addUser() {
    if (this.role == 'staff')
      this.router.navigate(['/account/staff/form']);
    if (this.role == 'teacher')
      this.router.navigate(['/account/teacher/form']);
    if (this.role == 'learner')
      this.router.navigate(['/account/learner/form']);
  }

  editUser(user: User) {
    if (this.role == 'staff')
      this.router.navigate(['/account/staff/form', user.id]);
    if (this.role == 'teacher')
      this.router.navigate(['/account/teacher/form', user.id]);
    if (this.role == 'learner')
      this.router.navigate(['/account/learner/form', user.id]);

  }

  viewUser(user: User) {
    if (this.role == 'staff')
      this.router.navigate(['/account/staff/view', user.id]);
    if (this.role == 'teacher')
      this.router.navigate(['/account/teacher/view', user.id]);
    if (this.role == 'learner')
      this.router.navigate(['/account/learner/view', user.id]);


  }

  activateMultipleUsers(users: User[]) {
    _.each(users, (user: User) => {
      user.banned = false;
    });
    return User.updateArray(this, users).do(() => {
      this.success(this.translateService.instant('User activated successfully'));
    });
  }

  deactivateMultipleUsers(users: User[]) {
    _.each(users, (user: User) => {
      user.banned = true;
    });
    return User.updateArray(this, users).do(() => {
      this.success(this.translateService.instant('User deactivated successfully'));
    });
  }

  exportUser() {
    if (this.role == 'staff') {
      this.userExportDialog.show();
    } else {
      this.exportUsers();
    }
  }

  importUser() {
    this.router.navigate(['/account/staffs/import']);
  }

  filterUserByGroup() {
    if (this.selectedGroupNodes.length != 0) {
      var groupIds = _.map(this.selectedGroupNodes, (node: TreeNode) => {
        return node.data["id"];
      });
      this.displayUsers = _.filter(this.users, (user: User) => {
        return groupIds.includes(user.group_id);
      });
    } else {
      this.displayUsers = this.users;
    }
  }

  enterBatchMode(action: string) {
    this.batchAction = action;
    this.mode = 'multiple';
    this.selectedUsers = [];
  }

  enterSingleMode() {
    this.mode = 'single';
    this.batchAction = '';
    this.selectedUsers = null;
  }

  applyBatchAction() {
    if (this.batchAction == 'activate')
      this.activateMultipleUsers(this.selectedUsers).subscribe(() => {
        this.enterSingleMode();
      });
    if (this.batchAction == 'deactivate')
      this.deactivateMultipleUsers(this.selectedUsers).subscribe(() => {
        this.enterSingleMode();
      });
  }

  exportUsers() {
    var output = _.map(this.users, record => {
      return {
        'Tên truy cập': record['login'],
        'Tên đầy đủ': record['name'],
        'Ngày sinh': record['dob'],
        'Giới tính': record['gender'],
        'Điện thoại': record['phone'],
        'Email': record['email'],
        'Ngày kích hoạt': record['ban_date'],
        'Ngày hủy kích hoạt': record['unban_date']
      };
    });
    this.excelService.exportAsExcelFile(output, 'export_user');
  }
}
