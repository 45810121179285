import { Component, OnInit } from '@angular/core';
import { environment } from '../environments/environment.prod';
import './operators';
import { BaseComponent } from './shared/components/base/base.component';
import { DEFAULT_LANG } from './shared/models/constants';
import { UserLog } from './shared/models/elearning/log.model';
import { ActivatedRoute, Router } from '@angular/router';
import { User } from './shared/models/elearning/user.model';

@Component({
	
	selector: 'app',
	template: `<div class="spinner" [hidden]="!loading"></div>
				<router-outlet></router-outlet>`
})
export class AppComponent extends BaseComponent implements OnInit {

	constructor(private router: Router) {
		super();
		console.log('Environment config', environment);
		this.translateService.setDefaultLang(DEFAULT_LANG);
		this.translateService.use(this.settingService.Lang);
	}

	ngOnInit() {

	}
}